import clsx from 'clsx';
import * as React from 'react';

import { Box } from '../box';

import { text } from './index.css';

import type { TextVariants } from './index.css';
import type { BoxProps } from '../box';

export type TextProps = BoxProps & TextVariants;

const HeadingElementMap: { [key: string]: BoxProps['as'] } = {
  hero: 'h1',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
};

export const Text = React.forwardRef(
  (
    {
      variant,
      as = variant && variant in HeadingElementMap ? HeadingElementMap[variant] : 'div',
      children,
      className,
      color = 'default',
      display = 'block',
      ...props
    }: TextProps,
    ref: React.Ref<HTMLElement>
  ) => {
    return (
      <Box as={as} className={clsx([text({ variant, color }), className])} display={display} ref={ref} {...props}>
        {children}
      </Box>
    );
  }
);

Text.displayName = 'Text';
