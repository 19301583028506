import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Priority = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <g clipPath="url(#priority_svg__a)">
      <path
        fill={colors.ultramarine900}
        d="M3.2 64h57.6c1.764 0 3.2-1.436 3.2-3.2a3.196 3.196 0 0 0-2.134-3.004v-7.662c0-1.765-1.436-3.2-3.2-3.2H43.733c-.375 0-.732.076-1.067.196V41.6a3.196 3.196 0 0 0-2.133-3.003v-1.263c0-1.765-1.436-3.2-3.2-3.2H35.2V21.748c.495-.427 2.148-1.48 6.4-1.48 2.337 0 3.894.309 5.543.637 2.33.463 4.74.943 9.247.475 1.298-.134 2.276-1.185 2.276-2.445V4.871a2.42 2.42 0 0 0-.797-1.783 2.607 2.607 0 0 0-1.988-.655c-3.953.374-6.033-.092-8.236-.589-1.7-.382-3.455-.777-6.045-.777-3.232 0-5.266.622-6.527 1.291C34.701 1.004 33.471 0 32 0a3.204 3.204 0 0 0-3.2 3.2v30.933h-2.133a3.204 3.204 0 0 0-3.2 3.2v1.263a3.196 3.196 0 0 0-2.133 3.003v2.33a3.164 3.164 0 0 0-1.067-.196H5.334a3.204 3.204 0 0 0-3.2 3.2v10.862A3.196 3.196 0 0 0 0 60.8 3.205 3.205 0 0 0 3.2 64Zm40.533-14.933h14.933c.589 0 1.067.479 1.067 1.067V57.6H42.666v-7.466c0-.588.478-1.067 1.067-1.067Zm3.443-45.14c2.269.51 4.615 1.037 8.908.63a.449.449 0 0 1 .35.11c.067.06.1.13.1.205v14.064c0 .16-.159.302-.362.322-4.192.432-6.338.007-8.61-.445-1.678-.333-3.413-.679-5.961-.679-3.125 0-5.134.535-6.4 1.127V4.857c.462-.456 2.087-1.657 6.4-1.657 2.353 0 3.918.353 5.575.725ZM30.933 3.2a1.068 1.068 0 0 1 2.134 0v30.933h-2.134V3.2ZM25.6 37.334c0-.588.478-1.067 1.067-1.067h10.666c.589 0 1.067.48 1.067 1.067V38.4H25.6v-1.066ZM23.467 41.6c0-.587.478-1.066 1.066-1.066h14.934c.588 0 1.066.479 1.066 1.066v16H23.467v-16Zm-19.2 5.334c0-.588.478-1.067 1.067-1.067h14.933c.589 0 1.067.479 1.067 1.067V57.6H4.267V46.934ZM3.2 59.734h57.6a1.068 1.068 0 0 1 0 2.133H3.2a1.068 1.068 0 0 1 0-2.134Z"
      />
    </g>
    <defs>
      <clipPath id="priority_svg__a">
        <path fill={colors.white} d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
);
export { Priority };
