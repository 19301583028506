import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Turkey = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M2.208 53.314c.164 1.07.8 2.034 1.74 2.598a11.7 11.7 0 0 0 6.015 1.666h44.102a11.69 11.69 0 0 0 6.013-1.665 3.689 3.689 0 0 0 1.741-2.599H2.21Zm51.856 6.396H9.964c-2.505 0-4.964-.681-7.112-1.97a5.833 5.833 0 0 1-2.818-4.976v-1.582h63.959v1.583a5.83 5.83 0 0 1-2.819 4.977 13.821 13.821 0 0 1-7.109 1.968Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="m12.825 59.71 1.21 1.612c.243.325.633.52 1.04.52h33.877a1.31 1.31 0 0 0 1.04-.52l1.209-1.612H12.825Zm36.126 4.264H15.074c-1.075 0-2.101-.513-2.746-1.373L8.56 57.578h46.903l-3.767 5.023a3.447 3.447 0 0 1-2.746 1.373Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M2.208 53.314c.164 1.07.8 2.034 1.74 2.598a11.7 11.7 0 0 0 6.015 1.666h44.102a11.69 11.69 0 0 0 6.013-1.665 3.689 3.689 0 0 0 1.741-2.599H2.21Zm51.856 6.396H9.964c-2.505 0-4.964-.681-7.112-1.97a5.833 5.833 0 0 1-2.818-4.976v-1.582h63.959v1.583a5.83 5.83 0 0 1-2.819 4.977 13.821 13.821 0 0 1-7.109 1.968ZM52.191 38.106l-1.507-1.508c2.052-2.051 2.835-4.052 2.392-6.12-.38-1.771-1.618-3.659-3.899-5.94-4.818-4.82-8.313-5.254-12.06-1.507l-1.508-1.508c6.08-6.082 11.516-2.052 15.076 1.508 2.585 2.585 4.008 4.81 4.477 7.002.597 2.79-.402 5.506-2.97 8.073M41.77 38.391c-1.305 0-3.172-.311-4.654-1.793-1.65-1.649-2.194-4.269-1.459-7.01a1.067 1.067 0 0 1 2.06.551c-.537 1.997-.198 3.849.907 4.952.965.966 2.244 1.168 3.147 1.168a1.066 1.066 0 0 1 0 2.132"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M4.685 51.182h55.363a14.236 14.236 0 0 0 1.813-6.921c0-4.875-2.473-9.344-6.562-12.052-.175 2.028-1.214 4.003-3.108 5.896-2.592 2.593-5.88 4.19-9.26 4.497-3.454.313-6.588-.747-8.83-2.99-4.25-4.248-3.964-11.56.437-16.91a26.862 26.862 0 0 0-8.523-1.367c-13.15 0-23.848 9.25-23.848 20.62 0 3.203.868 6.38 2.519 9.228Zm56.572 2.133H3.489l-.313-.505C1.12 49.5.034 45.745.034 41.955c0-12.545 11.655-22.752 25.98-22.752 3.744 0 7.37.69 10.777 2.054l1.54.617-1.201 1.145c-4.586 4.583-5.263 11.346-1.523 15.085 1.787 1.786 4.323 2.627 7.13 2.373 2.884-.262 5.706-1.64 7.946-3.88 2.038-2.037 2.822-4.025 2.397-6.078l-.443-2.147 1.963.978c5.792 2.883 9.392 8.597 9.392 14.91 0 2.995-.838 5.948-2.425 8.543l-.31.512Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M10.037 31.995a1.066 1.066 0 0 1-.764-1.809c4.148-4.27 10.25-6.72 16.742-6.72a1.066 1.066 0 0 1 0 2.133c-5.919 0-11.464 2.213-15.213 6.073a1.065 1.065 0 0 1-.765.323M52.137 26.099l-1.507-1.507 3.927-3.93c1.046-1.043 2.648-1.202 3.987-.394.21.126.464.22.757.28.635.13 1.276 0 1.719-.343A2.133 2.133 0 0 0 60 16.393a3.138 3.138 0 0 1-2.68-2.68 2.107 2.107 0 0 0-.607-1.234 2.148 2.148 0 0 0-1.644-.621c-.62.039-1.175.336-1.56.835-.344.444-.471 1.086-.344 1.719.06.294.154.549.281.757.808 1.34.649 2.942-.395 3.986l-3.929 3.93-1.507-1.508 3.928-3.93c.391-.389.32-.972.077-1.376a4.504 4.504 0 0 1-.544-1.438c-.253-1.246.018-2.503.745-3.444a4.281 4.281 0 0 1 3.113-1.658 4.269 4.269 0 0 1 3.286 1.24 4.22 4.22 0 0 1 1.215 2.47c.053.417.42.784.836.838.945.12 1.8.54 2.472 1.214a4.263 4.263 0 0 1-.419 6.399c-.94.727-2.194 1-3.445.745a4.478 4.478 0 0 1-1.435-.543c-.404-.243-.986-.314-1.378.077l-3.93 3.928Z"
      clipRule="evenodd"
    />
  </svg>
);
export { Turkey };
