import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Kindness = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <g clipPath="url(#kindness_svg__a)">
      <g fill={colors.ultramarine900} clipPath="url(#kindness_svg__b)">
        <path d="M31.995 59.707c-.17 0-.341-.043-.49-.117l-6.986-3.626C10.548 48.733-3.061 34.9.609 17.784c.842-4.352 3.465-8.276 7.187-10.73A16.74 16.74 0 0 1 20.52 4.667a16.927 16.927 0 0 1 11.475 9.417 16.914 16.914 0 0 1 11.454-9.417 16.816 16.816 0 0 1 12.745 2.378 16.806 16.806 0 0 1 7.198 10.76c3.658 17.086-9.95 30.918-23.91 38.16l-6.996 3.626a.981.981 0 0 1-.491.117ZM17.032 6.425c-2.837 0-5.663.832-8.052 2.41A14.67 14.67 0 0 0 2.7 18.222C-.725 34.175 12.233 47.197 25.5 54.066l6.495 3.37 6.506-3.37c13.267-6.88 26.214-19.901 22.801-35.835a14.7 14.7 0 0 0-6.282-9.406 14.665 14.665 0 0 0-11.102-2.08A14.758 14.758 0 0 0 33.03 17.55c-.117.47-.543.81-1.034.81-.49 0-.917-.33-1.035-.81A14.78 14.78 0 0 0 20.04 6.735a14.365 14.365 0 0 0-3.018-.32l.01.01Z" />
        <path d="M5.823 19.97c-.064 0-.139 0-.213-.021a1.078 1.078 0 0 1-.843-1.259C5.93 12.846 10.9 8.655 16.861 8.516c.928 0 1.824.107 2.71.31.575.127.927.703.799 1.279a1.058 1.058 0 0 1-1.28.8 9.677 9.677 0 0 0-2.207-.256c-4.928.117-9.066 3.605-10.036 8.457a1.052 1.052 0 0 1-1.045.853l.021.011ZM19.197 28.78H19.1a4.42 4.42 0 0 1-2.827-1.409 1.063 1.063 0 0 1 .075-1.503 1.063 1.063 0 0 1 1.504.074c.17.182.352.33.565.448a3.216 3.216 0 0 1 2.912-1.866c1.76 0 3.2 1.44 3.2 3.2a1.07 1.07 0 0 1-1.067 1.066 1.07 1.07 0 0 1-1.067-1.067 1.07 1.07 0 0 0-1.066-1.066 1.07 1.07 0 0 0-1.067 1.066c0 .299-.128.587-.352.79a1.036 1.036 0 0 1-.714.277v-.01ZM31.995 37.311c-6.325 0-6.4-4.223-6.4-4.266 0-.586.48-1.066 1.067-1.066s1.056.469 1.067 1.045c.01.234.245 2.154 4.266 2.154 4.02 0 4.255-1.94 4.266-2.154A1.083 1.083 0 0 1 37.327 32h.021c.576.01 1.046.47 1.046 1.056 0 .043-.075 4.266-6.4 4.266v-.01ZM15.997 36.245c-.277 0-.543-.107-.757-.31a1.062 1.062 0 0 1 0-1.503l1.067-1.067a1.062 1.062 0 1 1 1.503 1.504l-1.066 1.066c-.213.214-.48.31-.757.31h.01ZM20.264 36.245c-.278 0-.544-.107-.758-.31a1.062 1.062 0 0 1 0-1.503l1.067-1.067a1.062 1.062 0 1 1 1.503 1.504l-1.066 1.066c-.213.214-.48.31-.757.31h.01ZM47.992 36.245c-.277 0-.544-.107-.757-.31l-1.067-1.066a1.062 1.062 0 1 1 1.504-1.504l1.067 1.067a1.062 1.062 0 0 1-.758 1.813h.011ZM43.726 36.245c-.277 0-.544-.107-.757-.31l-1.067-1.066a1.062 1.062 0 1 1 1.504-1.504l1.066 1.067a1.062 1.062 0 0 1-.757 1.813h.011ZM5.333 24.513a1.07 1.07 0 0 0 1.066-1.066 1.07 1.07 0 0 0-1.066-1.067 1.07 1.07 0 0 0-1.067 1.067c0 .586.48 1.066 1.067 1.066Z" />
        <path d="M20.295 26.913c-.234 0-.48-.085-.682-.245a5.752 5.752 0 0 1-1.355-1.643 1.064 1.064 0 0 1 .427-1.45 1.064 1.064 0 0 1 1.45.426c.214.395.502.736.843 1.024.448.373.512 1.045.139 1.504a1.056 1.056 0 0 1-.822.384ZM44.792 28.78c-.266 0-.522-.097-.714-.278a1.067 1.067 0 0 1-.352-.79 1.07 1.07 0 0 0-1.067-1.066 1.07 1.07 0 0 0-1.066 1.067 1.07 1.07 0 0 1-1.067 1.066 1.07 1.07 0 0 1-1.066-1.066c0-1.76 1.44-3.2 3.2-3.2 1.29 0 2.399.768 2.91 1.867.214-.118.406-.267.566-.448a1.063 1.063 0 0 1 1.504-.075c.437.395.47 1.067.075 1.504a4.321 4.321 0 0 1-2.827 1.407h-.096v.011Z" />
        <path d="M43.694 26.913c-.31 0-.608-.128-.821-.384a1.076 1.076 0 0 1 .138-1.504c.342-.288.63-.63.843-1.024a1.067 1.067 0 1 1 1.866 1.035 5.75 5.75 0 0 1-1.354 1.642c-.203.17-.438.245-.683.245l.01-.01Z" />
      </g>
    </g>
    <defs>
      <clipPath id="kindness_svg__a">
        <path fill={colors.white} d="M0 0h64v64H0z" />
      </clipPath>
      <clipPath id="kindness_svg__b">
        <path fill={colors.white} d="M0 4.293h64v55.415H0z" />
      </clipPath>
    </defs>
  </svg>
);
export { Kindness };
