import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Secure = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <g fill={colors.ultramarine900} clipPath="url(#secure_svg__a)">
      <path d="M42.667 54.144H21.333a1.07 1.07 0 0 0-1.066 1.067c0 .586.48 1.066 1.066 1.066h21.334a1.07 1.07 0 0 0 1.066-1.066 1.07 1.07 0 0 0-1.066-1.067Z" />
      <path d="M52.267 6.4c0-3.53-2.87-6.4-6.4-6.4H18.133c-3.53 0-6.4 2.87-6.4 6.4v51.2c0 3.53 2.87 6.4 6.4 6.4h27.734c3.53 0 6.4-2.87 6.4-6.4V6.4Zm-9.195-4.267-1.547 3.083A2.133 2.133 0 0 1 39.616 6.4H24.373c-.81 0-1.546-.459-1.909-1.184l-1.547-3.083h22.155ZM50.133 25.6v32a4.266 4.266 0 0 1-4.266 4.267H18.133a4.266 4.266 0 0 1-4.266-4.267V6.4a4.266 4.266 0 0 1 4.266-4.267h.406l2.016 4.043a4.226 4.226 0 0 0 3.818 2.357h15.243a4.257 4.257 0 0 0 3.819-2.357l2.016-4.043h.405A4.265 4.265 0 0 1 50.123 6.4v19.2h.01Z" />
      <path d="M44.928 21.344c-8.107-1.013-12.256-4.032-12.288-4.064a1.089 1.089 0 0 0-1.28 0c-.043.032-4.192 3.05-12.299 4.064a1.06 1.06 0 0 0-.928 1.056v8.395a40.322 40.322 0 0 0 .342 5.674c.768 5.579 3.306 9.846 13.248 12.566.181.053.373.053.565 0 9.941-2.71 12.48-6.987 13.248-12.566.245-1.877.352-3.776.341-5.674V22.4a1.07 1.07 0 0 0-.938-1.056h-.011Zm-1.195 9.45a37.827 37.827 0 0 1-.32 5.387C42.87 40.14 41.536 44.224 32 46.891c-9.536-2.678-10.88-6.752-11.413-10.71a37.92 37.92 0 0 1-.32-5.386v-7.456A31.103 31.103 0 0 0 32 19.424a30.92 30.92 0 0 0 11.733 3.915v7.456Z" />
      <path d="M26.666 27.733v2.326a3.213 3.213 0 0 0-2.133 3.008v6.4c0 1.77 1.43 3.2 3.2 3.2h8.534c1.77 0 3.2-1.43 3.2-3.2v-6.4c0-1.355-.864-2.55-2.134-3.008v-2.326A5.335 5.335 0 0 0 32 22.4a5.335 5.335 0 0 0-5.334 5.333Zm10.667 11.734a1.07 1.07 0 0 1-1.066 1.066h-8.534a1.07 1.07 0 0 1-1.067-1.066v-6.4c0-.587.48-1.067 1.067-1.067h8.534c.586 0 1.066.48 1.066 1.067v6.4ZM35.2 27.733v2.134h-6.4v-2.134c0-1.77 1.43-3.2 3.2-3.2 1.77 0 3.2 1.43 3.2 3.2Z" />
    </g>
    <defs>
      <clipPath id="secure_svg__a">
        <path fill={colors.white} d="M11.733 0h40.533v64H11.733z" />
      </clipPath>
    </defs>
  </svg>
);
export { Secure };
