import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Steak = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M6.617 57.055c.869 1.22 1.628 2.099 2.256 2.612 1.608 1.317 6.876 3.075 11.204 1.742 2.493-.77 4.138-2.453 4.89-5.003 2.873-9.752 5.81-11.594 18.483-11.594 5.943 0 13.84-3.243 16.767-8.668 5.076-9.404-1.962-18.65-9.53-23.711-14.565-9.736-32.06 1.192-38.905 6.34-6.29 4.732-8.62 14.112-9.467 21.148-.738 6.121.79 12.207 4.301 17.134M16.756 64c-3.773 0-7.543-1.297-9.234-2.682-.785-.642-1.649-1.632-2.643-3.023v-.002C1.06 52.932-.604 46.317.196 39.666 1.093 32.218 3.61 22.252 10.5 17.072 32.145.788 46.642 7.165 51.871 10.66c13.3 8.893 13.82 19.832 10.223 26.496-3.431 6.358-12.166 9.79-18.645 9.79-12.392 0-13.958 1.647-16.438 10.062-.952 3.233-3.133 5.46-6.308 6.439-1.253.386-2.601.552-3.949.552"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M9.93 29.89c-.173 0-.348.029-.519.087-.482.16-.846.526-1.001 1.006-.79 2.463-1.381 5.463-1.86 9.446-.61 5.064.647 10.09 3.538 14.148.933 1.308 1.402 1.719 1.486 1.788.323.253 2.525 1.244 5.149 1.244 3.44 0 3.92-1.628 4.152-2.41.281-.96.556-1.858.836-2.698 1.32-3.968.684-8.357-1.704-11.74-.8-1.131-1.712-2.417-2.745-3.863-2.603-3.643-4.912-5.656-6.39-6.705a1.63 1.63 0 0 0-.941-.303m6.792 29.851c-3.065 0-5.789-1.143-6.501-1.726-.27-.222-.874-.803-1.87-2.2-3.201-4.492-4.593-10.048-3.92-15.64.496-4.122 1.114-7.249 1.947-9.844a3.666 3.666 0 0 1 2.358-2.378 3.716 3.716 0 0 1 3.367.5c2.346 1.663 4.666 4.087 6.894 7.206a823.872 823.872 0 0 1 2.752 3.874c2.777 3.933 3.518 9.034 1.985 13.64-.273.82-.54 1.694-.815 2.628-.288.975-1.163 3.94-6.197 3.94M39.57 37.344c4.017 2.492 8.882 2.705 12.691.559 2.001-1.127 3.494-2.472 4.203-3.785.84-1.558 4.394-9.753-8.147-18.139-3.138-2.097-6.718-3.16-10.638-3.16-7.482 0-15.648 2.94-19.86 7.152-2.575 2.574-2.17 5.726 1.202 9.367 2.578 2.783 6.239 4.524 10.306 4.902 2.811.261 6.872 1.011 10.243 3.103m6.971 4.164c-2.763 0-5.576-.788-8.096-2.351-3.018-1.873-6.731-2.551-9.316-2.791-4.591-.427-8.735-2.408-11.671-5.577C11.82 24.7 14.32 20.454 16.31 18.463c4.58-4.58 13.367-7.778 21.37-7.778 4.35 0 8.326 1.184 11.822 3.521 14.077 9.412 9.833 19.08 8.84 20.924-.912 1.689-2.652 3.29-5.032 4.631-2.074 1.167-4.401 1.746-6.77 1.746"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M47.992 26.681a3.204 3.204 0 0 0-3.2 3.2c0 1.764 1.437 3.2 3.2 3.2 1.764 0 3.2-1.436 3.2-3.2 0-1.764-1.436-3.2-3.2-3.2Zm0 8.532a5.339 5.339 0 0 1-5.332-5.332 5.338 5.338 0 0 1 5.333-5.332 5.338 5.338 0 0 1 5.332 5.332 5.339 5.339 0 0 1-5.333 5.332Z"
      clipRule="evenodd"
    />
  </svg>
);
export { Steak };
