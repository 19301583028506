import { noop } from 'lodash';
import { createContext, useContext } from 'react';

import type { Constants } from '@lib/queries';

export type TAppContext = Omit<Constants, '_type'> & { setContext: (context: Constants) => void };

export const AppContext = createContext<TAppContext>({
  appUrl: '',
  email: '',
  setContext: noop,
});

export const useAppContext = () => useContext(AppContext);

export const setAppContext = (context: Constants) => {
  const { setContext } = useAppContext();
  if (!setContext) return;

  setContext(context);
};
