import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Tuna = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      d="M32 2.133c-17.944 0-32 7.029-32 16v27.734c0 8.971 14.056 16 32 16 17.944 0 32-7.029 32-16V18.133c0-8.971-14.056-16-32-16Zm0 57.6c-15.195 0-28.176-5.593-29.713-12.496C6.964 52.738 18.354 56.533 32 56.533s25.036-3.795 29.713-9.296C60.175 54.14 47.195 59.733 32 59.733ZM61.867 41.6c0 6.939-13.677 12.8-29.867 12.8S2.133 48.539 2.133 41.6V30.663c4.577 5.2 16.069 8.804 29.867 8.804 13.798 0 25.29-3.604 29.867-8.804V41.6Zm0-16c0 6.36-13.677 11.733-29.867 11.733S2.133 31.96 2.133 25.6v-1.625C6.71 29.973 18.202 34.133 32 34.133c13.798 0 25.29-4.16 29.867-10.158V25.6ZM32 32C15.81 32 2.133 25.65 2.133 18.133 2.133 10.617 15.81 4.267 32 4.267s29.867 6.35 29.867 13.866C61.867 25.65 48.19 32 32 32Z"
    />
    <path
      fill={colors.ultramarine900}
      d="M27.809 27.587c-13.069-.92-21.41-6.216-21.41-9.454 0-2.297 4.812-6.631 15.138-8.64A1.067 1.067 0 0 0 21.13 7.4C11.358 9.3 4.267 13.813 4.267 18.133c0 4.794 9.4 10.591 23.39 11.582h.077a1.067 1.067 0 1 0 .075-2.133v.005ZM42.87 7.402a1.067 1.067 0 0 0-.407 2.091C52.79 11.502 57.6 15.836 57.6 18.133c0 3.238-8.34 8.534-21.409 9.454a1.067 1.067 0 1 0 .075 2.134h.077c13.991-.997 23.39-6.794 23.39-11.588 0-4.32-7.092-8.833-16.862-10.731Z"
    />
    <path
      fill={colors.ultramarine900}
      d="m38.933 18.596-1.008-7.058a5.986 5.986 0 0 0-11.85 0l-1.009 7.058a4.267 4.267 0 0 0 4.223 4.87h5.421a4.267 4.267 0 0 0 4.223-4.87ZM36.322 20.6a2.133 2.133 0 0 1-1.612.734h-5.42a2.133 2.133 0 0 1-2.113-2.435l1.008-7.059a3.854 3.854 0 0 1 7.63 0l1.007 7.058a2.134 2.134 0 0 1-.5 1.702Z"
    />
    <path
      fill={colors.ultramarine900}
      d="M33.067 17.067h-2.134a1.067 1.067 0 0 0 0 2.133h2.134a1.067 1.067 0 0 0 0-2.133ZM32 12.8a1.067 1.067 0 1 0 0-2.133 1.067 1.067 0 0 0 0 2.133ZM43.004 44.746a1.068 1.068 0 0 0-.674-2.027 9.46 9.46 0 0 0-3.583 2.112c-1.414-1.934-4.764-3.231-8.88-3.231-5.474 0-9.6 2.292-9.6 5.333 0 3.041 4.127 5.334 9.6 5.334 4.116 0 7.466-1.297 8.88-3.233a9.46 9.46 0 0 0 3.583 2.112 1.068 1.068 0 0 0 .674-2.027 6.94 6.94 0 0 1-3.316-2.186 6.933 6.933 0 0 1 3.316-2.187Zm-13.137 5.387c-4.274 0-7.467-1.69-7.467-3.2 0-1.51 3.194-3.2 7.467-3.2s7.466 1.69 7.466 3.2c0 1.51-3.193 3.2-7.466 3.2ZM10.667 39.467a1.067 1.067 0 1 0 0-2.134 1.067 1.067 0 0 0 0 2.134ZM10.667 43.733a1.067 1.067 0 1 0 0-2.133 1.067 1.067 0 0 0 0 2.133ZM10.667 48a1.067 1.067 0 1 0 0-2.133 1.067 1.067 0 0 0 0 2.133ZM54.4 39.467a1.067 1.067 0 1 0 0-2.134 1.067 1.067 0 0 0 0 2.134ZM54.4 43.733a1.067 1.067 0 1 0 0-2.133 1.067 1.067 0 0 0 0 2.133ZM54.4 48a1.067 1.067 0 1 0 0-2.133 1.067 1.067 0 0 0 0 2.133Z"
    />
  </svg>
);
export { Tuna };
