import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const BatteryEmpty = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={60} height={60} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      d="M14.309 58.507a5 5 0 0 0 7.07 0l37.043-37.041a5.006 5.006 0 0 0 0-7.071l-2.153-2.153-7.833-7.835 2.139-2.141a1.036 1.036 0 0 1 1.433 0l5.695 5.7a1.015 1.015 0 0 1 0 1.433 1 1 0 1 0 1.414 1.414 3.012 3.012 0 0 0 0-4.261L53.422.854a3.085 3.085 0 0 0-4.261 0l-2.14 2.14-1.446-1.446a5 5 0 0 0-7.071 0L1.462 38.59a5.005 5.005 0 0 0 0 7.07l12.847 12.847ZM2.877 40.004 39.919 2.966a3 3 0 0 1 4.242 0l12.847 12.842a3 3 0 0 1 0 4.242L19.965 57.092a3 3 0 0 1-4.242 0L2.877 44.246a3 3 0 0 1 0-4.242Z"
    />
  </svg>
);
export { BatteryEmpty };
