import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Battery4 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={61} height={60} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      d="M58.696 10.835a1 1 0 0 0 1.414 0 3.017 3.017 0 0 0 0-4.26L54.42.88a3.017 3.017 0 0 0-4.26 0l-2.14 2.14-1.446-1.446a5 5 0 0 0-7.071 0L2.461 38.616a5 5 0 0 0 0 7.071l12.846 12.846a5 5 0 0 0 7.071 0L59.42 21.491a5 5 0 0 0 0-7.07l-2.153-2.154-7.833-7.833 2.14-2.136a1.013 1.013 0 0 1 1.432 0l5.69 5.69a1.013 1.013 0 0 1 0 1.432 1 1 0 0 0 0 1.415Zm-2.847 2.846 2.153 2.153a3 3 0 0 1 0 4.243L20.964 57.12a3 3 0 0 1-4.243 0L3.875 44.273a3 3 0 0 1 0-4.243L40.917 2.988a3 3 0 0 1 4.243 0l2.153 2.153 8.536 8.54Z"
    />
    <path
      fill={colors.ultramarine900}
      d="M14.563 35.035a2.047 2.047 0 0 0-2.828 0l-5.7 5.7a2 2 0 0 0 0 2.83l11.4 11.4a2 2 0 0 0 2.83 0l5.7-5.7a2 2 0 0 0 0-2.829l-11.402-11.4Zm4.28 18.513-11.4-11.4 5.7-5.7 11.4 11.4-5.7 5.7ZM24.526 25.072a2 2 0 0 0-2.828 0l-5.7 5.7a2 2 0 0 0-.002 2.826l11.4 11.4a2 2 0 0 0 2.828 0l5.7-5.7a2 2 0 0 0 0-2.828L24.526 25.072Zm4.28 18.513-11.4-11.4 5.7-5.7 11.4 11.4-5.7 5.7ZM34.49 15.108a2 2 0 0 0-2.829 0l-5.7 5.7a2 2 0 0 0 0 2.83l11.395 11.4a2 2 0 0 0 2.83 0l5.7-5.7a2 2 0 0 0 0-2.829l-11.397-11.4Zm4.28 18.514-11.4-11.4 5.7-5.7 11.4 11.4-5.7 5.7ZM41.624 5.146l-5.7 5.7a2 2 0 0 0 0 2.828l11.4 11.4a2 2 0 0 0 2.828 0l5.7-5.7a2 2 0 0 0 0-2.829L44.453 5.146a2 2 0 0 0-2.83 0Zm7.109 18.513-11.4-11.4 5.7-5.7 11.4 11.4-5.7 5.7Z"
    />
  </svg>
);
export { Battery4 };
