import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const SwipeLeftLines = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      d="M55.765 49.581c-.035-.01-.072-.01-.107-.02.018-.5.048-1.023.098-1.583.376-4.151-.766-6.25-2.835-10.058l-7.64-14.058a4.267 4.267 0 0 0-7.536.07 4.224 4.224 0 0 0-6.816 1.617 4.201 4.201 0 0 0-5.802 1.44l-3.487-6.414A8.387 8.387 0 0 0 23.01 16a8.533 8.533 0 1 0-8.943 8.513l7.006 12.906-.894 1.079a6.374 6.374 0 0 0-.699 7.146l1.842 3.388c1.098 2.02 2.757 5.075 6.08 6.056 1.622.485 3.282.835 4.961 1.048a9.134 9.134 0 0 1 4.833 2.918 3.192 3.192 0 0 0 4.192 4.557l14.999-8.145a3.2 3.2 0 0 0-.621-5.88v-.006Zm-47.69-33.58a6.4 6.4 0 0 1 12.8 0 6.33 6.33 0 0 1-.455 2.327l-2.105-3.877a4.373 4.373 0 0 0-5.674-1.978 4.23 4.23 0 0 0-2.219 2.535 4.294 4.294 0 0 0 .31 3.359l2.064 3.801a6.4 6.4 0 0 1-4.72-6.167Zm24.56 38.014a28.758 28.758 0 0 1-4.633-.98c-2.47-.731-3.82-3.216-4.806-5.029l-1.842-3.388a4.249 4.249 0 0 1 .465-4.761l.35-.427 2.3 4.236a1.067 1.067 0 0 0 1.875-1.019l-3.026-5.567c-.012-.025-.026-.048-.04-.072l-10.676-19.66a2.133 2.133 0 0 1 .953-2.947 2.268 2.268 0 0 1 2.88 1.066l9.59 17.644 1.017 1.871a1.067 1.067 0 1 0 1.875-1.019l-2.034-3.744a2.133 2.133 0 0 1 3.748-2.037l.51.937 2.037 3.748a1.067 1.067 0 1 0 1.875-1.019l-2.038-3.75a2.135 2.135 0 0 1 3.754-2.034l2.546 4.684a1.067 1.067 0 1 0 1.875-1.018l-1.527-2.81a2.133 2.133 0 0 1 3.748-2.038l7.641 14.058c1.992 3.666 2.902 5.334 2.585 8.848-.063.702-.094 1.35-.107 1.97-.063.03-.129.047-.19.082L38.86 57.707a11.2 11.2 0 0 0-6.226-3.692Zm22.732-.427-14.995 8.15a1.067 1.067 0 1 1-1.019-1.875l14.995-8.15a1.067 1.067 0 1 1 1.019 1.875ZM49.367 7.457h-25.6a1.067 1.067 0 1 0 0 2.133h25.6a1.067 1.067 0 0 0 0-2.133Z"
    />
    <path
      fill={colors.ultramarine900}
      d="M39.567 12.79c0-.589-.478-1.066-1.067-1.066H26.767a1.067 1.067 0 0 0 0 2.133H38.5c.59 0 1.067-.478 1.067-1.067ZM29.767 15.99a1.067 1.067 0 0 0 0 2.134H35.1a1.067 1.067 0 0 0 0-2.134h-5.333Z"
    />
  </svg>
);
export { SwipeLeftLines };
