import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Kettlebell = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      d="M12.186 62.066A5.302 5.302 0 0 0 16.308 64h31.386c1.609 0 3.105-.699 4.106-1.918 4.418-5.382 6.42-12.189 5.634-19.165-.383-3.398-1.464-6.593-3.068-9.463l1.868-4.72c.42-1.058.4-2.219-.054-3.264a4.238 4.238 0 0 0-.743-1.128c.012-.29.03-.58.03-.874C55.466 10.527 44.938 0 32 0 19.06 0 8.533 10.527 8.533 23.467c0 .294.017.583.029.874a4.29 4.29 0 0 0-.743 1.128 4.23 4.23 0 0 0-.055 3.264l1.862 4.716a25.612 25.612 0 0 0-3.228 12.418 25.628 25.628 0 0 0 5.787 16.2h.001Zm37.965-1.338a3.157 3.157 0 0 1-2.457 1.139H16.308c-.97 0-1.871-.42-2.472-1.153a23.484 23.484 0 0 1-5.303-14.847 23.509 23.509 0 0 1 7.782-17.454c4.365-3.925 9.845-6.015 15.652-6.015.863 0 1.734.046 2.607.14 10.802 1.15 19.524 9.82 20.739 20.617.72 6.4-1.113 12.641-5.162 17.573ZM17.4 21.688c-.064-.028-.131-.039-.195-.063.91-7.405 7.238-13.09 14.795-13.09 7.556 0 13.884 5.685 14.794 13.09-.064.024-.131.035-.195.063a4.228 4.228 0 0 0-2.035 1.892c-2.952-1.674-6.252-2.79-9.763-3.163-5.47-.582-10.772.525-15.378 3.14a4.23 4.23 0 0 0-2.023-1.87Zm36.85 6.26-1.287 3.253a25.934 25.934 0 0 0-6.591-6.495 2.13 2.13 0 0 1 2.706-1.09l3.968 1.568a2.133 2.133 0 0 1 1.204 2.765ZM32 2.134c11.603 0 21.053 9.318 21.31 20.86l-3.445-1.361a4.287 4.287 0 0 0-.951-.244C47.884 12.91 40.647 6.398 32 6.398c-8.647 0-15.884 6.512-16.913 14.989-.323.048-.642.12-.952.243l-3.445 1.362c.255-11.544 9.705-20.86 21.31-20.86L32 2.132ZM9.748 27.95c-.21-.528-.2-1.106.028-1.628a2.128 2.128 0 0 1 1.175-1.136l3.97-1.569c.526-.21 1.105-.2 1.627.028.477.208.848.58 1.07 1.047a26.263 26.263 0 0 0-2.73 2.137 25.517 25.517 0 0 0-3.856 4.372L9.75 27.949Z"
    />
    <path
      fill={colors.ultramarine900}
      d="M32 29.867c-7.646 0-13.867 5.741-13.867 12.8 0 7.058 6.22 12.8 13.867 12.8 7.645 0 13.866-5.742 13.866-12.8 0-7.059-6.22-12.8-13.866-12.8Zm0 23.466c-6.47 0-11.734-4.785-11.734-10.666C20.266 36.785 25.53 32 32 32c6.469 0 11.733 4.785 11.733 10.667 0 5.881-5.264 10.666-11.733 10.666Z"
    />
    <path
      fill={colors.ultramarine900}
      d="M30.62 37.646a1.065 1.065 0 0 0-1.508 0l-2.446 2.446V38.4a1.067 1.067 0 0 0-2.133 0v8.533a1.067 1.067 0 0 0 2.133 0v-1.691l2.446 2.446a1.064 1.064 0 0 0 1.508 0 1.066 1.066 0 0 0 0-1.509l-3.512-3.512 3.512-3.513a1.066 1.066 0 0 0 0-1.508ZM39.466 42.667h-2.133a1.067 1.067 0 0 0 0 2.133c0 .588-.48 1.067-1.067 1.067a1.068 1.068 0 0 1-1.067-1.067v-4.267c0-.587.48-1.066 1.067-1.066.588 0 1.067.479 1.067 1.066a1.068 1.068 0 0 0 2.133 0c0-1.764-1.436-3.2-3.2-3.2a3.204 3.204 0 0 0-3.2 3.2V44.8c0 1.764 1.436 3.2 3.2 3.2 1.764 0 3.2-1.436 3.2-3.2a1.067 1.067 0 0 0 0-2.133Z"
    />
  </svg>
);
export { Kettlebell };
