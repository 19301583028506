import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Milk = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M24.299 4.266h15.4l.533-2.133H23.765l.534 2.133ZM40.533 6.4H23.466c-.49 0-.916-.334-1.035-.809l-1.067-4.266A1.065 1.065 0 0 1 22.4 0h19.2a1.068 1.068 0 0 1 1.034 1.325l-1.067 4.266a1.066 1.066 0 0 1-1.033.809ZM32 10.666a2.136 2.136 0 0 0-2.134 2.134c0 1.177.957 2.133 2.133 2.133a2.135 2.135 0 0 0 2.134-2.133 2.136 2.136 0 0 0-2.134-2.134Zm0 6.4a4.27 4.27 0 0 1-4.267-4.266 4.27 4.27 0 0 1 4.266-4.267 4.271 4.271 0 0 1 4.267 4.267A4.271 4.271 0 0 1 32 17.066Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M20.614 19.2h22.772L39.729 6.4H24.271l-3.657 12.8ZM44.8 21.334H19.2a1.067 1.067 0 0 1-1.026-1.36L22.44 5.042c.13-.458.55-.775 1.026-.775h17.066c.476 0 .895.317 1.027.775l4.266 14.934a1.07 1.07 0 0 1-.174.935 1.072 1.072 0 0 1-.852.424Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M20.265 21.334V60.8c0 .589.478 1.067 1.067 1.067h21.334c.589 0 1.066-.478 1.066-1.067V21.334H20.266ZM42.665 64H21.333a3.204 3.204 0 0 1-3.2-3.2V20.266c0-.59.477-1.066 1.066-1.066h25.6c.59 0 1.067.477 1.067 1.066V60.8c0 1.764-1.435 3.2-3.2 3.2Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M32 25.6c-4.117 0-7.467 3.35-7.467 7.467v17.067c0 4.116 3.35 7.466 7.466 7.466 4.117 0 7.467-3.35 7.467-7.466V33.067c0-4.116-3.35-7.467-7.467-7.467Zm0 34.134c-5.294 0-9.6-4.306-9.6-9.6V33.066c0-5.293 4.306-9.6 9.6-9.6s9.6 4.307 9.6 9.6v17.068c0 5.294-4.306 9.6-9.6 9.6ZM62.933 64H1.067a1.066 1.066 0 1 1 0-2.133h61.866a1.066 1.066 0 1 1 0 2.133Z"
      clipRule="evenodd"
    />
  </svg>
);
export { Milk };
