import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Double = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <g clipPath="url(#double_svg__a)">
      <path
        fill={colors.ultramarine900}
        d="M14.982 43.706a7.586 7.586 0 0 1 4.921 2.267c.468.565.64.803.78 1.008a32.481 32.481 0 0 0 3.031 3.472 20.436 20.436 0 0 0 3.388 2.987 8.567 8.567 0 0 1 2.853 4.566A3.19 3.19 0 0 0 31.467 64h17.066a3.19 3.19 0 0 0 2.178-5.53c.256-.433.534-.877.843-1.345 2.313-3.472 2.313-5.857 2.313-10.192v-16a4.266 4.266 0 0 0-6.655-3.534 4.237 4.237 0 0 0-5.705-2.512 14.923 14.923 0 1 0-15.382 4.346l-.006 9.167c-3.533-2.257-6.458-3.5-10.91-3.2a4.267 4.267 0 0 0-.226 8.503v.003Zm33.551 18.16H31.467a1.067 1.067 0 0 1 0-2.133h17.066a1.067 1.067 0 1 1 0 2.134ZM17.6 14.934a12.8 12.8 0 1 1 21.597 9.274 4.195 4.195 0 0 0-4.53-.147v-.554a9.608 9.608 0 1 0-8.534-.01v3.5A12.741 12.741 0 0 1 17.6 14.932Zm9.865-3.093a4.292 4.292 0 0 0-1.332 3.093v6.1a7.4 7.4 0 0 1-3.2-6.1 7.467 7.467 0 1 1 11.734 6.108v-5.926a4.373 4.373 0 0 0-4.04-4.448 4.212 4.212 0 0 0-3.162 1.173ZM13.346 39.214a2.151 2.151 0 0 1 2.002-1.88c3.88-.264 6.389.8 9.632 2.868.662.415 1.497.439 2.18.062a2.126 2.126 0 0 0 1.096-1.864l.01-23.467a2.133 2.133 0 0 1 2.24-2.133 2.27 2.27 0 0 1 2.027 2.315V32a1.067 1.067 0 0 0 2.134 0v-4.267a2.133 2.133 0 1 1 4.266 0v5.334a1.067 1.067 0 0 0 2.134 0V28.8a2.133 2.133 0 0 1 4.266 0v5.333a1.067 1.067 0 0 0 2.134 0v-3.2a2.133 2.133 0 1 1 4.266 0v16c0 4.173 0 6.08-1.954 9.008-.397.596-.73 1.145-1.04 1.68-.07 0-.135-.021-.206-.021H32.06a10.791 10.791 0 0 0-3.703-5.893 18.672 18.672 0 0 1-3.083-2.714 32.228 32.228 0 0 1-2.846-3.242 15.358 15.358 0 0 0-.877-1.136 9.674 9.674 0 0 0-6.325-3.03 2.145 2.145 0 0 1-1.88-2.371Z"
      />
    </g>
    <defs>
      <clipPath id="double_svg__a">
        <path fill={colors.white} d="M10.133 0h43.733v64H10.133z" />
      </clipPath>
    </defs>
  </svg>
);
export { Double };
