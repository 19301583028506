import { motion } from 'framer-motion';

import type { CurveProps } from './types';

import { hiddenOnLg, onlyOnLg } from '~/styles/utils/hidden-on-breakpoint.css';

const ProgesteroneCurveSm = ({ scrollYProgress, ...props }: CurveProps) => (
  <motion.svg xmlns="http://www.w3.org/2000/svg" viewBox={'0 0 342 2070'} fill="none" height="100%" {...props}>
    <motion.path
      initial={{ pathLength: 0 }}
      style={{ pathLength: scrollYProgress }}
      stroke="#6966FF"
      strokeLinecap="round"
      strokeWidth={14}
      d="M27.5589 7L24.8997 201.453C24.8997 201.453 18.5057 790.819 24.8997 965.522C31.2937 1140.22 341.778 1319.18 334.887 1501.65C328.786 1663.2 70.2489 1828.56 27.0652 1984.02L8 2062"
    />
  </motion.svg>
);

const ProgesteroneCurve = ({ scrollYProgress }: CurveProps) => {
  return (
    <>
      <ProgesteroneCurveSm className={hiddenOnLg} scrollYProgress={scrollYProgress} />
      <motion.svg
        className={onlyOnLg}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={'0 0 550 2071'}
        fill="none"
        height="100%"
      >
        <motion.path
          initial={{ pathLength: 0 }}
          style={{ pathLength: scrollYProgress }}
          stroke="#6966FF"
          strokeLinecap="round"
          strokeWidth={14}
          d="M40 7.5L35.6494 202C35.6494 202 25.1883 791.51 35.6494 966.255C46.1105 1141 554.089 1320 542.815 1502.51C532.833 1664.11 120 1780 39.1924 1985L8 2063"
        />
      </motion.svg>
    </>
  );
};

export { ProgesteroneCurve, ProgesteroneCurveSm };
