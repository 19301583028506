import { motion } from 'framer-motion';

import type { CurveProps } from './types';

import { onlyOnLg, hiddenOnLg } from '~/styles/utils/hidden-on-breakpoint.css';

const OestrogenCurve = ({ scrollYProgress, ...props }: CurveProps) => {
  return (
    <>
      <OestrogenCurveSm className={hiddenOnLg} scrollYProgress={scrollYProgress} />
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={'0 0 427 2022'}
        fill="none"
        height="100%"
        className={onlyOnLg}
        {...props}
      >
        <motion.path
          initial={{ pathLength: 0 }}
          style={{ pathLength: scrollYProgress }}
          stroke="#FE6A96"
          strokeLinecap="round"
          strokeWidth={14}
          d="M44.5 7.5L40.183 99C40.183 99 29.754 232.529 72.4179 430.465C134.721 719.515 448.318 854 416.778 992C400.551 1063 133.922 1111.5 133.922 1183C133.922 1235.63 264.066 1285.5 277.768 1365.5C289.071 1431.5 104.307 1511 37.2196 1703C14.0144 1769.41 7 1834.87 7 1882V2014.5"
        />
      </motion.svg>
    </>
  );
};

const OestrogenCurveSm = ({ scrollYProgress, ...props }: CurveProps) => (
  <motion.svg xmlns="http://www.w3.org/2000/svg" viewBox={'0 0 266 2021'} fill="none" height="100%" {...props}>
    <motion.path
      initial={{ pathLength: 0 }}
      style={{ pathLength: scrollYProgress }}
      stroke="#FE6A96"
      strokeLinecap="round"
      strokeWidth={14}
      d="M29.9369 7L27.2964 98.5C27.2964 98.5 20.9175 232.029 47.0129 429.965C85.1204 719.015 276.932 853.5 257.641 991.5C247.715 1062.5 84.6321 1111 84.6321 1182.5C84.6321 1235.13 164.235 1285 172.615 1365C179.529 1431 66.5179 1510.5 25.4838 1702.5C11.2903 1768.91 7 1834.37 7 1881.5V2014"
    />
  </motion.svg>
);

export { OestrogenCurve, OestrogenCurveSm };
