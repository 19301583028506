import * as Popover from '@radix-ui/react-popover';

import * as styles from './index.css';

import { Box } from '~/components/box';
import { Button } from '~/components/button';
import { MenuAlt3 } from '~/icons';

export const MobileNav = ({ container }: { container: HTMLElement | null }) => {
  return (
    <>
      <Box display={{ sm: 'flex', md: 'none' }} gap="2">
        <Button href="/download" forceButton variant="small">
          Try FYR
        </Button>
        <Popover.Trigger asChild>
          <Box as="button" display="block" borderStyle="none" aria-label="Open nav">
            <MenuAlt3 aria-hidden />
          </Box>
        </Popover.Trigger>
      </Box>
      <Popover.Portal container={container}>
        <Popover.Content className={styles.mobileNav}>
          <Box aria-label="Mobile nav" aria-modal display="flex" flexDirection="column" alignItems="stretch">
            <Button href="/about" className={styles.listEl} variant="text">
              About Us
            </Button>
            <Button href="/blog" className={styles.listEl} variant="text">
              Blog
            </Button>
            <Box className={styles.listEl}>
              <Button href="/faq" variant="text">
                FAQ
              </Button>
            </Box>
          </Box>
        </Popover.Content>
      </Popover.Portal>
    </>
  );
};
