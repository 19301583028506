import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';

type CurveProps = {
  color: keyof typeof colors;
};

const Curve = (props: SVGProps<SVGSVGElement> & CurveProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox={'0 0 608 237'} fill="none" {...props}>
    <path
      fill={props.color ? colors[props.color] : 'url(#curve_svg__a)'}
      d="M103.631 190.996C80.823 226.1 62.223 233.807 1.853 234.264c-2.472 0-2.477 2.466.023 2.466 50.876-.294 90-2.781 115.9-45.734 25.898-42.953 51.792-176.339 109.384-167.414 57.591 8.926 73.136 161.401 111.367 178.65 38.232 17.249 72.659-52.948 96.196-60.371 23.537-7.424 38.315 2.464 66.597 43.122 28.282 40.658 44.398 45.587 57.964 49.281 14.476 3.941 41.932 2.465 46.865 2.465 2.466 0 2.466-2.465.01-2.465-51.915 0-68.192-12.174-98.289-55.441-30.097-43.268-46.785-61.967-75.718-54.211-28.933 7.755-50.46 67.718-84.992 60.371-34.531-7.347-58.709-178.653-120-184.812-61.291-6.158-100.721 155.721-123.529 190.825Z"
    />
    <defs>
      <linearGradient id="curve_svg__a" x1={0.614} x2={607.229} y1={0} y2={236.96} gradientUnits="userSpaceOnUse">
        <stop stopColor={colors.ultramarine200} />
        <stop offset={1} stopColor={colors.ultramarine100} />
        <stop offset={1} stopColor={colors.ultramarine100} stopOpacity={0.56} />
      </linearGradient>
    </defs>
  </svg>
);
export { Curve };
