import clsx from 'clsx';
import Link from 'next/link';

import { UserAgent } from '../user-agent';

import * as styles from './index.css';

import { Box } from '~/components/box';
import { useAppContext } from '~/components/context';
import { AppStore } from '~/icons/app-store';

interface ButtonProps extends React.PropsWithChildren {
  href: string;
  variant?: 'small' | 'large' | 'text';
  className?: string;
  forceButton?: boolean;
}

export const Button = ({ children, href, variant = 'small', className, forceButton }: ButtonProps) => {
  const { appUrl } = useAppContext();

  return (
    <UserAgent>
      {(userAgent) => {
        if (userAgent.isIOS && !forceButton && variant !== 'text') {
          return (
            <Link href={appUrl ?? href}>
              <AppStore />
            </Link>
          );
        }

        return (
          <Box className={clsx(styles.button({ variant }), className)}>
            <Link className={styles.link} href={userAgent.isIOS && variant !== 'text' ? appUrl : href}>
              {children}
            </Link>
          </Box>
        );
      }}
    </UserAgent>
  );
};
