import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Calendar = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M10.667 1.067c0-.59.477-1.067 1.066-1.067H19.2c.59 0 1.067.478 1.067 1.067v3.2h23.466v-3.2C43.733.477 44.211 0 44.8 0h7.467c.589 0 1.066.478 1.066 1.067v3.2H60.8c.59 0 1.067.477 1.067 1.066v57.6c0 .59-.478 1.067-1.067 1.067H3.2c-.59 0-1.067-.478-1.067-1.067v-57.6c0-.589.478-1.066 1.067-1.066h7.467v-3.2ZM12.8 8.533h5.333v-6.4H12.8v6.4ZM20.267 9.6c0 .59-.478 1.067-1.067 1.067h-7.467c-.589 0-1.066-.478-1.066-1.067V6.4h-6.4V16h55.466V6.4h-6.4v3.2c0 .59-.477 1.067-1.066 1.067H44.8c-.59 0-1.067-.478-1.067-1.067V6.4H20.267v3.2Zm39.466 8.533H4.267v43.734h55.466V18.133Zm-8.533-16h-5.333v6.4H51.2v-6.4Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M11.733 25.6c0-.59.478-1.067 1.067-1.067h38.4c.59 0 1.067.478 1.067 1.067v28.8c0 .59-.478 1.067-1.067 1.067H12.8c-.589 0-1.067-.478-1.067-1.067V25.6Zm2.134 1.067v7.466h7.466v-7.466h-7.466Zm9.6 0v7.466h7.466v-7.466h-7.466Zm9.6 0v7.466h7.466v-7.466h-7.466Zm9.6 0v7.466h7.466v-7.466h-7.466Zm7.466 9.6h-7.466v7.466h7.466v-7.466Zm0 9.6h-7.466v7.466h7.466v-7.466Zm-9.6 7.466v-7.466h-7.466v7.466h7.466Zm-9.6 0v-7.466h-7.466v7.466h7.466Zm-9.6 0v-7.466h-7.466v7.466h7.466Zm-7.466-9.6h7.466v-7.466h-7.466v7.466Zm9.6-7.466v7.466h7.466v-7.466h-7.466Zm9.6 0v7.466h7.466v-7.466h-7.466Z"
      clipRule="evenodd"
    />
  </svg>
);
export { Calendar };
