import '~/styles/global.css';
import clsx from 'clsx';
import { Lexend } from 'next/font/google';
import Head from 'next/head';
import { useState } from 'react';

import type { Constants } from '@lib/queries';
import type { AppProps } from 'next/app';

import { Box } from '~/components/box';
import { AppContext } from '~/components/context';
import { Footer } from '~/components/footer';
import { Nav } from '~/components/nav';
import { pageMargin } from '~/styles/pageMargin.css';
import { defaultTheme, responsiveTheme } from '~/styles/theme.css';

export const lexend = Lexend({ subsets: ['latin'], variable: '--font-sans' });

export default function App({ Component, pageProps }: AppProps) {
  const [context, setContext] = useState<Omit<Constants, '_type'>>({
    appUrl: '',
    email: '',
  });

  return (
    <AppContext.Provider value={{ ...context, setContext }}>
      <Head>
        <title>Find Your Rhythm</title>
        <link rel="icon" href="/favicon/fyr@4x.png" sizes="any" />
        <link rel="apple-touch-icon" href="/favicon/fyr@16x.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <main className={clsx(defaultTheme, responsiveTheme, pageMargin)}>
        <Box display="flex" flexDirection="column" gap={{ sm: '5', lg: '2' }}>
          <Nav />
          <Component {...pageProps} />
        </Box>
        <Footer />
      </main>
    </AppContext.Provider>
  );
}
