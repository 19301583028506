import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Yoga = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <g fill={colors.ultramarine900} clipPath="url(#yoga_svg__a)">
      <path d="M59.733 19.2h-38.4v-8.204c0-5.791-4.374-10.612-9.958-10.973-2.983-.192-5.828.823-8.001 2.86A10.708 10.708 0 0 0 0 10.667v42.587c-.001.261.005.524.023.788C.384 59.626 5.203 64 10.995 64h48.739A4.271 4.271 0 0 0 64 59.734V23.467a4.271 4.271 0 0 0-4.267-4.267Zm-57.6-8.533a8.45 8.45 0 0 1 2.7-6.227 8.462 8.462 0 0 1 6.403-2.288c4.465.289 7.963 4.174 7.963 8.843V46.94a10.656 10.656 0 0 0-8.533-4.273 10.708 10.708 0 0 0-8.534 4.263V10.667h.001Zm59.734 49.067a2.135 2.135 0 0 1-2.133 2.133H10.995c-4.67 0-8.554-3.498-8.843-7.963a8.985 8.985 0 0 1-.02-.56v-.046A8.454 8.454 0 0 1 4.44 47.5a8.45 8.45 0 0 1 6.227-2.699c4.705 0 8.533 3.829 8.533 8.534a1.067 1.067 0 0 0 2.133 0V21.334h38.401c1.177 0 2.134.956 2.134 2.133v36.267Z" />
      <path d="M56.533 25.6a3.204 3.204 0 0 0-3.2 3.2v25.6c0 1.764 1.436 3.2 3.2 3.2 1.765 0 3.2-1.436 3.2-3.2V28.8c0-1.764-1.435-3.2-3.2-3.2ZM57.6 54.4c0 .588-.479 1.067-1.067 1.067a1.068 1.068 0 0 1-1.066-1.067V28.8c0-.588.479-1.066 1.066-1.066.588 0 1.067.478 1.067 1.066v25.6Z" />
    </g>
    <defs>
      <clipPath id="yoga_svg__a">
        <path fill={colors.white} d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
);
export { Yoga };
