import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Sync = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <g fill={colors.ultramarine900} fillRule="evenodd" clipPath="url(#sync_svg__a)" clipRule="evenodd">
      <path d="M47.15 6.253a29.918 29.918 0 0 0-30.3 0 1.067 1.067 0 0 1-1.08-1.84 32.051 32.051 0 0 1 32.461 0 1.067 1.067 0 1 1-1.08 1.84Z" />
      <path d="M12.8 4.267h38.4a7.472 7.472 0 0 1 7.467 7.465v37.644c0 .214-.064.422-.184.6a31.464 31.464 0 0 1-4.417 5.189 1.067 1.067 0 0 1-1.8-.776V18.136a1.073 1.073 0 0 0-1.068-1.07H12.802c-.589.003-1.066.48-1.069 1.07v36.253a1.067 1.067 0 0 1-1.799.776 31.46 31.46 0 0 1-4.416-5.19 1.067 1.067 0 0 1-.184-.599V11.733A7.473 7.473 0 0 1 12.8 4.267ZM51.2 6.4H12.8a5.338 5.338 0 0 0-5.333 5.334v37.31c.657.946 1.37 1.852 2.133 2.713V18.13a3.206 3.206 0 0 1 3.197-3.197h38.406A3.206 3.206 0 0 1 54.4 18.13v33.627a29.312 29.312 0 0 0 2.133-2.712v-37.31A5.338 5.338 0 0 0 51.2 6.4Z" />
      <path d="M6.998 13.741c.488.33.615.994.285 1.482a29.905 29.905 0 0 0 0 33.555 1.067 1.067 0 1 1-1.766 1.197 32.039 32.039 0 0 1 0-35.95c.33-.487.994-.614 1.481-.284ZM9.895 53.653a1.067 1.067 0 0 1 1.508-.035c11.529 10.998 29.665 10.998 41.194 0a1.067 1.067 0 0 1 1.473 1.543c-12.353 11.785-31.786 11.785-44.14 0a1.067 1.067 0 0 1-.035-1.508ZM57.002 13.741a1.067 1.067 0 0 1 1.481.285 32.039 32.039 0 0 1 0 35.948 1.067 1.067 0 1 1-1.766-1.196 29.905 29.905 0 0 0 0-33.555 1.067 1.067 0 0 1 .285-1.482ZM25.6 10.667c0-.59.478-1.067 1.067-1.067h10.666a1.067 1.067 0 0 1 0 2.133H26.667c-.59 0-1.067-.477-1.067-1.066Z" />
      <path d="M30.046 27.9a11.753 11.753 0 1 0 4.498.107l.458-2.084a13.886 13.886 0 0 1-3.315 27.444c-7.15-.171-13-5.744-13.517-12.877a13.886 13.886 0 0 1 11.517-14.694l.359 2.103Zm9.326 12.42a7.445 7.445 0 0 0-5.62-8.105l.507-2.072a9.579 9.579 0 1 1-4.657.024l.53 2.066a7.445 7.445 0 1 0 9.24 8.086Z" />
      <path d="M29.378 22.518a1.067 1.067 0 0 1 1.108.08l4.909 3.5 2.56 1.835a1.067 1.067 0 0 1-.002 1.735l-7.467 5.334a1.066 1.066 0 0 1-1.686-.869V31.2a1.067 1.067 0 0 1 2.133 0v.861L35.5 28.8l-1.346-.965-.002-.002-3.219-2.295v1.311a1.067 1.067 0 1 1-2.133 0v-3.381c0-.4.223-.766.578-.949Z" />
    </g>
    <defs>
      <clipPath id="sync_svg__a">
        <path fill={colors.white} d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
);
export { Sync };
