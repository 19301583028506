import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Switch = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={65} height={64} fill="none" {...props}>
    <g clipPath="url(#switch_svg__a)">
      <path
        fill={colors.ultramarine900}
        d="M10.892 35.2a4.267 4.267 0 0 0-.226 8.503 7.586 7.586 0 0 1 4.92 2.27c.47.566.64.803.782 1.008a32.44 32.44 0 0 0 3.03 3.472 20.574 20.574 0 0 0 3.387 2.98 8.569 8.569 0 0 1 2.853 4.566A3.19 3.19 0 0 0 27.15 64h17.067a3.19 3.19 0 0 0 2.178-5.53c.256-.433.533-.877.842-1.345 2.313-3.472 2.313-5.857 2.313-10.192v-16a4.266 4.266 0 0 0-6.655-3.534 4.22 4.22 0 0 0-6.759-1.836 4.199 4.199 0 0 0-5.786-1.503v-2.727h17.066c5.888-.008 10.659-4.779 10.667-10.666C58.077 4.778 53.305.007 47.417 0H26.082c-5.887.008-10.658 4.779-10.666 10.667a10.667 10.667 0 0 0 6.4 9.768L21.803 38.4c-3.533-2.257-6.454-3.5-10.911-3.2Zm33.325 26.667H27.15a1.067 1.067 0 0 1 0-2.134h17.067a1.067 1.067 0 0 1 0 2.134ZM32.483 25.6c1.178 0 2.134.955 2.134 2.133v5.334a1.067 1.067 0 0 0 2.133 0V28.8a2.133 2.133 0 0 1 4.267 0v5.333a1.067 1.067 0 0 0 2.133 0v-3.2a2.133 2.133 0 0 1 4.267 0v16c0 4.173 0 6.08-1.955 9.008-.395.593-.73 1.144-1.04 1.68-.069 0-.135-.021-.205-.021H27.744a10.804 10.804 0 0 0-3.702-5.893 18.7 18.7 0 0 1-3.085-2.713 32.264 32.264 0 0 1-2.845-3.242c-.164-.233-.353-.502-.878-1.137a9.664 9.664 0 0 0-6.325-3.029 2.133 2.133 0 0 1 .122-4.253c3.897-.267 6.392.803 9.632 2.87a2.152 2.152 0 0 0 2.18.06A2.126 2.126 0 0 0 23.94 38.4l.01-23.467a2.12 2.12 0 0 1 .667-1.552 2.183 2.183 0 0 1 1.574-.581 2.27 2.27 0 0 1 2.026 2.315V32a1.067 1.067 0 0 0 2.134 0v-4.267c0-1.178.955-2.133 2.133-2.133ZM21.817 10.667a4.267 4.267 0 0 1 8.533 0 4.201 4.201 0 0 1-.616 2.163 4.174 4.174 0 0 0-7.32-.03 4.254 4.254 0 0 1-.598-2.133Zm-4.267 0a8.547 8.547 0 0 1 8.533-8.534h21.334a8.543 8.543 0 0 1 8.533 8.534 8.547 8.547 0 0 1-8.534 8.533H30.35v-3.78a6.32 6.32 0 0 0 2.133-4.753 6.4 6.4 0 1 0-10.666 4.757v2.611a8.526 8.526 0 0 1-4.267-7.368Z"
      />
    </g>
    <defs>
      <clipPath id="switch_svg__a">
        <path fill={colors.white} d="M.467 0h64v64h-64z" />
      </clipPath>
    </defs>
  </svg>
);
export { Switch };
