import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const PeanutButter = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M11.084 2.716c-.824 0-1.493.669-1.493 1.493v6.521c0 .824.669 1.493 1.493 1.493h41.81c.825 0 1.492-.668 1.492-1.493V4.21c0-.825-.667-1.493-1.492-1.493h-41.81ZM6.875 4.21A4.21 4.21 0 0 1 11.084 0h41.81a4.208 4.208 0 0 1 4.209 4.209v6.521a4.208 4.208 0 0 1-4.21 4.209h-41.81a4.21 4.21 0 0 1-4.208-4.209V4.21Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M11.36 13.696c0-.75.608-1.358 1.358-1.358h38.564c.75 0 1.358.608 1.358 1.358v1.136c.186.533.523 1.054 1.028 1.682.223.278.46.555.722.86l.205.24c.337.393.7.826 1.06 1.301 1.484 1.962 2.828 4.559 2.828 8.804v19.866C58.483 56.65 51.133 64 42.069 64H21.93c-9.064 0-16.414-7.35-16.414-16.415V27.719c0-4.237 1.338-6.835 2.82-8.798.417-.55.909-1.126 1.336-1.627.248-.29.475-.555.652-.775.506-.627.845-1.146 1.035-1.675v-1.148Zm38.564 1.358H14.076v.005c0 .13-.019.26-.056.384-.326 1.105-.95 2-1.58 2.781-.294.364-.56.674-.82.976a25.8 25.8 0 0 0-1.115 1.358c-1.19 1.575-2.272 3.611-2.272 7.16v19.867c0 7.565 6.134 13.699 13.698 13.699h20.138c7.564 0 13.697-6.134 13.697-13.7V27.72c0-3.555-1.086-5.59-2.277-7.165a25.54 25.54 0 0 0-.96-1.177l-.188-.22a38.874 38.874 0 0 1-.79-.942c-.631-.785-1.254-1.685-1.574-2.799a1.36 1.36 0 0 1-.053-.362Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M18.575 18.448c.591.461.697 1.314.236 1.906-1.258 1.612-2.43 3.672-2.43 7.365v19.866a1.358 1.358 0 0 1-2.716 0V27.719c0-4.421 1.458-7.054 3.004-9.036a1.358 1.358 0 0 1 1.906-.235ZM32.679 27.162c0-.75.608-1.358 1.358-1.358h22.305c1.184 0 2.14.96 2.14 2.141v20.88a2.782 2.782 0 0 1-2.782 2.782H34.037c-.75 0-1.358-.608-1.358-1.358V27.162Zm2.716 1.358V48.89H55.7a.065.065 0 0 0 .066-.066V28.52H35.395Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M42.055 33.983c0-.75.608-1.358 1.358-1.358h12.93c1.184 0 2.14.963 2.14 2.142v7.192a2.781 2.781 0 0 1-2.783 2.782H43.413c-.75 0-1.358-.608-1.358-1.358v-9.4Zm2.716 1.359v6.683H55.7c.038 0 .066-.03.066-.066v-6.617H44.772Z"
      clipRule="evenodd"
    />
  </svg>
);
export { PeanutButter };
