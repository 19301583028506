import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Checklist = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <g fill={colors.ultramarine900} clipPath="url(#checklist_svg__a)">
      <path d="m16.313 12.046-1.18 1.18a2.118 2.118 0 0 0-1.266-.426H6.4a2.135 2.135 0 0 0-2.133 2.133V22.4c0 1.177.957 2.133 2.133 2.133h7.467A2.135 2.135 0 0 0 16 22.4v-7.025l1.821-1.82a1.065 1.065 0 1 0-1.508-1.51ZM13.868 22.4H6.4v-7.467h7.025l-2.758 2.759-.312-.313a1.066 1.066 0 1 0-1.509 1.508l1.067 1.067a1.064 1.064 0 0 0 1.508 0l2.446-2.446V22.4h.001ZM16.313 25.913l-1.18 1.18a2.117 2.117 0 0 0-1.266-.426H6.4A2.135 2.135 0 0 0 4.267 28.8v7.467c0 1.176.957 2.133 2.133 2.133h7.467A2.135 2.135 0 0 0 16 36.267v-7.025l1.821-1.821a1.065 1.065 0 1 0-1.508-1.508Zm-2.445 10.354H6.4V28.8h7.025l-2.758 2.758-.312-.312a1.065 1.065 0 1 0-1.509 1.508l1.067 1.067a1.064 1.064 0 0 0 1.508 0l2.446-2.446v4.892h.001ZM16.313 39.78l-1.18 1.179a2.117 2.117 0 0 0-1.266-.426H6.4a2.135 2.135 0 0 0-2.133 2.134v7.466c0 1.177.957 2.134 2.133 2.134h7.467A2.135 2.135 0 0 0 16 50.133v-7.025l1.821-1.82a1.065 1.065 0 1 0-1.508-1.509Zm-2.445 10.353H6.4v-7.466h7.025l-2.758 2.758-.312-.312a1.065 1.065 0 1 0-1.509 1.508l1.067 1.066a1.064 1.064 0 0 0 1.508 0l2.446-2.445v4.891h.001ZM20.267 17.067h19.2a1.067 1.067 0 0 0 0-2.134h-19.2a1.067 1.067 0 0 0 0 2.134ZM20.267 22.4h14.934a1.067 1.067 0 0 0 0-2.133H20.267a1.067 1.067 0 0 0 0 2.133ZM49.067 42.667c-4.705 0-8.533 3.828-8.533 8.533s3.828 8.533 8.533 8.533S57.6 55.905 57.6 51.2s-3.828-8.533-8.533-8.533Zm0 14.933c-3.53 0-6.4-2.87-6.4-6.4 0-3.53 2.87-6.4 6.4-6.4 3.53 0 6.4 2.87 6.4 6.4 0 3.53-2.87 6.4-6.4 6.4Z" />
      <path d="M56.474 25.74c-2.082-.093-4.574-.14-7.407-.14-1.528 0-2.948.015-4.267.042V9.6c0-.283-.111-.555-.312-.754L35.955.313C35.755.112 35.483 0 35.2 0H2.134A2.135 2.135 0 0 0 0 2.133v53.334C0 56.643.957 57.6 2.134 57.6h33.439c.954 2.01 2.36 3.81 4.154 5.25A5.319 5.319 0 0 0 43.051 64h12.032a5.35 5.35 0 0 0 3.302-1.13c4.103-3.29 6.155-8.386 5.49-13.628a14.475 14.475 0 0 0-1.995-5.717C62.69 41.778 64 38.195 64 33.3c0-1.976-.783-3.84-2.204-5.248a8.015 8.015 0 0 0-5.322-2.312ZM36.267 3.642l4.892 4.891h-3.825a1.068 1.068 0 0 1-1.067-1.066V3.642ZM2.134 55.467V2.133h32v5.334c0 1.764 1.436 3.2 3.2 3.2h5.333v15.035c-.339.012-.687.024-1.007.038-2.465.109-4.612 1.29-5.976 3.06H20.267a1.067 1.067 0 0 0 0 2.133h14.266a7.3 7.3 0 0 0-.4 2.368c0 .286.015.556.023.832H20.267a1.067 1.067 0 0 0 0 2.134h14.034c.29 2.579.907 4.64 1.449 6.041.047.125.094.24.14.359H20.266a1.067 1.067 0 0 0 0 2.133h15.316a14.986 14.986 0 0 0-1.097 3.2H20.266a1.067 1.067 0 0 0 0 2.133h13.906c-.025.354-.04.71-.04 1.067 0 1.469.234 2.898.642 4.267H2.134Zm57.884-10.9a12.37 12.37 0 0 1 1.74 4.943c.572 4.502-1.188 8.873-4.7 11.689a3.204 3.204 0 0 1-1.975.668H43.051a3.182 3.182 0 0 1-1.988-.68 12.751 12.751 0 0 1-4.796-9.987c0-2.344.644-4.644 1.864-6.653.362-.6.391-1.315.074-1.911a9.724 9.724 0 0 1-.463-1.09c-.673-1.741-1.475-4.585-1.475-8.245 0-2.91 2.41-5.295 5.488-5.43 2.05-.09 4.511-.138 7.312-.138s5.262.046 7.315.138a5.89 5.89 0 0 1 3.912 1.697 5.226 5.226 0 0 1 1.572 3.733c0 4.56-1.225 7.847-1.956 9.4-.282.601-.241 1.299.108 1.867Z" />
      <path d="M56.54 30.017c-2.18-.1-4.693-.15-7.473-.15-2.78 0-5.294.05-7.46.15a3.333 3.333 0 0 0-3.207 3.327c0 2.071.248 4.084.735 5.98a1.947 1.947 0 0 0 1.886 1.458c.377 0 .75-.108 1.073-.317 2.519-1.637 5.457-2.32 8.495-1.975h.001c1.921.215 3.805.897 5.448 1.97a1.96 1.96 0 0 0 1.78.186 1.944 1.944 0 0 0 1.179-1.329c.488-1.909.736-3.919.736-5.973 0-1.113-.505-1.87-.942-2.322-.621-.621-1.4-.969-2.25-1.005Zm-15.4 8.526a22.037 22.037 0 0 1-.605-5.2c0-.642.512-1.167 1.169-1.196.31-.014.64-.025.964-.037v5.606c-.52.248-1.032.515-1.528.827Zm15.855-.005a14.704 14.704 0 0 0-6.166-2.169 15.095 15.095 0 0 0-6.028.523v-4.845c1.336-.03 2.756-.047 4.267-.047 2.748 0 5.229.05 7.378.148.321.014.596.139.825.368.22.227.33.506.33.827 0 1.787-.203 3.533-.606 5.195Z" />
    </g>
    <defs>
      <clipPath id="checklist_svg__a">
        <path fill={colors.white} d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
);
export { Checklist };
