import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const QuestionPad = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      d="M6.4 58.667A5.34 5.34 0 0 0 11.733 64h40.534a5.34 5.34 0 0 0 5.333-5.333V9.6a5.34 5.34 0 0 0-5.333-5.333h-3.2v-3.2a1.067 1.067 0 0 0-2.134 0v3.2H38.4v-3.2a1.067 1.067 0 0 0-2.133 0v3.2h-8.534v-3.2a1.067 1.067 0 1 0-2.133 0v3.2h-8.533v-3.2a1.067 1.067 0 0 0-2.134 0v3.2h-3.2A5.34 5.34 0 0 0 6.4 9.6v49.067ZM8.533 9.6a3.2 3.2 0 0 1 3.2-3.2h3.2v1.263a3.2 3.2 0 1 0 2.134 0V6.4H25.6v1.263a3.2 3.2 0 1 0 2.133 0V6.4h8.534v1.263a3.2 3.2 0 1 0 2.133 0V6.4h8.533v1.263a3.2 3.2 0 1 0 2.134 0V6.4h3.2a3.2 3.2 0 0 1 3.2 3.2V16a1.067 1.067 0 0 0 0 2.133v40.534a3.2 3.2 0 0 1-3.2 3.2H11.733a3.2 3.2 0 0 1-3.2-3.2V18.133a1.067 1.067 0 1 0 0-2.133V9.6ZM16 9.6a1.067 1.067 0 1 1 0 2.133A1.067 1.067 0 0 1 16 9.6Zm10.667 0a1.067 1.067 0 1 1 0 2.133 1.067 1.067 0 0 1 0-2.133Zm10.666 0a1.067 1.067 0 1 1 0 2.133 1.067 1.067 0 0 1 0-2.133ZM48 9.6a1.067 1.067 0 1 1 0 2.133A1.067 1.067 0 0 1 48 9.6Z"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M12.8 18.133h1.067a1.067 1.067 0 1 0 0-2.133H12.8a1.067 1.067 0 0 0 0 2.133ZM18.133 18.133H19.2a1.067 1.067 0 1 0 0-2.133h-1.067a1.067 1.067 0 0 0 0 2.133ZM23.467 18.133h1.066a1.067 1.067 0 1 0 0-2.133h-1.066a1.067 1.067 0 0 0 0 2.133ZM28.8 18.133h1.066a1.067 1.067 0 1 0 0-2.133H28.8a1.067 1.067 0 0 0 0 2.133ZM34.133 18.133H35.2a1.067 1.067 0 1 0 0-2.133h-1.067a1.067 1.067 0 0 0 0 2.133ZM39.467 18.133h1.066a1.067 1.067 0 1 0 0-2.133h-1.066a1.067 1.067 0 0 0 0 2.133ZM44.8 18.133h1.067a1.067 1.067 0 1 0 0-2.133H44.8a1.067 1.067 0 0 0 0 2.133ZM50.133 18.133H51.2a1.067 1.067 0 1 0 0-2.133h-1.067a1.067 1.067 0 0 0 0 2.133Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      d="M20.267 33.066a3.2 3.2 0 1 0 6.4 0 5.343 5.343 0 0 1 6.435-5.226 5.26 5.26 0 0 1 4.125 4.122 5.326 5.326 0 0 1-4.16 6.331 5.343 5.343 0 0 0-4.267 5.22v3.42a3.2 3.2 0 0 0 6.4 0l-.008-2.57a11.733 11.733 0 1 0-10.652-20.35 11.697 11.697 0 0 0-4.273 9.053Zm5.63-7.41a9.663 9.663 0 0 1 8.023-2.006 9.562 9.562 0 0 1 7.499 7.499 9.667 9.667 0 0 1-7.502 11.322 1.067 1.067 0 0 0-.854 1.05l.004 3.412a1.067 1.067 0 0 1-2.134 0V43.51a3.2 3.2 0 0 1 2.56-3.127 7.467 7.467 0 0 0 .02-14.64 8.08 8.08 0 0 0-1.528-.142 7.36 7.36 0 0 0-4.732 1.706 7.448 7.448 0 0 0-2.72 5.76 1.067 1.067 0 1 1-2.133 0 9.576 9.576 0 0 1 3.497-7.413v.003ZM32 52.267a3.2 3.2 0 1 0 0 6.4 3.2 3.2 0 0 0 0-6.4Zm0 4.266a1.067 1.067 0 1 1 0-2.133 1.067 1.067 0 0 1 0 2.133Z"
    />
  </svg>
);
export { QuestionPad };
