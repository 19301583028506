import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Oil = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M27.743 5.333h8.533V4.267a2.136 2.136 0 0 0-2.133-2.134h-4.267a2.135 2.135 0 0 0-2.133 2.134v1.066Zm9.6 2.133H26.676A1.067 1.067 0 0 1 25.61 6.4V4.266A4.271 4.271 0 0 1 29.876 0h4.267a4.27 4.27 0 0 1 4.266 4.266V6.4c0 .59-.477 1.066-1.066 1.066Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M28.809 7.466v7.52a29.036 29.036 0 0 1-4.877 16.133l-3.919 5.892c-3.481 5.376-1.71 13.157 1.592 18.478.282.452.561.918.841 1.383 1.34 2.225 3.005 4.994 4.23 4.994h10.648c1.225 0 2.891-2.769 4.23-4.993.28-.465.56-.932.842-1.384 3.302-5.32 5.072-13.097 1.59-18.482l-3.917-5.888a29.028 29.028 0 0 1-4.878-16.134v-7.52l-6.382.001ZM37.324 64H26.676c-2.431 0-4.192-2.928-6.058-6.027-.275-.457-.55-.915-.826-1.36-3.657-5.892-5.559-14.6-1.563-20.77l3.927-5.906a26.91 26.91 0 0 0 4.52-14.95V6.4c0-.59.478-1.067 1.066-1.067h8.515c.59 0 1.067.477 1.067 1.067v8.585a26.906 26.906 0 0 0 4.52 14.951l3.924 5.899.004.007c3.995 6.17 2.094 14.878-1.564 20.77-.276.445-.552.904-.826 1.361-1.866 3.1-3.627 6.026-6.058 6.026Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M32.009 40.534a3.204 3.204 0 0 0-3.2 3.2c0 1.764 1.435 3.2 3.2 3.2 1.764 0 3.2-1.436 3.2-3.2 0-1.765-1.436-3.2-3.2-3.2Zm0 8.533a5.34 5.34 0 0 1-5.333-5.333 5.34 5.34 0 0 1 5.333-5.334 5.34 5.34 0 0 1 5.333 5.334 5.34 5.34 0 0 1-5.333 5.333ZM45.22 53.334H18.78a1.066 1.066 0 1 1 0-2.134h26.44a1.066 1.066 0 1 1 0 2.134Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M45.258 39.466c-3.3 0-6.79-.875-10.376-2.601-1.598-.771-4.15-.77-5.745-.001-3.588 1.727-7.079 2.602-10.378 2.602a1.066 1.066 0 1 1 0-2.133c3.017 0 6.11-.782 9.452-2.391 2.192-1.056 5.402-1.057 7.596 0 3.341 1.61 6.433 2.391 9.45 2.391a1.067 1.067 0 1 1 0 2.133"
      clipRule="evenodd"
    />
  </svg>
);
export { Oil };
