import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Breakfast = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <g fill={colors.ultramarine900} fillRule="evenodd" clipPath="url(#breakfast_svg__a)" clipRule="evenodd">
      <path d="M32 2.133C15.505 2.133 2.133 15.505 2.133 32S15.505 61.867 32 61.867 61.867 48.495 61.867 32 48.495 2.133 32 2.133ZM0 32C0 14.327 14.327 0 32 0c17.673 0 32 14.327 32 32 0 17.673-14.327 32-32 32C14.327 64 0 49.673 0 32Z" />
      <path d="M29.473 6.523c.035-.004.07-.006.106-.006h.01a1.067 1.067 0 0 1 .059 2.132C17.66 9.86 8.535 19.95 8.533 32v.002a23.187 23.187 0 0 0 2.05 9.565c.062.138.094.287.094.438v.011a1.067 1.067 0 0 1-2.076.344A25.321 25.321 0 0 1 6.4 32c.002-13.16 9.978-24.176 23.073-25.477ZM52.084 46.108c.467.359.556 1.029.197 1.496-7.187 9.368-19.856 12.587-30.64 7.796a1.067 1.067 0 0 1 .343-2.077h.01c.151 0 .3.032.437.093a23.412 23.412 0 0 0 28.158-7.11 1.067 1.067 0 0 1 1.495-.198ZM54.288 19.441a1.067 1.067 0 0 0-2.043.43v.012c0 .185.049.368.14.529a23.37 23.37 0 0 1 .44 22.391 1.067 1.067 0 1 0 1.892.986 25.503 25.503 0 0 0-.429-24.348Z" />
      <path d="M17.113 33.31a2.133 2.133 0 0 0-3.016 0A11.733 11.733 0 0 0 30.69 49.903a2.133 2.133 0 1 0-3.017-3.018 7.467 7.467 0 0 1-10.56-10.558M12.59 31.8a4.267 4.267 0 0 1 6.034 6.034 5.334 5.334 0 0 0 7.542 7.544m6.034 6.034A13.866 13.866 0 0 1 12.589 31.8M32.2 51.41a4.267 4.267 0 1 0-6.034-6.034" />
      <path d="M17.868 28.471c.59 0 1.067.478 1.067 1.067v3.017a1.067 1.067 0 0 1-2.133 0v-3.017c0-.59.477-1.067 1.066-1.067Z" />
      <path d="M16.802 32.555c0-.59.477-1.067 1.066-1.067h3.017a1.067 1.067 0 1 1 0 2.134h-3.017c-.589 0-1.066-.478-1.066-1.067ZM31.445 42.048c.59 0 1.067.477 1.067 1.066v3.017a1.067 1.067 0 0 1-2.134 0v-3.017c0-.589.478-1.066 1.067-1.066Z" />
      <path d="M30.378 46.132c0-.59.478-1.067 1.067-1.067h3.017a1.067 1.067 0 0 1 0 2.133h-3.017c-.589 0-1.067-.477-1.067-1.066ZM35.2 7.467c-7.07 0-12.8 5.73-12.8 12.8 0 7.069 5.73 12.8 12.8 12.8 3.578 0 7.9-1.465 11.326-3.868 3.445-2.418 5.74-5.604 5.74-8.932 0-3.329-2.295-6.514-5.74-8.932C43.1 8.93 38.778 7.467 35.2 7.467Zm-14.933 12.8c0-8.248 6.686-14.934 14.933-14.934 4.08 0 8.824 1.64 12.551 4.255 3.707 2.601 6.649 6.349 6.649 10.679s-2.942 8.077-6.649 10.678C44.024 33.56 39.281 35.2 35.2 35.2c-8.248 0-14.933-6.686-14.933-14.933Z" />
      <path d="M34.133 13.867a5.333 5.333 0 1 0 0 10.666 5.333 5.333 0 0 0 0-10.666ZM26.666 19.2a7.467 7.467 0 1 1 14.934 0 7.467 7.467 0 0 1-14.934 0Z" />
      <path d="M35.2 17.065c0 .59-.477 1.068-1.066 1.068a1.07 1.07 0 0 0-1.068 1.068 1.067 1.067 0 1 1-2.133-.002A3.202 3.202 0 0 1 34.132 16c.589 0 1.067.476 1.068 1.065ZM38.771 32.956a1.067 1.067 0 0 1 1.468-.35c.155.095.299.21.426.342l1.508 1.498a3.201 3.201 0 1 1-4.527 4.527l-.003-.003-1.499-1.51a3.174 3.174 0 0 1-.444-3.978 1.067 1.067 0 1 1 1.795 1.153 1.04 1.04 0 0 0 .147 1.307l.011.01 1.503 1.513a1.068 1.068 0 0 0 1.509-1.51l-1.512-1.502a2.139 2.139 0 0 1-.031-.03 1.067 1.067 0 0 1-.35-1.467ZM48.937 36.396a1.067 1.067 0 0 0 1.509 0l1.508-1.508a1.067 1.067 0 0 0-1.508-1.509l-1.509 1.509a1.067 1.067 0 0 0 0 1.508Zm3.017 1.509a3.2 3.2 0 1 1-4.525-4.526l1.508-1.508a3.2 3.2 0 1 1 4.526 4.525l-1.509 1.509ZM50.446 42.98a1.067 1.067 0 0 0 0 1.508l1.508 1.508a1.067 1.067 0 0 0 1.508-1.508l-1.508-1.509a1.066 1.066 0 0 0-1.508 0Zm-1.509 3.016a3.2 3.2 0 0 1 4.526-4.525l1.508 1.508a3.2 3.2 0 0 1-4.525 4.526l-1.509-1.509ZM40.404 47.063a1.067 1.067 0 0 0 1.508 0l1.509-1.509a1.067 1.067 0 1 0-1.508-1.508l-1.509 1.508a1.067 1.067 0 0 0 0 1.509Zm3.017 1.508a3.2 3.2 0 1 1-4.526-4.525l1.509-1.509a3.2 3.2 0 1 1 4.525 4.526l-1.508 1.508Z" />
    </g>
    <defs>
      <clipPath id="breakfast_svg__a">
        <path fill={colors.white} d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
);
export { Breakfast };
