import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Pear = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M32.488 11.733c-2.936.018-4.886 1.488-6.329 4.763-.718 1.634-1.088 3.485-1.096 5.503-.027 6.308-.387 8.698-4.577 12.887-5.445 5.445-5.03 11.899-5.024 11.963.003 9.987 5.74 15.01 17.055 15.015 11.328-.005 17.066-5.029 17.066-14.932.008-.149.427-6.6-5.021-12.046-4.188-4.188-4.548-6.579-4.576-12.887-.01-2.018-.379-3.87-1.098-5.503-1.447-3.289-3.409-4.757-6.36-4.762h-.04m.043 52.265c-12.565-.006-19.2-5.907-19.2-17.066-.021-.217-.522-7.387 5.646-13.554 3.559-3.56 3.927-5.336 3.952-11.389.01-2.312.44-4.45 1.278-6.355.655-1.486 2.643-6.004 8.267-6.034h.05c5.664.008 7.66 4.545 8.316 6.034.839 1.903 1.27 4.042 1.28 6.354.026 6.055.393 7.831 3.95 11.39 6.17 6.169 5.667 13.338 5.642 13.64.004 11.073-6.63 16.975-19.181 16.981"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M26.137 58.97c-.097 0-.194-.013-.29-.04-7.393-2.087-8.249-7.848-8.249-11.998a1.066 1.066 0 1 1 2.134 0c0 3.983.794 8.28 6.694 9.945a1.066 1.066 0 0 1-.29 2.093ZM32.532 11.733c-.59 0-1.066-.476-1.066-1.067v-9.6a1.066 1.066 0 1 1 2.133 0v9.6c0 .59-.477 1.067-1.067 1.067Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M26.133 5.333a1.066 1.066 0 0 1-.593-1.954c.142-.095 3.508-2.313 6.992-2.313a1.066 1.066 0 1 1 0 2.134c-2.844 0-5.78 1.933-5.808 1.954a1.07 1.07 0 0 1-.591.179"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M22.214 5.333c-2.468 0-4.723 1.303-5.89 3.4l-1.75 3.154c-.42.754.034 1.34.183 1.504.341.373.962.658 1.68.336l3.897-1.744c1.765-.789 3.083-2.277 3.615-4.083l.758-2.567h-2.493Zm-6.409 10.669a3.54 3.54 0 0 1-2.621-1.17c-1.017-1.108-1.203-2.67-.475-3.98l1.751-3.154C16.001 4.922 18.972 3.2 22.214 3.2h3.919a1.066 1.066 0 0 1 1.023 1.368l-1.16 3.937c-.709 2.403-2.455 4.38-4.79 5.427l-3.899 1.743c-.49.218-1 .327-1.502.327Z"
      clipRule="evenodd"
    />
  </svg>
);
export { Pear };
