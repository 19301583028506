import * as Popover from '@radix-ui/react-popover';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import * as styles from './index.css';
import { MobileNav } from './mobile-nav';

import { Box } from '~/components/box';
import { Button } from '~/components/button';
import { Wordmark } from '~/icons';

const Menu = ({ container }: { container: HTMLElement | null }) => {
  return (
    <>
      <Box as="nav" display={{ md: 'none' }}>
        <MobileNav container={container} />
      </Box>
      <Box as="nav" display={{ sm: 'none', md: 'flex' }} flexDirection="row" alignItems="center" gap="2">
        <Button href="/about" variant="text">
          About Us
        </Button>
        <Button href="/blog" variant="text">
          Blog
        </Button>
        <Button href="/faq" variant="text">
          FAQ
        </Button>
        <Button href="/download" forceButton variant="small">
          Try FYR Now
        </Button>
      </Box>
    </>
  );
};

export const Nav = () => {
  const container = useRef(null);
  const [open, setOpen] = useState(false);

  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = () => {
      setOpen(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Box as="header" ref={container}>
        <Popover.Anchor asChild>
          <Box className={styles.nav}>
            <Link href="/">
              <Box height="2">
                <Wordmark color="ultramarineLogo" />
              </Box>
            </Link>
            <Menu container={container?.current} />
          </Box>
        </Popover.Anchor>
      </Box>
    </Popover.Root>
  );
};
