import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const MenuAlt3 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      stroke={colors.ultramarine900}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13 18h7M4 6h16H4Zm0 6h16H4Z"
    />
  </svg>
);
export { MenuAlt3 };
