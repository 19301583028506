import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Trophy = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      d="M39.467 51.2H24.534a2.135 2.135 0 0 0-2.134 2.133V57.6c0 1.176.957 2.133 2.134 2.133h14.933A2.135 2.135 0 0 0 41.6 57.6v-4.267a2.135 2.135 0 0 0-2.133-2.133Zm-14.933 6.4v-4.267h14.933V57.6H24.534Z"
    />
    <path
      fill={colors.ultramarine900}
      d="M13.867 26.667h.803c1.601 5.191 5.476 9.389 10.457 11.438-1.189 3.778-4.245 5.076-4.883 5.308a3.169 3.169 0 0 0-2.11 3.008v.664c-1.835.477-3.2 2.133-3.2 4.115v10.667c0 1.176.956 2.133 2.133 2.133h29.867a2.135 2.135 0 0 0 2.133-2.133V51.2c0-1.982-1.365-3.638-3.2-4.115v-.664a3.169 3.169 0 0 0-2.109-3.007c-.64-.233-3.695-1.531-4.884-5.31 4.98-2.048 8.855-6.246 10.456-11.437h.803C56.015 26.667 60.8 21.882 60.8 16V7.467c0-1.765-1.435-3.2-3.2-3.2h-4.463c.12-.335.197-.692.197-1.067 0-1.764-1.436-3.2-3.2-3.2H13.866a3.204 3.204 0 0 0-3.2 3.2c0 .375.077.732.196 1.067H6.4a3.204 3.204 0 0 0-3.2 3.2V16c0 5.882 4.785 10.667 10.667 10.667ZM9.6 16v-5.333h4.267v9.6A4.271 4.271 0 0 1 9.6 16Zm37.334 35.2v10.667H17.067V51.2c0-1.177.957-2.133 2.133-2.133h25.6c1.177 0 2.134.956 2.134 2.133Zm-3.905-5.781c.434.158.704.542.704 1.002v.512H20.267v-.512c0-.46.27-.844.707-1.002 2.296-.838 4.989-3.013 6.154-6.636 1.553.434 3.183.684 4.872.684 1.69 0 3.32-.25 4.873-.684 1.165 3.623 3.858 5.797 6.156 6.636ZM48 21.333c0 8.823-7.177 16-16 16-8.822 0-16-7.177-16-16V6.4h32v14.933Zm2.133-10.666H54.4V16a4.271 4.271 0 0 1-4.267 4.267v-9.6Zm8.534-3.2V16c0 4.705-3.828 8.533-8.534 8.533h-.3c.126-.697.205-1.41.247-2.133h.055c3.53 0 6.4-2.87 6.4-6.4v-5.333A2.135 2.135 0 0 0 54.4 8.533h-4.266V6.4H57.6c.588 0 1.066.479 1.066 1.067ZM12.8 3.2c0-.588.48-1.067 1.067-1.067h36.266c.588 0 1.067.48 1.067 1.067 0 .588-.479 1.067-1.067 1.067H13.867A1.068 1.068 0 0 1 12.8 3.2ZM5.334 7.467c0-.588.478-1.067 1.066-1.067h7.467v2.133H9.6a2.135 2.135 0 0 0-2.133 2.134V16c0 3.53 2.87 6.4 6.4 6.4h.054c.043.723.122 1.436.247 2.133h-.3c-4.705 0-8.533-3.828-8.533-8.533l-.001-8.533Z"
    />
    <path
      fill={colors.ultramarine900}
      d="M32 33.067c6.47 0 11.734-5.264 11.734-11.734C43.734 14.864 38.47 9.6 32 9.6c-6.469 0-11.733 5.264-11.733 11.733 0 6.47 5.264 11.734 11.733 11.734Zm0-21.334c5.293 0 9.6 4.308 9.6 9.6 0 5.293-4.307 9.6-9.6 9.6-5.292 0-9.6-4.307-9.6-9.6 0-5.292 4.308-9.6 9.6-9.6Z"
    />
    <path
      fill={colors.ultramarine900}
      d="m27.643 23.24-.342 2.083a2.068 2.068 0 0 0 .842 2.04c.348.245.75.37 1.154.37.329 0 .659-.083.96-.248l1.744-.956 1.745.956c.674.369 1.485.323 2.112-.123.651-.462.974-1.242.843-2.04l-.342-2.081 1.444-1.467a2.08 2.08 0 0 0 .489-2.13 2.026 2.026 0 0 0-1.62-1.377l-1.963-.298-.886-1.869a2.01 2.01 0 0 0-3.648 0l-.885 1.87-1.962.297a2.027 2.027 0 0 0-1.62 1.378 2.08 2.08 0 0 0 .489 2.129l1.444 1.468.002-.002Zm2.53-3.246c.351-.054.652-.278.805-.599L32 17.233l1.025 2.162c.152.32.453.545.804.599l2.377.36-1.749 1.777a1.068 1.068 0 0 0-.292.922l.448 2.476-2.1-1.15a1.066 1.066 0 0 0-1.025 0l-2.055 1.125.403-2.451a1.066 1.066 0 0 0-.292-.922l-1.75-1.777 2.378-.36Z"
    />
  </svg>
);
export { Trophy };
