import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';

const Battery3 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={61} height={60} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      d="M15.306 58.506a5 5 0 0 0 7.07 0l37.042-37.04a5 5 0 0 0 0-7.072l-2.15-2.152-7.835-7.836 2.14-2.14a1.016 1.016 0 0 1 1.432 0l5.695 5.7a1.015 1.015 0 0 1 0 1.432 1 1 0 1 0 1.414 1.414 3.014 3.014 0 0 0 0-4.26L54.42.852a3.086 3.086 0 0 0-4.26 0l-2.14 2.14-1.447-1.446a5 5 0 0 0-7.07 0L2.458 38.59a5.005 5.005 0 0 0 0 7.07l12.847 12.847ZM3.874 40.003 40.916 2.965a3 3 0 0 1 4.242 0L58 15.807a3 3 0 0 1 0 4.242L20.962 57.091a3 3 0 0 1-4.242 0L3.874 44.245a3 3 0 0 1 0-4.242Z"
    />
    <path
      fill={colors.ultramarine900}
      d="M17.427 54.934a2 2 0 0 0 2.829 0l5.7-5.7a2 2 0 0 0 0-2.828l-11.4-11.4a2.005 2.005 0 0 0-2.83 0l-5.7 5.7a2 2 0 0 0 0 2.828l11.401 11.4ZM13.15 36.422l11.4 11.4-5.7 5.7-11.4-11.4 5.7-5.7ZM27.39 44.971a2 2 0 0 0 2.828 0l5.7-5.7a2 2 0 0 0 0-2.828l-11.4-11.4a2 2 0 0 0-2.828 0l-5.7 5.7a2 2 0 0 0 0 2.828l11.4 11.4ZM23.111 26.46l11.4 11.4-5.7 5.7-11.4-11.4 5.7-5.7ZM37.354 35.007a2 2 0 0 0 2.828 0l5.7-5.7a2 2 0 0 0 0-2.828l-11.4-11.395a2 2 0 0 0-2.828 0l-5.7 5.7a2 2 0 0 0 0 2.828l11.4 11.395Zm-4.28-18.51 11.4 11.4-5.7 5.7-11.4-11.4 5.7-5.7Z"
    />
  </svg>
);
export { Battery3 };
