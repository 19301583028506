import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const ZoomIn = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={65} height={64} fill="none" {...props}>
    <g fill={colors.ultramarine900} clipPath="url(#zoom-in_svg__a)">
      <path d="M13.838 39.437c1.592.19 3.128.709 4.51 1.523a5.924 5.924 0 0 1 2.825 3.2 18.787 18.787 0 0 0 3.541 5.227 20.432 20.432 0 0 0 3.388 2.986c1.242.96 2.185 2.92 2.755 5.687a3.19 3.19 0 0 0 1.61 5.94h17.066a3.19 3.19 0 0 0 1.576-5.968c.22-1.447.711-2.84 1.445-4.107 2.313-3.472 2.313-4.821 2.313-12.325v-16a4.266 4.266 0 0 0-6.655-3.534 4.212 4.212 0 0 0-6.145-2.273V4.267a4.267 4.267 0 1 0-8.534 0v1.658a4.223 4.223 0 0 0-2.133-.592A4.267 4.267 0 0 0 27.134 9.6v24.533a23.004 23.004 0 0 0-13.059-3.2 4.267 4.267 0 0 0-.237 8.502v.002Zm35.695 22.43H32.468a1.067 1.067 0 0 1 0-2.134h17.066a1.067 1.067 0 0 1 0 2.134Zm-37.32-26.92a2.151 2.151 0 0 1 2.001-1.88c4.12-.239 8.217.757 11.768 2.86a2.123 2.123 0 0 0 3.274-1.794l.01-24.533a2.133 2.133 0 1 1 4.267 0v18.133a1.067 1.067 0 1 0 2.134 0V4.267a2.133 2.133 0 1 1 4.267 0V28.8a1.067 1.067 0 0 0 2.133 0v-5.333a2.133 2.133 0 1 1 4.267 0v6.4a1.067 1.067 0 0 0 2.133 0V25.6a2.133 2.133 0 0 1 4.267 0v16c0 7.434 0 8.213-1.955 11.141a13.573 13.573 0 0 0-1.756 4.859H32.94c-.684-3.307-1.872-5.642-3.582-6.96a18.723 18.723 0 0 1-3.084-2.712 16.708 16.708 0 0 1-3.178-4.694 7.969 7.969 0 0 0-3.673-4.115 13.667 13.667 0 0 0-5.334-1.802 2.145 2.145 0 0 1-1.876-2.37Z" />
      <path d="M14.646 28.487a1.067 1.067 0 0 0 1.508 0l3.2-3.2a1.067 1.067 0 0 0-1.508-1.508l-1.38 1.38V10.666a1.067 1.067 0 0 0-2.133 0v14.491l-1.379-1.379a1.067 1.067 0 0 0-1.508 1.508l3.2 3.2ZM19.354 5.02l1.38-1.378v14.491a1.067 1.067 0 1 0 2.133 0V3.642l1.379 1.379a1.067 1.067 0 0 0 1.508-1.508l-3.2-3.2a1.067 1.067 0 0 0-1.508 0l-3.2 3.2a1.067 1.067 0 0 0 1.508 1.508Z" />
    </g>
    <defs>
      <clipPath id="zoom-in_svg__a">
        <path fill={colors.white} d="M10.067 0h44.8v64h-44.8z" />
      </clipPath>
    </defs>
  </svg>
);
export { ZoomIn };
