import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Scales = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M32 2.133c-5.89 0-10.666 4.776-10.666 10.667S26.108 23.467 32 23.467 42.667 18.69 42.667 12.8 37.89 2.133 32 2.133ZM19.2 12.8C19.2 5.73 24.931 0 32 0c7.07 0 12.8 5.73 12.8 12.8S39.07 25.6 32 25.6c-7.069 0-12.8-5.73-12.8-12.8Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M32 11.733a1.067 1.067 0 1 0 0 2.134 1.067 1.067 0 0 0 0-2.134ZM28.8 12.8a3.2 3.2 0 1 1 6.4 0 3.2 3.2 0 0 1-6.4 0Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M38.088 6.712a1.067 1.067 0 0 1 0 1.509l-3.83 3.83a1.067 1.067 0 1 1-1.508-1.51l3.83-3.829a1.067 1.067 0 0 1 1.508 0ZM15.253 13.867a8.533 8.533 0 0 0-8.467 9.614l3.947 30.932a8.545 8.545 0 0 0 8.468 7.454h25.598a8.544 8.544 0 0 0 8.468-7.454l3.947-30.929c.045-.365.067-.734.066-1.102a1.067 1.067 0 0 1 2.133-.007c.002.459-.026.918-.082 1.373l-3.948 30.937A10.677 10.677 0 0 1 44.801 64H19.2a10.678 10.678 0 0 1-10.583-9.315L4.67 23.751a10.667 10.667 0 0 1 10.584-12.018 1.067 1.067 0 0 1 0 2.134ZM47.684 12.717a1.067 1.067 0 0 1 1.146-.98l1.504.117.044.004c.188.023.375.058.56.105.146.029.335.067.52.12l.077.021c.23.065.517.144.79.245.183.062.36.133.508.192l.011.005c.028.01.055.023.081.036l.013.006c.164.063.325.136.48.219.157.073.308.155.431.221l.024.013.01.006c.087.048.208.115.334.198l.487.3c.02.012.038.025.057.038.266.189.533.386.795.6a1.067 1.067 0 0 1-1.351 1.65c-.2-.163-.415-.323-.65-.49l-.472-.291a2.006 2.006 0 0 0-.933-.48 1.065 1.065 0 0 1-.115-.05l-.024-.012a7.063 7.063 0 0 0-.417-.156 7.94 7.94 0 0 0-.64-.197l-.073-.02-.006-.002a5.108 5.108 0 0 0-.385-.086 1.099 1.099 0 0 1-.063-.015 2.26 2.26 0 0 0-.283-.055l-1.48-.115a1.067 1.067 0 0 1-.98-1.147Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M31.999 2.134c-4.526 0-8.56 2.854-10.065 7.122l-.006.016a7.04 7.04 0 0 1-6.68 4.595 8.533 8.533 0 0 0-8.462 9.614l3.947 30.932a8.545 8.545 0 0 0 8.468 7.454h25.598a8.544 8.544 0 0 0 8.468-7.454l3.947-30.932a8.533 8.533 0 0 0-8.46-9.614 7.04 7.04 0 0 1-6.684-4.595l-.006-.016a10.673 10.673 0 0 0-10.065-7.122ZM19.925 8.538a12.806 12.806 0 0 1 24.148 0 4.907 4.907 0 0 0 4.66 3.195h.013A10.667 10.667 0 0 1 59.33 23.751l-3.947 30.934A10.677 10.677 0 0 1 44.801 64H19.2a10.678 10.678 0 0 1-10.583-9.315L4.67 23.751a10.667 10.667 0 0 1 10.584-12.018h.012a4.907 4.907 0 0 0 4.66-3.195Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M17.067 28.8h29.866a6.4 6.4 0 0 1 6.362 7.107L51.28 54.04a6.4 6.4 0 0 1-6.362 5.693H19.081a6.4 6.4 0 0 1-6.36-5.693l-2.015-18.133a6.4 6.4 0 0 1 6.36-7.107Zm33.046 3.554a4.267 4.267 0 0 0-3.18-1.42H17.067a4.267 4.267 0 0 0-4.24 4.737l2.014 18.133a4.267 4.267 0 0 0 4.24 3.796h25.837a4.267 4.267 0 0 0 4.242-3.796l2.014-18.133a4.267 4.267 0 0 0-1.061-3.317Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M21.333 35.2c.59 0 1.067.478 1.067 1.067v16a1.067 1.067 0 1 1-2.133 0v-16c0-.59.477-1.067 1.066-1.067Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M30.533 35.434c.46.368.535 1.039.167 1.5L22.166 47.6a1.067 1.067 0 1 1-1.666-1.333L29.034 35.6a1.067 1.067 0 0 1 1.499-.166Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M23.462 42.654a1.067 1.067 0 0 1 1.48.296l5.813 8.725a1.067 1.067 0 1 1-1.776 1.183l-5.813-8.725a1.067 1.067 0 0 1 .296-1.48ZM40.953 37.669a3.113 3.113 0 0 0-2.15-.249l-.014.004c-1.4.322-2.496 1.291-3.29 2.646-.8 1.361-1.244 3.037-1.347 4.564l-.003.04a6.92 6.92 0 0 0 1.556 5.14c.994 1.078 2.799 1.599 4.375 1.306.77-.143 1.413-.469 1.855-.939.429-.456.731-1.112.731-2.06v-2.254h-3.2a1.067 1.067 0 0 1 0-2.134h3.2c1.179 0 2.134.956 2.134 2.134v2.255c0 1.447-.482 2.639-1.31 3.52-.815.867-1.902 1.367-3.02 1.575-2.193.407-4.794-.264-6.358-1.984a9.052 9.052 0 0 1-2.087-6.762c.124-1.794.643-3.793 1.633-5.48.996-1.697 2.517-3.153 4.645-3.645a5.246 5.246 0 0 1 3.627.426l.012.007a5.359 5.359 0 0 1 2.858 4.606 1.067 1.067 0 1 1-2.133.053 3.226 3.226 0 0 0-1.714-2.77Z"
      clipRule="evenodd"
    />
  </svg>
);
export { Scales };
