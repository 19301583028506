import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';

const Battery = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      d="M14.309 58.506a5 5 0 0 0 7.07 0l37.042-37.04a5 5 0 0 0 0-7.072l-2.151-2.152-7.834-7.836 2.139-2.14a1.016 1.016 0 0 1 1.433 0l5.695 5.7a1.015 1.015 0 0 1 0 1.432 1 1 0 1 0 1.414 1.414 3.014 3.014 0 0 0 0-4.26L53.422.852a3.086 3.086 0 0 0-4.261 0l-2.14 2.14-1.446-1.446a5 5 0 0 0-7.071 0L1.462 38.59a5.005 5.005 0 0 0 0 7.07l12.847 12.847ZM2.877 40.003 39.919 2.965a3 3 0 0 1 4.242 0l12.842 12.842a3 3 0 0 1 0 4.242L19.965 57.091a3 3 0 0 1-4.242 0L2.877 44.245a3 3 0 0 1 0-4.242Z"
    />
    <path
      fill={colors.ultramarine900}
      d="M16.43 54.934a2 2 0 0 0 2.828 0l5.7-5.7a2 2 0 0 0 0-2.828l-11.4-11.4a2.005 2.005 0 0 0-2.83 0l-5.7 5.7a2 2 0 0 0 0 2.828l11.402 11.4Zm-4.28-18.512 11.4 11.4-5.7 5.7-11.4-11.4 5.7-5.7Z"
    />
  </svg>
);
export { Battery };
