import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Day = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      d="M4.03 16v40.533C4.03 60.65 7.38 64 11.496 64h29.867c4.116 0 7.467-3.35 7.467-7.467h7.916c1.31 0 2.478-.788 2.979-2.01.495-1.209.229-2.523-.694-3.43-8.587-8.44-10.19-26.467-10.2-26.56V16c0-1.505-.484-2.964-1.332-4.194.167-.71.265-1.445.265-2.206 0-5.294-4.306-9.6-9.6-9.6-4.933 0-9.005 3.74-9.537 8.533h-2.26C25.836 3.741 21.764 0 16.83 0a9.621 9.621 0 0 0-7.102 3.147A9.488 9.488 0 0 0 7.246 9.87 7.473 7.473 0 0 0 4.03 16Zm37.333 45.867H11.496a5.34 5.34 0 0 1-5.333-5.334v-20.53c2.127 7.681 6.26 16.988 14.119 20.285a3.2 3.2 0 0 0 1.245.244h25.17a5.34 5.34 0 0 1-5.334 5.335Zm16.172-9.254c.429.423.293.911.215 1.102-.135.332-.47.685-1.005.685H21.527c-.152 0-.296-.027-.42-.078C8.55 49.052 6.25 25.444 6.162 24.49v-1.023H46.7v1.155c.064.772 1.68 18.994 10.835 27.991ZM46.696 16l.003 5.333H6.163V16a5.347 5.347 0 0 1 3.075-4.836 5.287 5.287 0 0 1 2.258-.497h8.338A3.196 3.196 0 0 1 16.83 12.8a3.204 3.204 0 0 0-3.2 3.2c0 1.764 1.435 3.2 3.2 3.2 4.933 0 9.005-3.74 9.537-8.533h14.8a3.196 3.196 0 0 1-3.004 2.133 3.204 3.204 0 0 0-3.2 3.2c0 1.764 1.436 3.2 3.2 3.2 3.53 0 6.614-1.921 8.282-4.767.157.506.251 1.031.251 1.567Zm-32.87-7.467A3.196 3.196 0 0 1 16.83 6.4c1.389 0 2.562.895 3.004 2.133h-6.007Zm21.333 0A3.196 3.196 0 0 1 38.163 6.4c1.389 0 2.562.895 3.004 2.133h-6.008Zm3.004-6.4c4.116 0 7.467 3.35 7.467 7.467 0 4.116-3.35 7.467-7.467 7.467a1.067 1.067 0 0 1 0-2.134A5.34 5.34 0 0 0 43.496 9.6a5.34 5.34 0 0 0-5.333-5.333 5.343 5.343 0 0 0-5.225 4.266h-2.156c.52-3.611 3.628-6.4 7.381-6.4Zm-26.856 2.45a7.482 7.482 0 0 1 5.523-2.45c4.116 0 7.467 3.35 7.467 7.467 0 4.116-3.35 7.467-7.467 7.467a1.067 1.067 0 0 1 0-2.134A5.34 5.34 0 0 0 22.163 9.6a5.34 5.34 0 0 0-5.333-5.333 5.343 5.343 0 0 0-5.226 4.266h-.107c-.72 0-1.42.11-2.093.306a7.384 7.384 0 0 1 1.903-4.257Z"
    />
    <path
      fill={colors.ultramarine900}
      d="M41.467 28.367a4.227 4.227 0 0 0-3.225-.518 4.233 4.233 0 0 0-2.644 1.915l-6.214 10.1-2.72-1.813a4.238 4.238 0 0 0-3.205-.635 4.237 4.237 0 0 0-2.711 1.818 4.27 4.27 0 0 0 1.183 5.917l6.4 4.266a4.261 4.261 0 0 0 3.28.618 4.29 4.29 0 0 0 2.72-1.932l8.533-13.866c.598-.971.78-2.116.516-3.225a4.238 4.238 0 0 0-1.913-2.645Zm-.42 4.75-8.534 13.867c-.302.491-.798.843-1.362.967a2.163 2.163 0 0 1-1.637-.31l-6.4-4.266a2.122 2.122 0 0 1-.91-1.356c-.11-.559 0-1.128.318-1.603a2.123 2.123 0 0 1 1.778-.95c.418 0 .825.122 1.18.359l4.558 3.04 7.375-11.982a2.119 2.119 0 0 1 1.323-.958 2.118 2.118 0 0 1 1.612.26c.485.298.824.768.957 1.322.132.554.04 1.125-.258 1.61Z"
    />
  </svg>
);
export { Day };
