import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Id = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M0 13.867a3.2 3.2 0 0 1 3.2-3.2h57.6a3.2 3.2 0 0 1 3.2 3.2v36.266a3.2 3.2 0 0 1-3.2 3.2H3.2a3.2 3.2 0 0 1-3.2-3.2V13.867ZM3.2 12.8c-.59 0-1.067.478-1.067 1.067v36.266c0 .59.478 1.067 1.067 1.067h57.6c.59 0 1.067-.478 1.067-1.067V13.867c0-.59-.478-1.067-1.067-1.067H3.2Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M16 20.267a3.2 3.2 0 0 0-3.2 3.2v4.266a3.2 3.2 0 0 0 3.2 3.2h2.134a3.2 3.2 0 0 0 3.2-3.2v-4.266a3.2 3.2 0 0 0-3.2-3.2H16Zm-5.333 3.2A5.333 5.333 0 0 1 16 18.133h2.134a5.333 5.333 0 0 1 5.333 5.334v4.266a5.333 5.333 0 0 1-5.333 5.334H16a5.333 5.333 0 0 1-5.333-5.334v-4.266ZM12.516 35.2H21.617a5.214 5.214 0 0 1 5.05 5.348v5.319c0 .589-.478 1.067-1.067 1.067h-5.02a1.067 1.067 0 1 1 0-2.134h3.953V40.503a3.08 3.08 0 0 0-2.967-3.17h-8.999a3.08 3.08 0 0 0-2.967 3.2v5.334a1.067 1.067 0 0 1-2.133 0v-5.319a5.214 5.214 0 0 1 5.049-5.347ZM32 16c.59 0 1.067.478 1.067 1.067v35.2a1.067 1.067 0 1 1-2.133 0v-35.2c0-.59.477-1.067 1.066-1.067ZM36.267 20.267c0-.59.477-1.067 1.066-1.067h19.2a1.067 1.067 0 0 1 0 2.134h-19.2c-.589 0-1.066-.478-1.066-1.067ZM36.267 26.667c0-.59.477-1.067 1.066-1.067h19.2a1.067 1.067 0 0 1 0 2.133h-19.2c-.589 0-1.066-.477-1.066-1.066ZM36.267 33.067c0-.59.477-1.067 1.066-1.067h19.2a1.067 1.067 0 0 1 0 2.133h-19.2c-.589 0-1.066-.477-1.066-1.066ZM45.867 39.467c0-.59.477-1.067 1.066-1.067h9.6a1.067 1.067 0 0 1 0 2.133h-9.6c-.589 0-1.066-.477-1.066-1.066Z"
      clipRule="evenodd"
    />
  </svg>
);
export { Id };
