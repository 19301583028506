import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Chocolate = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={65} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M19.302 13.761h4.267v-6.4h-4.267v6.4Zm5.334 2.133h-6.4c-.59 0-1.067-.477-1.067-1.066V6.294c0-.59.477-1.066 1.067-1.066h6.4c.59 0 1.067.477 1.067 1.066v8.534c0 .589-.477 1.066-1.067 1.066ZM29.968 13.761h4.267v-6.4h-4.267v6.4Zm5.333 2.133h-6.4c-.59 0-1.066-.477-1.066-1.066V6.294c0-.59.477-1.066 1.066-1.066h6.4c.59 0 1.067.477 1.067 1.066v8.534c0 .589-.476 1.066-1.067 1.066ZM40.636 13.761h4.266v-6.4h-4.266v6.4Zm5.333 2.133h-6.4c-.59 0-1.067-.477-1.067-1.066V6.294c0-.59.477-1.066 1.067-1.066h6.4c.59 0 1.067.477 1.067 1.066v8.534c0 .589-.477 1.066-1.067 1.066ZM26.769 31.895c.152 0 .304.032.447.098l13.244 6.113 6.948-5.954a1.06 1.06 0 0 1 .694-.257h7.607l-4.887-7.33-22.75 5.171a1.06 1.06 0 0 1-.595-.037l-14.149-5.053-4.833 7.249h6.54c.285 0 .556.112.755.313l4.645 4.644 5.65-4.71c.197-.163.44-.247.684-.247m13.867 8.533c-.153 0-.304-.032-.447-.098l-13.255-6.117-5.88 4.902a1.066 1.066 0 0 1-1.439-.066l-5.02-5.02H6.503a1.068 1.068 0 0 1-.888-1.66l6.4-9.6a1.065 1.065 0 0 1 1.246-.411l14.641 5.228 23.164-5.265c.436-.096.879.082 1.124.449l6.4 9.6a1.068 1.068 0 0 1-.888 1.659h-9.205l-7.168 6.142c-.197.17-.444.257-.693.257Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M51.303 64.961h-38.4c-.59 0-1.067-.476-1.067-1.066V32.96a1.066 1.066 0 1 1 2.133 0v29.866h36.267V32.961a1.066 1.066 0 1 1 2.133 0v30.934c0 .59-.477 1.066-1.067 1.066M15.036 22.99l12.868 4.596 21.266-4.833V3.095H15.036V22.99Zm12.8 6.771c-.121 0-.243-.021-.358-.063L13.61 24.746a1.064 1.064 0 0 1-.708-1.004V2.028c0-.59.477-1.067 1.067-1.067h36.266c.59 0 1.067.477 1.067 1.067v21.576c0 .498-.345.93-.83 1.041l-22.4 5.09a1.04 1.04 0 0 1-.237.027Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M40.636 20.161v4.53l4.266-.968v-3.562h-4.266Zm-1.067 6.934a1.068 1.068 0 0 1-1.067-1.067v-6.933c0-.59.477-1.067 1.067-1.067h6.4c.59 0 1.067.477 1.067 1.067v5.48c0 .498-.345.93-.83 1.039l-6.4 1.455a1.095 1.095 0 0 1-.237.026ZM29.968 26.561h2.441l1.826-.414V20.16h-4.267v6.4Zm2.56 2.134h-3.627c-.59 0-1.066-.477-1.066-1.067v-8.533c0-.59.477-1.067 1.066-1.067h6.4c.59 0 1.067.477 1.067 1.067v7.902c0 .499-.344.93-.83 1.042l-2.773.63a1.174 1.174 0 0 1-.237.026ZM19.302 24.513l4.267 1.525v-5.877h-4.267v4.352Zm5.334 4.106c-.12 0-.242-.022-.36-.063l-6.4-2.287a1.064 1.064 0 0 1-.707-1.004v-6.17c0-.59.477-1.067 1.067-1.067h6.4c.59 0 1.067.477 1.067 1.067v8.457a1.065 1.065 0 0 1-1.067 1.067ZM13.968 62.828h4.923L50.235 34.47v-.442h-1.74l-7.167 6.143c-.316.272-.764.33-1.14.16l-2.595-1.199-23.624 21.375v2.32Zm5.334 2.133h-6.4c-.59 0-1.067-.477-1.067-1.066v-3.86c0-.301.127-.59.35-.791l24.5-22.166a1.07 1.07 0 0 1 1.163-.178l2.612 1.206 6.948-5.954a1.06 1.06 0 0 1 .694-.257h3.2c.59 0 1.067.477 1.067 1.067v1.98c0 .302-.127.59-.351.792l-32 28.95a1.065 1.065 0 0 1-.716.277Z"
      clipRule="evenodd"
    />
  </svg>
);
export { Chocolate };
