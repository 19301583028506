import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const X = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      stroke={colors.ultramarine900}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m6 6 12 12M6 18 18 6 6 18Z"
    />
  </svg>
);
export { X };
