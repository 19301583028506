import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Menu = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <g fill={colors.ultramarine900} clipPath="url(#menu_svg__a)">
      <path d="M58.667 5.43V3.2a3.2 3.2 0 0 0-6.4 0v2.133h-5.334V3.2a3.2 3.2 0 0 0-6.4 0v2.133H35.2V3.2a3.2 3.2 0 0 0-6.4 0v2.133h-5.333V3.2a3.2 3.2 0 0 0-6.4 0v2.133h-5.334V3.2a3.2 3.2 0 0 0-6.4 0v2.23A6.406 6.406 0 0 0 0 11.732v48A4.267 4.267 0 0 0 4.267 64h55.466A4.267 4.267 0 0 0 64 59.733v-48a6.406 6.406 0 0 0-5.333-6.304ZM54.4 3.2a1.067 1.067 0 0 1 2.133 0v6.4a1.067 1.067 0 1 1-2.133 0V3.2Zm-11.733 0a1.067 1.067 0 1 1 2.133 0v6.4a1.067 1.067 0 0 1-2.133 0V3.2Zm-11.734 0a1.067 1.067 0 0 1 2.134 0v6.4a1.067 1.067 0 0 1-2.134 0V3.2ZM19.2 3.2a1.067 1.067 0 0 1 2.133 0v6.4a1.067 1.067 0 1 1-2.133 0V3.2Zm-11.733 0a1.067 1.067 0 1 1 2.133 0v6.4a1.067 1.067 0 1 1-2.133 0V3.2Zm-5.334 8.533a4.267 4.267 0 0 1 3.2-4.115V9.6a3.2 3.2 0 0 0 6.4 0V7.467h5.334V9.6a3.2 3.2 0 0 0 6.4 0V7.467H28.8V9.6a3.2 3.2 0 0 0 6.4 0V7.467h5.333V9.6a3.2 3.2 0 0 0 6.4 0V7.467h5.334V9.6a3.2 3.2 0 0 0 6.4 0V7.618a4.267 4.267 0 0 1 3.2 4.115v5.334H2.133v-5.334Zm59.734 48a2.133 2.133 0 0 1-2.134 2.134H4.267a2.133 2.133 0 0 1-2.134-2.134V19.2h59.734v40.533Z" />
      <path d="M45.808 35.54a14.433 14.433 0 0 0-5.375.32 1.56 1.56 0 0 0-.702 2.72c.407.346.869.717 1.35 1.079a5.81 5.81 0 0 0-2.552 2.421 11.477 11.477 0 0 0-.68 8.603c1.324 4.748 4.724 7.984 8.116 7.984A5.22 5.22 0 0 0 48 58.256c.233.1.474.181.72.243 3.795.985 7.937-2.453 9.43-7.825a11.474 11.474 0 0 0-.678-8.602 5.597 5.597 0 0 0-3.446-2.756 5.882 5.882 0 0 0-4.714.923c-.01.006-.023.008-.034.015a4.276 4.276 0 0 1 2.393-3.087 1.067 1.067 0 0 0-.685-2.02 5.809 5.809 0 0 0-2.453 1.827 5.973 5.973 0 0 0-2.725-1.435Zm-.514 2.07c1.529.378 2.167 1.236 2.11 1.464-.02.076-.192.237-.578.346a3.975 3.975 0 0 1-1.971-.035 9.352 9.352 0 0 1-2.786-1.657 9.666 9.666 0 0 1 3.225-.118Zm5.173 4.426a3.877 3.877 0 0 1 3.027-.65 3.567 3.567 0 0 1 2.133 1.757 9.337 9.337 0 0 1 .471 6.964c-1.141 4.095-4.266 6.988-6.847 6.33a2.963 2.963 0 0 1-.723-.293 1.066 1.066 0 0 0-1.051 0c-.229.13-.473.229-.728.294-2.57.657-5.702-2.24-6.843-6.331a9.334 9.334 0 0 1 .473-6.965 3.568 3.568 0 0 1 2.133-1.756c.248-.064.503-.096.759-.095.811.03 1.598.288 2.268.746.43.273.904.472 1.4.587v.925a1.067 1.067 0 0 0 2.133 0v-.925a4.534 4.534 0 0 0 1.395-.588ZM8.533 38.4H35.2a1.067 1.067 0 0 0 0-2.133h-1.067V25.6H44.8v5.333a1.067 1.067 0 0 0 2.133 0V25.6a2.133 2.133 0 0 0-2.133-2.133H8.533A2.133 2.133 0 0 0 6.4 25.6v10.667c0 1.178.955 2.133 2.133 2.133ZM32 36.267H21.333V25.6H32v10.667ZM8.533 25.6H19.2v10.667H8.533V25.6ZM8.533 57.6H32a2.133 2.133 0 0 0 2.133-2.133V44.8A2.133 2.133 0 0 0 32 42.667H8.533A2.133 2.133 0 0 0 6.4 44.8v10.667c0 1.178.955 2.133 2.133 2.133ZM32 55.467H21.333V44.8H32v10.667ZM8.533 44.8H19.2v10.667H8.533V44.8Z" />
      <path d="M16.312 28.046 12.8 31.558l-1.38-1.379a1.067 1.067 0 0 0-1.508 1.508l2.134 2.134a1.067 1.067 0 0 0 1.508 0l4.266-4.267a1.067 1.067 0 0 0-1.508-1.508ZM24.846 33.82a1.067 1.067 0 0 0 1.508 0l4.267-4.266a1.067 1.067 0 0 0-1.508-1.508L25.6 31.558l-1.38-1.379a1.067 1.067 0 0 0-1.508 1.508l2.134 2.134ZM39.154 33.82l4.267-4.266a1.067 1.067 0 0 0-1.509-1.508L38.4 31.558l-1.38-1.379a1.067 1.067 0 0 0-1.508 1.508l2.134 2.134a1.067 1.067 0 0 0 1.508 0ZM16.312 47.246 12.8 50.758l-1.38-1.379a1.067 1.067 0 0 0-1.508 1.508l2.134 2.134a1.067 1.067 0 0 0 1.508 0l4.266-4.267a1.067 1.067 0 0 0-1.508-1.508ZM24.846 53.02a1.067 1.067 0 0 0 1.508 0l4.267-4.266a1.067 1.067 0 0 0-1.508-1.508L25.6 50.758l-1.38-1.379a1.067 1.067 0 0 0-1.508 1.508l2.134 2.134Z" />
    </g>
    <defs>
      <clipPath id="menu_svg__a">
        <path fill={colors.white} d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
);
export { Menu };
