import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Water = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      d="M45 28.78v-8.14a10.028 10.028 0 0 0-7.1-9.57 4.109 4.109 0 0 1-1.669-1.339A3 3 0 0 0 38 7V5a3 3 0 0 0-3-3h-6a3 3 0 0 0-3 3v2a3 3 0 0 0 1.774 2.731 4.1 4.1 0 0 1-1.588 1.31A10.064 10.064 0 0 0 19 20.64v8.14A2.985 2.985 0 0 0 18 31v2c.003.74.28 1.453.78 2a2.98 2.98 0 0 0-.78 2v2c.003.74.28 1.453.78 2a2.98 2.98 0 0 0-.78 2v2a2.985 2.985 0 0 0 1 2.22V58a4 4 0 0 0 4 4h18a4 4 0 0 0 4-4V47.22A2.985 2.985 0 0 0 46 45v-2a2.98 2.98 0 0 0-.78-2c.5-.547.777-1.26.78-2v-2a2.98 2.98 0 0 0-.78-2c.5-.547.777-1.26.78-2v-2a2.985 2.985 0 0 0-1-2.22ZM28 5a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1V5Zm-1.163 7.93A5.466 5.466 0 0 0 29.843 10h4.314a5.494 5.494 0 0 0 3.091 2.959A8.052 8.052 0 0 1 43 20.64v2.741l-2.548-1.275a10 10 0 0 0-8.9 0 7.979 7.979 0 0 1-7.106 0l-3.425-1.714a8.083 8.083 0 0 1 5.816-7.462ZM43 58a2 2 0 0 1-2 2H23a2 2 0 0 1-2-2V48h3a1 1 0 1 0 0-2h-3a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h3a1 1 0 1 0 0-2h-3a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h3a1 1 0 1 0 0-2h-3a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h3a1 1 0 1 0 0-2h-3v-5.382l2.555 1.282a9.993 9.993 0 0 0 8.894 0 7.985 7.985 0 0 1 7.109 0L43 25.618V28H30a1 1 0 1 0 0 2h13a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H30a1 1 0 1 0 0 2h13a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H30a1 1 0 1 0 0 2h13a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H30a1 1 0 1 0 0 2h13v10Z"
    />
    <path fill={colors.ultramarine900} d="M25 53a1 1 0 1 0-2 0v4a1 1 0 0 0 1 1h8a1 1 0 1 0 0-2h-7v-3Z" />
  </svg>
);
export { Water };
