import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Warrior = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M9.932 40.544a1.602 1.602 0 0 0-1.968 1.468L6.896 60.168v.003a1.614 1.614 0 0 0 1.49 1.693h.113a1.611 1.611 0 0 0 1.605-1.516l.887-14.978a1.068 1.068 0 0 1 1.31-.977l13.984 3.28c.277.065.517.238.666.48l7.959 12.948c.291.474.808.763 1.365.763h19.23a1.603 1.603 0 0 0 0-3.205H37.77c-.371 0-.715-.193-.91-.508l-5.427-8.814a1.068 1.068 0 1 1 1.82-1.12l5.114 8.305h17.136a3.739 3.739 0 0 1 0 7.478H36.276a3.74 3.74 0 0 1-3.186-1.78l-7.726-12.569-12.315-2.888-.812 13.71A3.748 3.748 0 0 1 8.502 64H8.35c-.02 0-.041 0-.062-.002a3.75 3.75 0 0 1-3.525-3.943v-.006l1.068-18.16c0 .001 0 0 0 0a3.74 3.74 0 0 1 4.591-3.424l12.08 2.844 3.178.26a1.068 1.068 0 1 1-.175 2.13l-3.258-.267a1.049 1.049 0 0 1-.158-.025L9.932 40.544Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M26.565 27.515c.244.537.006 1.17-.53 1.414a8.829 8.829 0 0 0-3.159 2.42 1.08 1.08 0 0 1-.078.086 3.36 3.36 0 0 0-.981 2.887l1.565 7.836a1.068 1.068 0 0 1-2.096.419l-1.57-7.863a5.497 5.497 0 0 1 1.548-4.768 10.966 10.966 0 0 1 3.886-2.962 1.068 1.068 0 0 1 1.415.53ZM30.934 26.61c.59 0 1.068.479 1.068 1.069v11.75a1.068 1.068 0 0 1-2.136 0V27.68c0-.59.478-1.069 1.068-1.069Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M30.15 39.282c.4-.433 1.075-.46 1.509-.06a7.746 7.746 0 0 1 2.48 6.108 9.253 9.253 0 0 1-.822 3.887 1.068 1.068 0 0 1-1.946-.88 7.114 7.114 0 0 0 .631-3.019c0-.025 0-.05.002-.075a5.61 5.61 0 0 0-1.795-4.451 1.068 1.068 0 0 1-.06-1.51ZM25.133 14.86c.106 0 .212.008.317.022a1.068 1.068 0 1 0 .285-2.117 4.665 4.665 0 0 0-.682-.04 6.185 6.185 0 0 0-5.871 6.408 6.185 6.185 0 0 0 5.922 6.41c.21 0 .422-.014.63-.042a1.068 1.068 0 1 0-.284-2.118 2.52 2.52 0 0 1-.317.023 4.049 4.049 0 0 1-3.815-4.225 1.031 1.031 0 0 0 0-.097 4.049 4.049 0 0 1 3.815-4.224Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M24.524 3.739a3.739 3.739 0 0 1 7.478 0v24.378a1.068 1.068 0 0 1-2.136 0V3.739a1.602 1.602 0 0 0-3.205 0v24.218a1.068 1.068 0 0 1-2.137 0V3.739Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M34.15 11.612c.826-.24 1.74.027 2.266.637l-2.266-.637Zm2.266.637c.469.544.788 1.571-.027 3.2l.027-3.2Zm1.883 4.157c1.11-2.216.89-4.211-.265-5.551-1.098-1.275-2.892-1.756-4.48-1.294h-.001c-2.085.607-3.009 2.19-3.394 3.494a7.37 7.37 0 0 0-.282 2.431l.002.016v.008c0 .001 0 .002 1.065-.088l-1.065.09a1.068 1.068 0 0 0 2.13-.177l-.002-.018a4.88 4.88 0 0 1 .002-.463c.017-.322.068-.75.199-1.194.259-.877.79-1.712 1.942-2.048m4.15 4.794c-.148.295-.235.784-.183 1.445.05.644.227 1.363.506 2.047.28.686.645 1.294 1.044 1.739.407.453.781.665 1.074.718a1.068 1.068 0 0 1 .204 2.043l-.397-.992.396.992h-.002l-.003.002-.008.003-.022.009a4.618 4.618 0 0 1-.294.097 7.066 7.066 0 0 1-.782.183 8.412 8.412 0 0 1-2.64.038c-2.177-.31-4.846-1.553-7.148-5.005a1.068 1.068 0 1 1 1.778-1.185c1.972 2.958 4.11 3.852 5.672 4.075.08.012.159.022.236.03a8.628 8.628 0 0 1-1.086-1.94 9.157 9.157 0 0 1-.658-2.686c-.069-.872.015-1.796.401-2.569M26.627 27.414a1.068 1.068 0 0 0-2.07.524l1.035-.26-1.036.261v.001l.001.003.002.007.005.018a3.31 3.31 0 0 0 .07.228c.047.142.118.336.22.56.199.438.53 1.027 1.051 1.548.53.53 1.268.997 2.24 1.136.967.138 2.056-.064 3.267-.67a1.068 1.068 0 0 0-.956-1.91c-.925.463-1.573.527-2.008.465a1.782 1.782 0 0 1-1.032-.532 3.177 3.177 0 0 1-.617-.922 3.652 3.652 0 0 1-.167-.44l-.005-.017m0 0ZM20.328 36.897c.219-.548.84-.815 1.388-.596l-.396.992.396-.992.006.003.035.013a12.426 12.426 0 0 0 .765.252c.53.156 1.28.343 2.153.468 1.77.253 3.922.23 5.781-.7a1.068 1.068 0 1 1 .956 1.912c-2.414 1.207-5.07 1.184-7.039.903a17.623 17.623 0 0 1-2.454-.533 14.652 14.652 0 0 1-.97-.324l-.018-.006-.005-.003h-.002l.396-.993-.397.992a1.068 1.068 0 0 1-.595-1.388ZM5.316 56.313a1.068 1.068 0 0 1 1.256-.839l4.979.994a1.068 1.068 0 0 1-.418 2.095l-4.979-.993a1.068 1.068 0 0 1-.838-1.257ZM52.3 56.522c.59 0 1.068.478 1.068 1.068v5.342a1.068 1.068 0 0 1-2.137 0V57.59c0-.59.478-1.068 1.068-1.068Z"
      clipRule="evenodd"
    />
  </svg>
);
export { Warrior };
