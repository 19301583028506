import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Drag = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      d="M59.733 25.605c-.851 0-1.683.254-2.388.733a4.22 4.22 0 0 0-6.758-1.837 4.199 4.199 0 0 0-5.787-1.503v-1.493a10.667 10.667 0 1 0-8.533 0l-.014 15.833c-3.534-2.257-6.456-3.498-10.911-3.2a4.267 4.267 0 0 0-.226 8.504 7.586 7.586 0 0 1 4.92 2.27c.47.565.64.802.782 1.008a32.44 32.44 0 0 0 3.03 3.471 20.574 20.574 0 0 0 3.387 2.98 8.569 8.569 0 0 1 2.854 4.566 3.19 3.19 0 0 0 1.511 6.001h17.067a3.19 3.19 0 0 0 2.178-5.53c.256-.433.533-.877.843-1.345C64 52.593 64 50.206 64 45.871v-16a4.267 4.267 0 0 0-4.267-4.266ZM32 11.738a8.533 8.533 0 1 1 12.8 7.37v-5.055a4.373 4.373 0 0 0-4.04-4.448 4.267 4.267 0 0 0-4.493 4.266v5.236A8.527 8.527 0 0 1 32 11.738Zm26.667 49.067H41.6a1.067 1.067 0 0 1 0-2.133h17.067a1.067 1.067 0 0 1 0 2.133Zm3.2-14.934c0 4.173 0 6.08-1.954 9.008-.396.594-.731 1.144-1.04 1.68-.07 0-.136-.02-.206-.02H42.194a10.804 10.804 0 0 0-3.702-5.894 18.7 18.7 0 0 1-3.085-2.713 32.264 32.264 0 0 1-2.845-3.241c-.164-.234-.353-.503-.878-1.137a9.664 9.664 0 0 0-6.325-3.03 2.133 2.133 0 0 1 .123-4.252c3.895-.266 6.39.802 9.632 2.869a2.152 2.152 0 0 0 2.18.06 2.126 2.126 0 0 0 1.095-1.863l.011-23.467a2.12 2.12 0 0 1 .667-1.552 2.181 2.181 0 0 1 1.573-.58 2.27 2.27 0 0 1 2.027 2.314v16.885a1.067 1.067 0 0 0 2.133 0v-4.266a2.133 2.133 0 0 1 4.267 0v5.333a1.067 1.067 0 0 0 2.133 0v-4.267a2.133 2.133 0 0 1 4.267 0v5.334a1.067 1.067 0 0 0 2.133 0v-3.2a2.133 2.133 0 0 1 4.267 0v16ZM1.067 46.938c.589 0 1.066-.477 1.066-1.067a38.555 38.555 0 0 1 25.22-36.085 1.067 1.067 0 1 0-.733-2.004A40.696 40.696 0 0 0 0 45.872c0 .589.478 1.066 1.067 1.066Z"
    />
    <path
      fill={colors.ultramarine900}
      d="M28.401 18.58a1.067 1.067 0 1 0-.867-1.95c-15.703 6.972-22.918 24.747-16.78 41.334a1.067 1.067 0 0 0 2-.739C6.212 39.54 15.934 24.111 28.401 18.58Z"
    />
  </svg>
);
export { Drag };
