export const AboriginalFlag = (props: object) => (
  <svg width="44" height="28" viewBox="0 0 44 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} role="img">
    <rect x="1" y="1" width="42" height="26" rx="3" fill="#161820" />
    <rect x="1" y="1" width="42" height="26" rx="3" stroke="#4A4B52" strokeWidth="2" />
    <path d="M2 14H42V26H2V14Z" fill="#FC553F" rx="3" />
    <path
      d="M27.5744 11.7164C26.4715 8.55231 23.2474 7.84919 20.8717 8.02497C17.8174 8.37652 15.6962 10.3101 16.0356 13.9137C16.375 17.5172 19.2597 20.857 23.5019 19.8023C27.7441 18.7476 28.6774 14.8804 27.5744 11.7164Z"
      fill="#FFD872"
    />
    <rect x="1" y="1" width="42" height="26" rx="3" stroke="#4A4B52" strokeWidth="2" />
  </svg>
);
