import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Groceries = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <g clipPath="url(#groceries_svg__a)">
      <g fill={colors.ultramarine900} clipPath="url(#groceries_svg__b)">
        <path d="M13.764 23.564c-.371 0-.743-.192-.957-.53-.676-1.104-1.971-2.376-3.39-2.376h-.113a1.13 1.13 0 0 1-1.127-1.093 1.13 1.13 0 0 1 1.093-1.16c2.41-.034 4.393 1.723 5.452 3.435a1.133 1.133 0 0 1-.958 1.724ZM28.587 28.317c-.8 0-1.644-.09-2.613-.282a1.135 1.135 0 0 1-.901-1.317 1.135 1.135 0 0 1 1.318-.902c2.737.518 4.201.237 6.251-1.216 1.61-1.149 2.94-1.78 4.303-2.05a1.129 1.129 0 0 1 1.329.878 1.129 1.129 0 0 1-.879 1.33c-1.058.214-2.083.71-3.435 1.678-1.825 1.295-3.402 1.88-5.361 1.88h-.012ZM27.415 15.87a2.736 2.736 0 0 1-1.464-.427 2.744 2.744 0 0 1-1.194-1.713 2.693 2.693 0 0 1 .372-2.05 2.692 2.692 0 0 1 1.7-1.182l6.218-1.363a2.709 2.709 0 0 1 3.233 2.072c.158.71.023 1.43-.372 2.05a2.69 2.69 0 0 1-1.7 1.183l-6.218 1.363a2.595 2.595 0 0 1-.586.068h.011Zm6.218-4.539h-.09l-6.218 1.363a.513.513 0 0 0-.293.203.441.441 0 0 0-.067.35.513.513 0 0 0 .203.292c.056.034.191.09.349.068l6.217-1.363c.158-.034.248-.147.293-.203a.441.441 0 0 0 .068-.35.48.48 0 0 0-.462-.37v.01ZM41.214 28.565c-.124 0-.248-.023-.36-.057a1.128 1.128 0 0 1-.7-1.43c.755-2.208 3.166-3.402 5.373-2.647a1.985 1.985 0 0 0 2.512-1.239 1.144 1.144 0 0 1 1.43-.71c.587.203.902.845.71 1.43a4.238 4.238 0 0 1-5.372 2.648 1.98 1.98 0 0 0-2.512 1.239 1.122 1.122 0 0 1-1.07.766h-.011ZM43.883 20.714c-.124 0-.237-.023-.36-.056a1.143 1.143 0 0 1-.71-1.43 4.186 4.186 0 0 1 2.14-2.434 4.24 4.24 0 0 1 3.233-.214 1.98 1.98 0 0 0 2.511-1.239 1.124 1.124 0 0 1 1.43-.698c.587.203.902.845.7 1.43a4.238 4.238 0 0 1-5.374 2.647 1.95 1.95 0 0 0-1.509.102 1.993 1.993 0 0 0-1.002 1.137 1.122 1.122 0 0 1-1.07.766l.01-.011ZM46.541 12.874c-.124 0-.236-.022-.36-.056a1.143 1.143 0 0 1-.71-1.43 3.717 3.717 0 0 1 4.72-2.32c.765.258 1.599-.147 1.858-.913a1.123 1.123 0 0 1 1.43-.698c.586.202.901.844.699 1.43-.653 1.938-2.771 2.974-4.708 2.32a1.472 1.472 0 0 0-1.115.068c-.35.169-.609.473-.744.845a1.122 1.122 0 0 1-1.07.766v-.011Z" />
        <path d="M46.609 64H14.992a6.412 6.412 0 0 1-6.41-6.093L6.658 30.67a1.946 1.946 0 0 1 .934-1.78 1.902 1.902 0 0 1 1.983 0l1.51.913a4.351 4.351 0 0 0 4.493 0l.383-.237a5.835 5.835 0 0 1 5.993 0l.8.485a3.579 3.579 0 0 0 3.671 0l.8-.485a5.81 5.81 0 0 1 5.992 0l.8.485a3.579 3.579 0 0 0 3.672 0l.8-.485a5.81 5.81 0 0 1 5.992 0l.8.485a3.579 3.579 0 0 0 3.672 0c.045-.023.078-.046.123-.068l3.819-1.69a1.47 1.47 0 0 1 1.442.136c.428.292.664.777.642 1.306v.023l-1.972 28.193c-.169 3.39-2.984 6.07-6.409 6.07L46.61 64ZM8.954 31.144l1.87 26.627a4.172 4.172 0 0 0 4.168 3.976h31.617a4.159 4.159 0 0 0 4.156-3.953l1.881-26.92-2.58 1.149a5.816 5.816 0 0 1-5.924-.034l-.8-.485a3.579 3.579 0 0 0-3.672 0l-.8.485a5.81 5.81 0 0 1-5.992 0l-.8-.485a3.579 3.579 0 0 0-3.671 0l-.8.485a5.81 5.81 0 0 1-5.992 0l-.8-.485a3.579 3.579 0 0 0-3.672 0l-.383.237a6.608 6.608 0 0 1-6.814 0l-.98-.586-.012-.01Z" />
        <path d="M21.3 48.434h-7.885a1.13 1.13 0 0 1-1.126-1.127c0-.62.506-1.126 1.126-1.126h7.884c.62 0 1.127.507 1.127 1.126a1.13 1.13 0 0 1-1.127 1.127ZM23.552 52.94H13.415a1.13 1.13 0 0 1-1.126-1.127c0-.62.506-1.127 1.126-1.127h10.137c.62 0 1.127.507 1.127 1.127a1.13 1.13 0 0 1-1.127 1.126ZM25.805 57.445h-12.39a1.13 1.13 0 0 1-1.126-1.127c0-.62.506-1.126 1.126-1.126h12.39c.62 0 1.126.507 1.126 1.126a1.13 1.13 0 0 1-1.126 1.127ZM32.563 57.445H30.31a1.13 1.13 0 0 1-1.126-1.127c0-.62.507-1.126 1.126-1.126h2.253c.62 0 1.127.507 1.127 1.126a1.13 1.13 0 0 1-1.127 1.127ZM47.206 32.811a5.843 5.843 0 0 1-2.997-.833l-.8-.485a3.578 3.578 0 0 0-3.671 0l-.8.485a5.825 5.825 0 0 1-4.415.653 1.161 1.161 0 0 1-.699-.518 1.112 1.112 0 0 1-.112-.867c1.622-5.97 3.852-11.613 6.634-16.795.417-.777.89-1.79 1.397-2.86 1.937-4.1 4.336-9.214 7.704-10.949 2.151-1.104 3.796-.586 4.81.045 2.106 1.318 3.142 4.168 3.086 6.308-.18 6.86-1.43 14.08-3.717 21.446-.09.304-.327.991-.676 1.948-.101.293-.327.53-.608.654l-2.208.98c-.912.529-1.915.8-2.928.8v-.012Zm-5.632-4.077c1.036 0 2.072.281 2.996.833l.8.485a3.579 3.579 0 0 0 3.672 0c.045-.023.078-.046.123-.068l1.825-.811c.237-.676.406-1.16.485-1.397 2.23-7.163 3.446-14.17 3.615-20.837.034-1.453-.732-3.515-2.039-4.337-.754-.473-1.599-.462-2.579.045-2.68 1.386-5 6.308-6.702 9.9-.518 1.093-1.002 2.13-1.453 2.963-2.489 4.652-4.539 9.71-6.082 15.037a3.56 3.56 0 0 0 1.532-.495l.8-.485a5.843 5.843 0 0 1 2.996-.833h.01Z" />
        <path d="M35.638 32.789h-.079c-.09 0-.191-.012-.281-.023a7.737 7.737 0 0 1-.766-.135.21.21 0 0 1-.08-.023 5.496 5.496 0 0 1-1.497-.63l-.8-.485a3.578 3.578 0 0 0-3.672 0l-.8.485a1.104 1.104 0 0 1-1.024.067 1.124 1.124 0 0 1-.654-.788l-1.734-7.964c-.688-3.153.833-5.09 1.937-6.51.124-.158.248-.304.36-.462.023-.022.034-.056.057-.079.01-.022.034-.067 0-.157-.045-.147-.192-.282-.372-.36a2.408 2.408 0 0 1-1.397-1.69l-.135-.62a2.412 2.412 0 0 1 1.836-2.86l6.848-1.499a2.408 2.408 0 0 1 1.814.327c.54.349.912.878 1.047 1.51l.136.63a2.387 2.387 0 0 1-.53 2.072.656.656 0 0 0-.158.496c.023.124.08.169.124.191.17.102.35.192.519.282 1.329.698 3.153 1.656 4.066 3.953.112.282.101.609-.023.89a80.52 80.52 0 0 0-4.179 11.275c.36.203.586.609.563 1.048a1.13 1.13 0 0 1-1.126 1.047v.012Zm-5.328-4.055c1.037 0 2.073.281 2.997.833l.754.45a81.441 81.441 0 0 1 4.066-11.071c-.653-1.228-1.757-1.814-2.838-2.377l-.552-.293c-.023-.011-.079-.045-.101-.056-.597-.338-1.037-.969-1.16-1.724a2.92 2.92 0 0 1 .675-2.354.162.162 0 0 0 .034-.124l-.135-.63c-.011-.068-.113-.124-.169-.113l-6.848 1.498c-.08.023-.136.101-.113.18l.135.62c.012.056.056.09.08.101.754.327 1.34.946 1.61 1.69.236.653.18 1.363-.147 1.97a2.584 2.584 0 0 1-.236.373c-.124.157-.248.326-.383.495-.969 1.24-1.983 2.523-1.51 4.64l1.409 6.466c.777-.36 1.61-.54 2.444-.54l-.012-.034Z" />
        <path d="M24.656 32.8a5.74 5.74 0 0 1-2.974-.822l-.8-.485a3.578 3.578 0 0 0-3.671 0l-.383.237a6.608 6.608 0 0 1-6.815 0l-1.34-.811c-.26.112-.552.135-.822.056a1.147 1.147 0 0 1-.721-.72c-.631-1.904-.597-3.537.101-4.844 1.014-1.915 3.21-2.76 5.012-3.267a3.773 3.773 0 0 0 1.577-.912c1.476-1.408 3.47-2.985 5.824-2.636 2.072.305 3.976 2.04 5.823 5.294 1.25 2.208 1.554 5.463.732 8.099-.135.44-.518.743-.969.788-.191.012-.371.023-.563.023h-.011Zm-5.61-4.066c1.037 0 2.073.281 2.997.833l.8.485c.427.259.9.416 1.396.473.372-1.881.09-4.055-.743-5.52-1.464-2.579-2.872-3.987-4.19-4.178-1.082-.17-2.298.473-3.943 2.038a6.085 6.085 0 0 1-2.523 1.442c-2.005.575-3.165 1.262-3.638 2.163-.304.574-.372 1.295-.191 2.185.225.045.439.124.642.236l1.51.913a4.351 4.351 0 0 0 4.493 0l.383-.237a5.842 5.842 0 0 1 2.996-.833h.012Z" />
        <path d="M25.028 26.2c-.563 0-1.081-.395-1.532-1.195-1.43-2.523-2.805-3.92-4.1-4.167-.901-.17-1.903.225-3.142 1.228l-.766.63-.721-.687a4.388 4.388 0 0 1-1.386-3.21c0-1.025.35-2.005.98-2.793a5.697 5.697 0 0 1-.383-2.039 5.632 5.632 0 0 1 4.224-5.463c.011-2.286 1.588-4.246 3.728-4.832A4.142 4.142 0 0 1 25.963.473c.698 0 1.385.18 1.993.518a3.823 3.823 0 0 1 2.388-.495c1.498.18 2.794 1.284 3.222 2.748.034.113.067.237.09.35a5.384 5.384 0 0 1 4.179 4.156 6.524 6.524 0 0 1 3.728 6.048c-.034 1.746-.845 3.447-2.208 4.675l-.89.8-.743-.947c-.653-.845-1.577-1.318-2.455-1.79-.18-.102-.36-.192-.541-.294l-.079-.045a2.527 2.527 0 0 1-1.194-1.746 2.92 2.92 0 0 1 .676-2.354.163.163 0 0 0 .034-.124l-.135-.63c-.012-.068-.102-.124-.18-.113l-6.849 1.498c-.079.023-.135.101-.112.18l.135.62c.01.056.056.09.09.101a2.897 2.897 0 0 1 1.61 1.69c.237.664.18 1.363-.146 1.97a3.41 3.41 0 0 1-.236.372c-.113.158-.248.316-.372.485-.969 1.239-1.971 2.523-1.51 4.64l.023.158c.135 1.949-.18 2.895-1.059 3.165a1.17 1.17 0 0 1-.383.057l-.011.033Zm-6.049-7.649c.282 0 .552.023.834.08 1.498.28 2.895 1.306 4.269 3.13 0-2.275 1.194-3.795 2.117-4.967.124-.157.248-.315.36-.462.068-.112.08-.157.057-.236-.045-.147-.191-.282-.372-.36a2.408 2.408 0 0 1-1.396-1.69l-.135-.62a2.406 2.406 0 0 1 1.835-2.86l6.849-1.499a2.398 2.398 0 0 1 2.86 1.836l.136.62a2.387 2.387 0 0 1-.53 2.072c-.123.147-.19.338-.157.496.022.124.079.169.124.191l.507.27c.698.372 1.509.8 2.264 1.443.461-.676.72-1.442.743-2.242a4.296 4.296 0 0 0-2.883-4.145l-.699-.248-.056-.743a3.108 3.108 0 0 0-3.086-2.884h-1.273l.135-1.261a1.62 1.62 0 0 0-.056-.608c-.18-.597-.721-1.07-1.33-1.138a1.593 1.593 0 0 0-1.317.45l-.755.744-.788-.71a1.872 1.872 0 0 0-1.262-.495 1.896 1.896 0 0 0-1.892 1.892v1.047l-1.037.09a2.806 2.806 0 0 0-2.579 2.783c0 .214.023.428.079.653l.327 1.33-1.375.055a3.39 3.39 0 0 0-3.255 3.39c0 .598.158 1.184.462 1.69l.485.834-.744.608a2.187 2.187 0 0 0-.642 2.5c1.104-.709 2.163-1.07 3.188-1.07l-.012.034Z" />
      </g>
    </g>
    <defs>
      <clipPath id="groceries_svg__a">
        <path fill={colors.white} d="M0 0h64v64H0z" />
      </clipPath>
      <clipPath id="groceries_svg__b">
        <path fill={colors.white} d="M6.657 0h50.686v64H6.657z" />
      </clipPath>
    </defs>
  </svg>
);
export { Groceries };
