import clsx from 'clsx';
import Link from 'next/link';

import { Box } from '../box';

import * as styles from './index.css';

import { useAppContext } from '~/components/context';
import { Text } from '~/components/text';
import { AboriginalFlag, TorresStraitFlag, Brandmark } from '~/icons';

export const Footer = () => {
  const { email } = useAppContext();

  return (
    <Box
      as="footer"
      paddingX={{ sm: '3', md: '5' }}
      marginTop="5"
      background="black"
      display="flex"
      flexDirection="column"
      gap="2"
      paddingY="3"
    >
      <Box display="flex" flexDirection={{ sm: 'column', md: 'row' }} gap="3" marginBottom={{ sm: '2', lg: '0' }}>
        <Box height={{ sm: '3', lg: '1.5' }}>
          <Brandmark color="white" />
        </Box>
        <Box
          as="ul"
          display="flex"
          gap="2"
          flexDirection={{ sm: 'column', md: 'row' }}
          alignItems={{ sm: 'flex-start', md: 'center' }}
        >
          <Text as="li" color="white">
            <Link href="/about">About Us</Link>
          </Text>
          <Text as="li" color="white">
            <Link href="/blog">Blog</Link>
          </Text>
          <Text as="li" color="white">
            <Link href="/faq">FAQ</Link>
          </Text>
        </Box>
      </Box>
      <Box display="grid" gridTemplateColumns={{ sm: 'cols-2', md: 'cols-6' }} gap="3">
        <Box className={styles.left}>
          <Box className={styles.content}>
            <Box display="flex" gap="0.5">
              <AboriginalFlag />
              <TorresStraitFlag />
            </Box>
            <Text variant="caption" color="white">
              Find Your Rhythm acknowledges Aboriginal and Torres Strait Islander peoples as the First Peoples of this
              nation. We proudly recognise Elders past, present and emerging as the Traditional Owners of the lands on
              which we work and live. We're committed to supporting Indigenous self-determination and envision a future
              where all Australians embrace Aboriginal and Torres Strait Islander histories, cultures and rights as a
              central part of our national identity.
            </Text>
          </Box>
        </Box>
        <Box className={clsx(styles.right, styles.content)}>
          <Text variant="caption" color="white" whiteSpace="nowrap">
            Contact us:{' '}
            <Text display="inline" color="highlight">
              <Link href={`mailto:${email}`}>{email}</Link>
            </Text>
          </Text>
          <Text variant="caption" color="white">
            Find Your Rhythm is a business of Medibank Private Limited (ABN 47 080 890 259), a member of the Medibank
            group of companies. Copyright © 2023 Medibank Private Limited (ABN 47 080 890 259). All rights reserved.
            Please refer to our{' '}
            <Text display="inline" color="highlight">
              <Link href="/terms-and-conditions">Terms & Conditions&nbsp;</Link>
            </Text>{' '}
            and the{' '}
            <Text display="inline" color="white">
              Medibank&nbsp;
              <Text display="inline" color="highlight">
                <Link target="_blank" href="https://www.medibank.com.au/privacy/">
                  privacy policy&nbsp;
                </Link>
              </Text>
              for how your personal information is handled.
            </Text>
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
