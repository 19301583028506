export const TorresStraitFlag = (props: object) => (
  <svg width="44" height="28" viewBox="0 0 44 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} role="img">
    <rect x="2" y="2" width="40" height="24" fill="#012169" />
    <g clipPath="url(#clip0_3250_4250)">
      <rect x="2" y="2" width="40" height="24" rx="2" fill="#012169" />
      <mask id="path-3-inside-1_3250_4250" fill="white">
        <path d="M2 20H42V26H2V20Z" />
      </mask>
      <path d="M2 20H42V26H2V20Z" fill="#006747" />
      <path d="M2 20.5H42V19.5H2V20.5Z" fill="black" mask="url(#path-3-inside-1_3250_4250)" />
      <mask id="path-5-inside-2_3250_4250" fill="white">
        <path d="M2 2H42V8H2V2Z" />
      </mask>
      <path d="M2 2H42V8H2V2Z" fill="#006747" />
      <path d="M42 7.5H2V8.5H42V7.5Z" fill="black" mask="url(#path-5-inside-2_3250_4250)" />
      <path
        d="M19.4257 17.9943L16.4922 19L18.0812 17.6494C17.7247 17.2184 16.687 15.4845 16.687 12.9214C16.687 10.3343 19.497 9.30651 21.0758 8.99999L21.9008 10.954L22.512 8.99999C25.2388 9.58601 26.1943 10.3226 26.8816 12.6494C27.2483 13.8908 26.1177 16.5 25.5065 17.6494L27.0955 19L24.162 17.9943C24.162 17.9943 25.0621 12.0135 21.7314 12.0135C18.7166 12.0135 19.4257 15.9233 19.4257 17.9943Z"
        fill="white"
      />
      <path
        d="M21.8108 15.5341L20.5891 16.3865L21.003 15.0153L19.7812 14.2555H21.3379L21.8108 12.8843L22.2837 14.2555H23.6631L22.5202 15.0153L22.9931 16.3865L21.8108 15.5341Z"
        fill="white"
      />
    </g>
    <rect x="1" y="1" width="42" height="26" rx="3" stroke="#4A4B52" strokeWidth="2" />
    <defs>
      <clipPath id="clip0_3250_4250">
        <rect x="2" y="2" width="40" height="24" rx="2" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
