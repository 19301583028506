import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';

const Avocado = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M32 2.133c-8.294 0-13.866 5.137-13.866 12.784 0 7.453-1.353 10.866-4.383 15.702a1.357 1.357 0 0 1-.062.09c-.31.417-3.022 4.292-3.022 11.957 0 8.644 9.844 19.2 21.333 19.2 11.489 0 21.333-10.556 21.333-19.2 0-7.665-2.712-11.54-3.021-11.957a1.565 1.565 0 0 1-.066-.095c-3.027-4.83-4.38-8.244-4.38-15.696C45.867 7.27 40.296 2.134 32 2.134M32 64C20.157 64 8.533 53.434 8.533 42.666c0-8.176 2.868-12.455 3.442-13.228C14.701 25.087 16 22.214 16 14.918 16 6.135 22.58 0 32 0s16 6.134 16 14.918c0 7.297 1.3 10.17 4.056 14.568.543.725 3.41 5.004 3.41 13.18C55.467 53.433 43.844 64 32 64"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M32 6.4c-5.921 0-9.6 3.264-9.6 8.517 0 8.45-1.62 12.52-5.022 17.948l-.265.388c-.02.026-2.18 3.027-2.18 9.413C14.934 49.249 22.97 57.6 32 57.6c9.032 0 17.067-8.351 17.067-14.934 0-6.386-2.159-9.387-2.181-9.415l-.252-.37c-3.41-5.437-5.034-9.514-5.034-17.964C41.6 9.663 37.922 6.4 32 6.4m0 53.333c-8.463 0-19.2-8.035-19.2-17.067 0-6.98 2.336-10.328 2.601-10.687l.17-.25c3.182-5.073 4.696-8.884 4.696-16.812 0-6.37 4.715-10.65 11.733-10.65 7.019 0 11.734 4.28 11.734 10.65 0 7.929 1.517 11.745 4.708 16.834l.158.229c.265.359 2.6 3.707 2.6 10.687 0 9.031-10.737 17.067-19.2 17.067"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M32 27.734c-2.89 0-5.333 4.395-5.333 9.6 0 5.204 2.442 9.6 5.333 9.6 2.89 0 5.333-4.396 5.333-9.6 0-5.205-2.443-9.6-5.333-9.6Zm0 21.332c-4.187 0-7.467-5.154-7.467-11.732 0-6.58 3.28-11.734 7.467-11.734 4.187 0 7.467 5.154 7.467 11.734 0 6.579-3.28 11.732-7.467 11.732Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M29.866 38.4a1.067 1.067 0 0 1-1.067-1.066c0-3.967 1.559-6.653 2.667-7.293a1.065 1.065 0 1 1 1.067 1.846c-.235.17-1.6 1.94-1.6 5.447 0 .59-.478 1.066-1.067 1.066Z"
      clipRule="evenodd"
    />
  </svg>
);
export { Avocado };
