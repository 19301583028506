import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Stethoscope = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={65} height={64} fill="none" {...props}>
    <g fill={colors.ultramarine900} clipPath="url(#stethoscope_svg__a)">
      <path d="M54.987 22.4a9.594 9.594 0 0 0-3.2 18.64v3.76c-.007 7.066-5.734 12.792-12.8 12.8h-2.133c-7.066-.008-12.793-5.734-12.8-12.8v-5.344a12.776 12.776 0 0 0 12.36-10.565L40.22 7.327a5.333 5.333 0 0 0-5.252-6.26H31.52C31.52.477 31.043 0 30.454 0H28.32a4.267 4.267 0 0 0 0 8.533h2.134c.589 0 1.066-.477 1.066-1.066h2.178l-3.585 20.311a6.392 6.392 0 0 1-6.303 5.289h-5.912a6.392 6.392 0 0 1-6.303-5.289L8.011 7.467h2.176c0 .589.478 1.066 1.067 1.066h2.133a4.267 4.267 0 0 0 0-8.533h-2.133c-.59 0-1.067.478-1.067 1.067H6.74a5.333 5.333 0 0 0-5.253 6.26L5.293 28.89a12.775 12.775 0 0 0 12.36 10.565V44.8c.013 10.599 8.602 19.188 19.2 19.2h2.134c10.599-.012 19.188-8.601 19.2-19.2v-3.76a9.594 9.594 0 0 0-3.2-18.64ZM28.32 6.4a2.133 2.133 0 0 1 0-4.267h1.067V6.4H28.32ZM13.387 2.133a2.133 2.133 0 0 1 0 4.267H12.32V2.133h1.067ZM7.394 28.52 3.587 6.957A3.2 3.2 0 0 1 6.74 3.2h3.447v2.133H8.011A2.133 2.133 0 0 0 5.91 7.838l3.586 20.311a8.517 8.517 0 0 0 8.402 7.051h5.912a8.517 8.517 0 0 0 8.402-7.05l3.586-20.312a2.134 2.134 0 0 0-2.102-2.505h-2.175V3.2h3.447a3.2 3.2 0 0 1 3.152 3.756L34.314 28.52a10.653 10.653 0 0 1-10.504 8.813h-5.912A10.653 10.653 0 0 1 7.394 28.52Zm48.66 16.28c-.011 9.421-7.646 17.056-17.067 17.067h-2.133c-9.421-.011-17.056-7.646-17.067-17.067v-5.333h2.134V44.8c.008 8.244 6.69 14.925 14.933 14.933h2.133c8.244-.008 14.925-6.69 14.933-14.933v-3.263a9.094 9.094 0 0 0 2.134 0V44.8Zm-1.067-5.333A7.467 7.467 0 1 1 62.454 32a7.475 7.475 0 0 1-7.467 7.467Z" />
      <path d="M54.987 26.667A5.333 5.333 0 1 0 60.321 32a5.34 5.34 0 0 0-5.334-5.333Zm0 8.533a3.2 3.2 0 1 1 0-6.4 3.2 3.2 0 0 1 0 6.4Z" />
    </g>
    <defs>
      <clipPath id="stethoscope_svg__a">
        <path fill={colors.white} d="M.997 0h64v64h-64z" />
      </clipPath>
    </defs>
  </svg>
);
export { Stethoscope };
