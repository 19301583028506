import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Tape = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <g fill={colors.ultramarine900} clipPath="url(#tape_svg__a)">
      <path d="M40.87.654c-3.988-.472-8-.689-12.015-.65-14.354 0-25.598 5.622-25.598 12.8v17.065c0 5.741 7.235 10.577 17.892 12.207a505.036 505.036 0 0 1-9.446 6.648 2.127 2.127 0 0 0-.118 3.42L25.817 63.53a2.146 2.146 0 0 0 2.6.06 151.57 151.57 0 0 0 6.974-5.41 145.95 145.95 0 0 0 6.4-5.584c.01-.012.025-.015.035-.027l.007-.013a136.81 136.81 0 0 0 5.807-5.691c.022-.021.052-.03.072-.054.02-.025.017-.036.03-.052a123.962 123.962 0 0 0 3.934-4.278c3.554-4.076 7.976-9.148 8.818-15.395.86-6.372-.327-15.814-6.5-21.456C50.14 2.094 45.198 1.207 40.87.654ZM28.854 2.137c12.72 0 23.465 4.885 23.465 10.666 0 6.913-13.759 10.666-23.465 10.666-12.72 0-23.465-4.885-23.465-10.666 0-5.78 10.746-10.666 23.465-10.666ZM5.39 29.87V18.002c3.914 4.515 12.834 7.6 23.465 7.6 5.608.091 11.18-.896 16.416-2.906a219.64 219.64 0 0 1-20.682 16.871v-5.432a1.067 1.067 0 0 0-2.133 0v5.973c-1.8-.235-3.581-.59-5.334-1.06v-5.98a1.067 1.067 0 0 0-2.133 0v5.334a25.948 25.948 0 0 1-4.266-1.87v-6.663a1.067 1.067 0 0 0-2.133 0v5.26c-2.027-1.582-3.2-3.388-3.2-5.26Zm21.758 31.998L12.911 50.483a549.652 549.652 0 0 0 5.174-3.6c.938.601 2.063.84 3.164.673a2.498 2.498 0 0 1 3.005 2.164 4.627 4.627 0 0 0 5.24 3.787 2.518 2.518 0 0 1 3.005 2.173c.12.566.322 1.11.602 1.616a151.811 151.811 0 0 1-5.953 4.57Zm31.242-35.07a16.36 16.36 0 0 1-1.508 4.853l-1.517-2.133a1.067 1.067 0 0 0-1.738 1.237l2.123 2.986a40.28 40.28 0 0 1-3.43 4.72l-2.968-3.395a1.067 1.067 0 1 0-1.607 1.404l3.176 3.639a119.822 119.822 0 0 1-4.093 4.53l-4.48-3.84a1.066 1.066 0 1 0-1.386 1.62l4.391 3.767a134.552 134.552 0 0 1-4.366 4.256l-5.04-4.317a1.067 1.067 0 0 0-1.386 1.62l4.853 4.154a140.868 140.868 0 0 1-4.622 4 6.21 6.21 0 0 1-.223-.74 4.616 4.616 0 0 0-5.233-3.778 2.53 2.53 0 0 1-3.014-2.184 4.595 4.595 0 0 0-5.233-3.768c-.319.035-.64.036-.96.006 12.138-8.64 26.636-19.796 32.105-27.335a8.14 8.14 0 0 0 2.22-5.18c.055-3.353-2.3-6.013-5.109-7.878A13.71 13.71 0 0 1 52.57 7.2c5.202 4.76 6.672 13.271 5.82 19.596Z" />
      <path d="M28.822 19.203c4.363 0 11.646-1.252 13.522-5.205 1.274-2.683-.937-5.44-3.429-7.123a24.368 24.368 0 0 0-7.655-3.18 2.892 2.892 0 0 0-2.405.46c-.425.305-.76.72-.972 1.198-7.407.242-12.894 3.15-12.894 6.917 0 5.03 8.4 6.933 13.833 6.933Zm7.832-3.413.382-7.58c.237.143.466.288.688.437 4.006 2.7 3.922 5.271-1.07 7.143Zm-6.886-9.298v-.02a.8.8 0 0 1 .33-.588.746.746 0 0 1 .634-.123c1.44.357 2.852.825 4.22 1.4l-.466 9.286a24.09 24.09 0 0 1-5.478.623l.76-10.578Zm-2.21 1.01-.682 9.492c-5.878-.424-9.754-2.707-9.754-4.724 0-2.108 4.23-4.469 10.436-4.769Z" />
      <path d="M32.055 10.67c-.59 0-1.067.478-1.067 1.067v1.066a1.067 1.067 0 0 0 2.133 0v-1.066c0-.59-.477-1.067-1.066-1.067ZM20.989 51.434a1.067 1.067 0 1 0-1.333 1.666l5.333 4.267a1.067 1.067 0 0 0 1.333-1.666l-5.333-4.267Z" />
    </g>
    <defs>
      <clipPath id="tape_svg__a">
        <path fill={colors.white} d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
);
export { Tape };
