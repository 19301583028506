import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Cycle = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <g clipPath="url(#cycle_svg__a)">
      <g fill={colors.ultramarine900} fillRule="evenodd" clipPath="url(#cycle_svg__b)" clipRule="evenodd">
        <path d="M32 2.133C15.505 2.133 2.133 15.505 2.133 32S15.505 61.867 32 61.867 61.867 48.495 61.867 32 48.495 2.133 32 2.133ZM0 32C0 14.327 14.327 0 32 0c17.673 0 32 14.327 32 32 0 17.673-14.327 32-32 32C14.327 64 0 49.673 0 32Z" />
        <path d="M32 12.8c-10.604 0-19.2 8.596-19.2 19.2 0 10.603 8.595 19.2 19.199 19.2 10.6-.012 19.189-8.602 19.2-19.201C51.2 21.395 42.604 12.8 32 12.8ZM10.666 32c0-11.782 9.552-21.333 21.334-21.333S53.333 20.217 53.333 32v.001c-.013 11.776-9.556 21.32-21.332 21.332H32c-11.782 0-21.334-9.55-21.334-21.333Z" />
        <path d="m9.369 53.123 7.552-7.552 1.508 1.509-7.552 7.552-1.508-1.509ZM33.066 1.067v10.666h-2.133V1.067h2.133ZM45.571 16.92l7.552-7.552 1.509 1.509-7.552 7.552-1.509-1.509ZM47.08 45.571l7.552 7.552-1.509 1.509-7.552-7.552 1.509-1.509Z" />
      </g>
    </g>
    <defs>
      <clipPath id="cycle_svg__a">
        <path fill={colors.white} d="M0 0h64v64H0z" />
      </clipPath>
      <clipPath id="cycle_svg__b">
        <path fill={colors.white} d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
);
export { Cycle };
