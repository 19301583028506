import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Chips = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="m2.488 29.856 5.163 17.701c.99 3.394 4.15 5.765 7.687 5.765H48.66c3.538 0 6.698-2.37 7.688-5.765l5.164-17.701H2.488Zm46.171 25.6H15.34c-4.479 0-8.482-3.003-9.736-7.303L.043 29.088a1.067 1.067 0 0 1 1.024-1.366h61.866a1.067 1.067 0 0 1 1.024 1.366l-5.561 19.065c-1.255 4.3-5.258 7.303-9.735 7.303h-.002Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M24.533 49.056h-8.128a4.832 4.832 0 0 1-4.616-3.46L9.636 37.6a1.068 1.068 0 0 1 2.06-.555l2.148 7.975c.323 1.111 1.379 1.902 2.56 1.902h8.129a1.066 1.066 0 1 1 0 2.134ZM6.188 27.723h14.207c-3.592-4.106-12.231-6.374-15.798-7.161l1.591 7.16ZM22.4 29.856H5.332c-.5 0-.932-.348-1.04-.835l-2.134-9.6c-.078-.347.023-.71.27-.968.245-.258.597-.376.953-.314.667.115 16.376 2.908 19.975 10.177a1.068 1.068 0 0 1-.957 1.54ZM40.685 29.338l-11.046-18.41a.504.504 0 0 0-.495-.247.505.505 0 0 0-.435.346L25.117 21.58l-2.018-.688 3.592-10.554a2.622 2.622 0 0 1 2.226-1.78 2.618 2.618 0 0 1 2.55 1.272L42.515 28.24l-1.83 1.098ZM59.723 28.94l-2.112-.302 1.672-11.706-10.245 10.47-1.524-1.492 12.523-12.8a1.067 1.067 0 0 1 1.82.897l-2.134 14.934Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="m14.49 22.4-2.072-.507 2.545-10.424a1.069 1.069 0 0 1 1.929-.332l5.973 9.087-1.784 1.173-4.626-7.041-1.964 8.044Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M21.444 29.263c-.539-1.089-1.507-2.155-2.878-3.17a1.068 1.068 0 0 1-.433-.858v-4.979a1.067 1.067 0 0 1 1.277-1.045l10.666 2.133c.499.1.857.538.857 1.046v6.4H28.8v-5.526l-8.533-1.706v3.15c1.421 1.127 2.46 2.339 3.09 3.608l-1.913.947ZM47.85 29.255l-7.648-15.768-4.829 4.496-1.454-1.561 5.887-5.479a1.06 1.06 0 0 1 .922-.267c.334.061.617.278.764.582l8.276 17.067-1.919.93Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M56.826 18.534c-3.415-1.278-8.472-2.37-13.186-.308l-.854-1.954c5.364-2.348 11.004-1.151 14.788.264l-.748 1.998Z"
      clipRule="evenodd"
    />
  </svg>
);
export { Chips };
