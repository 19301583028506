import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const FruitBowl = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <g fill={colors.ultramarine900} fillRule="evenodd" clipPath="url(#fruit-bowl_svg__a)" clipRule="evenodd">
      <path d="M22.816 61.756h18.262v-2.283H22.816v2.283Zm18.405 2.283H22.626c-1.154 0-2.093-.95-2.093-2.116v-3.591c0-.63.512-1.142 1.142-1.142H42.22c.63 0 1.141.512 1.141 1.142v3.591c0 1.166-.961 2.116-2.14 2.116ZM22.799 38.928H7.955c-.062 0-.122-.005-.183-.015-3.77-.61-4.515-4.784-4.38-6.905-.003-2.28 3.611-6.969 6-9.038 1.16-1.003 1.993-2.467 2.412-4.233 1.352-5.702 5.514-7.203 7.548-7.203h.023c2.538 0 4.4 1.282 5.236 3.609.523 1.453.51 3.106-.034 4.778-.404 1.237-1.195 4.265.17 5.65a1.142 1.142 0 1 1-1.626 1.602c-2.163-2.195-1.377-5.929-.715-7.96.27-.827.5-2.07.057-3.298-.515-1.431-1.497-2.098-3.094-2.098-.163 0-4.078.11-5.344 5.447-.53 2.234-1.615 4.112-3.138 5.43-2.467 2.138-5.215 6.227-5.215 7.385-.013.233-.202 4.067 2.382 4.566h14.744a1.142 1.142 0 0 1 0 2.283M42.493 36.645h13.105a7.884 7.884 0 0 0 1.438-4.566c0-4.405-3.585-7.99-7.992-7.99-4.405 0-7.99 3.585-7.99 7.99a7.88 7.88 0 0 0 1.44 4.566m13.673 2.283H41.924c-.346 0-.674-.158-.89-.427a10.3 10.3 0 0 1-2.261-6.422c0-5.663 4.608-10.273 10.272-10.273 5.665 0 10.274 4.61 10.274 10.273 0 2.324-.802 4.605-2.262 6.422a1.14 1.14 0 0 1-.89.427" />
      <path d="M22.8 38.928c-.41 0-.808-.223-1.012-.61-.847-1.618-1.277-3.332-1.277-5.097 0-6.923 6.655-12.556 14.838-12.556 3.41 0 6.74 1.002 9.376 2.82a1.14 1.14 0 1 1-1.297 1.88c-2.258-1.558-5.127-2.416-8.079-2.416-6.924 0-12.555 4.608-12.555 10.272 0 1.391.34 2.75 1.017 4.035a1.143 1.143 0 0 1-1.01 1.672" />
      <path d="M4.564 38.928c.593 10.17 9.054 18.262 19.37 18.262h15.98c10.316 0 18.776-8.092 19.37-18.262H4.564Zm35.35 20.545h-15.98c-11.958 0-21.687-9.728-21.687-21.686 0-.63.51-1.141 1.141-1.141h57.07a1.14 1.14 0 0 1 1.142 1.141c0 11.959-9.728 21.686-21.687 21.686Z" />
      <path d="M23.934 53.766a15.952 15.952 0 0 1-14.749-9.832 1.141 1.141 0 0 1 2.105-.881 13.68 13.68 0 0 0 12.645 8.43 1.142 1.142 0 0 1 0 2.283M23.858 2.478a7.055 7.055 0 0 0-5.474 5.475 7.055 7.055 0 0 0 5.474-5.475Zm-6.773 7.915a1.141 1.141 0 0 1-1.14-1.174c.143-5.003 4.176-9.036 9.179-9.18a1.14 1.14 0 0 1 1.173 1.173c-.144 5.004-4.175 9.036-9.18 9.18l-.032.001Z" />
      <path d="M19.368 16.1c-.63 0-1.14-.511-1.14-1.141a5.713 5.713 0 0 0-5.708-5.707 1.142 1.142 0 0 1 0-2.283c4.406 0 7.99 3.584 7.99 7.99 0 .63-.512 1.141-1.142 1.141ZM49.066 29.837c-1.839 0-3.378-.782-4.177-1.447a1.142 1.142 0 0 1 1.458-1.757c.12.097 2.608 2.047 5.445-.038a1.14 1.14 0 1 1 1.354 1.838c-1.407 1.033-2.815 1.404-4.08 1.404Z" />
      <path d="M49.044 29.796a1.14 1.14 0 0 1-1.137-1.053c-.032-.411-.709-10.093 5.27-12.556a1.142 1.142 0 0 1 .868 2.109c-4.389 1.81-3.87 10.187-3.862 10.271a1.141 1.141 0 0 1-1.14 1.23M28.5 29.796a1.142 1.142 0 1 1-2.284 0 1.142 1.142 0 0 1 2.284 0ZM29.64 33.22a1.142 1.142 0 1 1-2.283 0 1.142 1.142 0 0 1 2.284 0ZM31.924 30.937a1.141 1.141 0 1 1-2.283 0 1.141 1.141 0 0 1 2.283 0Z" />
    </g>
    <defs>
      <clipPath id="fruit-bowl_svg__a">
        <path fill={colors.white} d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
);
export { FruitBowl };
