import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Tampon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <g fill={colors.ultramarine900} fillRule="evenodd" clipPath="url(#tampon_svg__a)" clipRule="evenodd">
      <path d="M57.599 2.133c-1.132 0-2.217.45-3.017 1.25L20.685 37.258l6.034 6.034L60.615 9.418a4.267 4.267 0 0 0 0-6.035c-.8-.8-1.885-1.25-3.017-1.25Zm-4.526-.259a6.4 6.4 0 1 1 9.052 9.052L27.472 45.555a1.067 1.067 0 0 1-1.508 0l-7.542-7.542a1.066 1.066 0 0 1 0-1.509l34.65-34.628Z" />
      <path d="M51.668 10.82a1.067 1.067 0 0 1 0 1.508l-1.978 1.976a1.066 1.066 0 1 1-1.508-1.508l1.977-1.977a1.067 1.067 0 0 1 1.509 0ZM45.736 16.747a1.067 1.067 0 0 1 0 1.508l-7.909 7.903a1.067 1.067 0 1 1-1.507-1.509l7.908-7.902a1.067 1.067 0 0 1 1.508 0ZM62.125 1.874a1.067 1.067 0 0 1 0 1.509L59.108 6.4a1.067 1.067 0 0 1-1.509-1.508l3.018-3.018a1.067 1.067 0 0 1 1.508 0ZM21.44 33.487a1.067 1.067 0 0 1 1.51 0l7.542 7.542a1.067 1.067 0 0 1-1.509 1.509l-7.542-7.543a1.067 1.067 0 0 1 0-1.508ZM24.457 30.47a1.067 1.067 0 0 1 1.509 0l6.788 6.787a1.067 1.067 0 0 1-1.508 1.509l-6.789-6.788a1.067 1.067 0 0 1 0-1.509ZM7.983 41.6a6.696 6.696 0 0 0-4.226 1.417l-.017.013a4.014 4.014 0 0 0-1.607 3.114C2.141 48.44 4.546 50.666 8 50.666h13.866c4.19 0 8 2.783 8 6.667v.023a6.147 6.147 0 0 1-2.453 4.782 8.83 8.83 0 0 1-5.555 1.861h-6.925a1.067 1.067 0 0 1 0-2.133h6.95a6.696 6.696 0 0 0 4.226-1.417l.017-.013a4.014 4.014 0 0 0 1.607-3.114c-.008-2.299-2.412-4.522-5.867-4.522H8c-4.19 0-8-2.787-8-6.667v-.023a6.147 6.147 0 0 1 2.453-4.782 8.83 8.83 0 0 1 5.555-1.861h13.858a1.067 1.067 0 0 1 0 2.133H7.983Z" />
    </g>
    <defs>
      <clipPath id="tampon_svg__a">
        <path fill={colors.white} d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
);
export { Tampon };
