import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Bowl = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M23.556 57.6h15.735l-1.066-2.134H24.612L23.556 57.6Zm17.461 2.133H21.84a1.068 1.068 0 0 1-.957-1.539l2.112-4.267c.18-.363.55-.594.956-.594h14.932c.405 0 .774.228.955.59l2.133 4.267a1.067 1.067 0 0 1-.954 1.543Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M3.715 36.266c.554 9.504 8.461 17.068 18.101 17.068h20.267c9.64 0 17.547-7.564 18.102-17.068H3.715Zm38.369 19.2H21.818C10.642 55.466 1.55 46.375 1.55 35.2c0-.59.477-1.067 1.067-1.067h58.666c.589 0 1.067.478 1.067 1.067 0 11.175-9.092 20.266-20.267 20.266Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M23.95 50.133c-6.04 0-11.45-3.606-13.785-9.188a1.068 1.068 0 0 1 1.969-.824 12.782 12.782 0 0 0 11.816 7.88 1.066 1.066 0 1 1 0 2.133M19.683 59.733c-.588 0-1.067.48-1.067 1.067 0 .588.479 1.067 1.067 1.067H43.15a1.068 1.068 0 0 0 0-2.133H19.683ZM43.15 64H19.683a3.204 3.204 0 0 1-3.2-3.2c0-1.764 1.436-3.2 3.2-3.2H43.15c1.764 0 3.2 1.436 3.2 3.2 0 1.764-1.436 3.2-3.2 3.2ZM6.022 19.79l2.026 4.684 3.917-1.692-1.072-2.48-5.328-1.57.45 1.04.008.019m1.472 7.154c-.414 0-.807-.242-.98-.644l-2.413-5.579a1.068 1.068 0 0 1-.081-.188L2.704 17.49a1.066 1.066 0 0 1 1.281-1.446l7.99 2.355c.304.09.552.31.678.6l1.694 3.916a1.065 1.065 0 0 1-.557 1.403l-5.874 2.54c-.138.06-.28.088-.421.088"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M10.43 25.674c-.412 0-.805-.241-.979-.644L6.7 18.667a1.066 1.066 0 1 1 1.958-.847l2.753 6.364a1.066 1.066 0 0 1-.98 1.49M53.839 24.51a1.066 1.066 0 0 1-.754-1.82l7.542-7.543a1.066 1.066 0 1 1 1.509 1.509l-7.543 7.542a1.066 1.066 0 0 1-.754.313Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M59.119 23.756c-.07 0-.14-.006-.21-.02l-3.77-.755a1.068 1.068 0 0 1 .417-2.092l3.77.755a1.065 1.065 0 0 1-.207 2.112ZM58.363 19.986c-.59 0-1.066-.478-1.066-1.067v-3.017a1.066 1.066 0 1 1 2.133 0v3.017c0 .589-.476 1.067-1.067 1.067Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M61.382 19.986h-3.018a1.066 1.066 0 1 1 0-2.134h3.018a1.066 1.066 0 1 1 0 2.134ZM55.347 23.002c-.59 0-1.066-.478-1.066-1.066v-3.017a1.066 1.066 0 1 1 2.133 0v3.017c0 .588-.477 1.066-1.067 1.066ZM49.313 27.528c-.273 0-.546-.105-.754-.313l-2.118-2.118a1.065 1.065 0 1 1 1.508-1.508l2.118 2.118a1.065 1.065 0 0 1-.754 1.82Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M41.2 34.133h4.803c1.23-.875 2.583-1.874 4.022-2.97l4.016-3.405c.248-.202.375-.49.393-.868.04-.788-.399-1.74-1.146-2.487l-.41-.41c-.746-.745-1.667-1.175-2.486-1.145-.377.019-.666.147-.855.379L46.085 27.3c-2.046 2.69-3.687 4.986-4.885 6.833Zm5.142 2.134h-7.057a1.067 1.067 0 0 1-.92-1.606c1.246-2.127 3.283-5.053 6.057-8.696l3.474-4.102c.558-.684 1.41-1.096 2.389-1.145 1.4-.073 2.926.59 4.102 1.767l.41.411c1.178 1.176 1.839 2.71 1.768 4.101-.05.98-.461 1.832-1.161 2.403l-4.043 3.425a148.33 148.33 0 0 1-4.403 3.246c-.18.127-.395.196-.616.196ZM8.863 34.133h13.816a7.373 7.373 0 0 0-2.795-3.128c-2.955-1.833-4.997-3.438-6.926-6.328L8.78 26.484c.598 2.694.461 4.93.083 7.65Zm16.701 2.133H6.39l.183-1.225c.523-3.45.778-5.77-.075-8.897l.992-.27-.424-.973 1.132-.496.001.006 5.596-2.417.482.785c1.87 3.05 3.734 4.553 6.733 6.412a9.53 9.53 0 0 1 3.736 4.334c.183.421.332.869.472 1.407l.346 1.334Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M57.017 35.2h-2.133a3.192 3.192 0 0 0-3.025-3.178l.116-2.131c2.828.156 5.042 2.487 5.042 5.309ZM25.131 34.133h12.57l-1.308-12.738c-.328-2.454-2.468-4.328-4.976-4.328a5.04 5.04 0 0 0-4.982 4.36l-1.304 12.706Zm14.935 2.133H22.77l1.548-15.089c.477-3.573 3.526-6.244 7.1-6.244 3.573 0 6.623 2.67 7.095 6.212l1.554 15.122Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="m16.101 26.788-1.508-1.508 5.037-5.036a1.066 1.066 0 0 1 1.508 0l4.556 4.556-1.508 1.508-3.802-3.801-4.283 4.281ZM45.066 26.005a3.725 3.725 0 0 0-3.516-2.54c-1.15 0-2.224.536-2.943 1.469l-1.689-1.304c1.126-1.46 2.815-2.298 4.633-2.298 2.5 0 4.724 1.603 5.534 3.986l-2.019.687ZM29.283 23.466h-3.2a1.066 1.066 0 1 1 0-2.133h3.2a1.066 1.066 0 1 1 0 2.133ZM37.817 27.733H33.55a1.067 1.067 0 0 1 0-2.133h4.267a1.066 1.066 0 1 1 0 2.133ZM29.283 32h-4.267a1.066 1.066 0 1 1 0-2.134h4.267a1.067 1.067 0 0 1 0 2.134Z"
      clipRule="evenodd"
    />
  </svg>
);
export { Bowl };
