import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Battery2 = (props: SVGProps<SVGSVGElement>) => (
  <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M58.7014 10.8074C59.0919 11.1978 59.7249 11.1978 60.1154 10.8074C61.2915 9.63056 61.2915 7.72331 60.1154 6.54643L54.4204 0.853431C53.228 -0.284477 51.3518 -0.284477 50.1594 0.853431L48.0194 2.99343L46.5734 1.54743C44.6208 -0.405146 41.455 -0.405146 39.5024 1.54743L2.4604 38.5894C0.511247 40.5431 0.511247 43.7058 2.4604 45.6594L15.3074 58.5064C17.2599 60.4583 20.4249 60.4583 22.3774 58.5064L59.4194 21.4654C61.372 19.5128 61.372 16.347 59.4194 14.3944L57.2684 12.2424L49.4344 4.40643L51.5734 2.26543C51.9697 1.87121 52.6101 1.87121 53.0064 2.26543L58.7014 7.96543C59.0962 8.36153 59.0962 9.00233 58.7014 9.39843C58.3143 9.78835 58.3143 10.4175 58.7014 10.8074ZM58.0014 20.0494L20.9634 57.0914C19.7919 58.2626 17.8929 58.2626 16.7214 57.0914L3.8754 44.2454C2.70426 43.0739 2.70426 41.1749 3.8754 40.0034L40.9174 2.96543C42.0889 1.79428 43.9879 1.79428 45.1594 2.96543L58.0014 15.8074C59.1725 16.9789 59.1725 18.8779 58.0014 20.0494Z"
      fill={colors.ultramarine900}
    />
    <path
      d="M14.5633 35.0073C13.7811 34.2287 12.5166 34.2287 11.7343 35.0073L6.0343 40.7073C5.25354 41.4883 5.25354 42.7543 6.0343 43.5353L17.4343 54.9354C18.2153 55.7161 19.4813 55.7161 20.2623 54.9354L25.9623 49.2353C26.7431 48.4543 26.7431 47.1884 25.9623 46.4074L14.5633 35.0073ZM18.8433 53.5213L7.4433 42.1213L13.1433 36.4214L24.5433 47.8213L18.8433 53.5213Z"
      fill={colors.ultramarine900}
    />
    <path
      d="M24.5267 25.0455C23.7457 24.2648 22.4797 24.2648 21.6987 25.0455L15.9987 30.7455C15.2179 31.5265 15.2179 32.7925 15.9987 33.5735L27.3987 44.9735C28.1797 45.7543 29.4457 45.7543 30.2267 44.9735L35.9267 39.2735C36.7074 38.4925 36.7074 37.2265 35.9267 36.4455L24.5267 25.0455ZM28.8017 43.5575L17.4017 32.1575L23.1017 26.4575L34.5017 37.8575L28.8017 43.5575Z"
      fill={colors.ultramarine900}
    />
  </svg>
);
export { Battery2 };
