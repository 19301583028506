import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Bike = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <g fill={colors.ultramarine900} clipPath="url(#bike_svg__a)">
      <path d="M29.136 46.984a1.067 1.067 0 0 0-1.35.674l-.196.587-2.055-.684c.034-.21.056-.42.064-.633a5.4 5.4 0 1 0-.736 2.656l2.053.685-.196.588a1.068 1.068 0 0 0 2.026.674l1.067-3.2a1.066 1.066 0 0 0-.677-1.347Zm-8.87 3.144a3.2 3.2 0 1 1 3.193-3.26l-2.856-.95a1.068 1.068 0 0 0-.674 2.026l2.847.949a3.18 3.18 0 0 1-2.51 1.235Z" />
      <path d="m60.42 56.597-3.52-5.271a13.374 13.374 0 0 0-2.535-18.933 13.587 13.587 0 0 0-2.103-1.274v-3.388a3.2 3.2 0 0 0-2.133-3.004v-6.595h1.066a3.214 3.214 0 0 0 2.479-1.173l9.599-11.732a3.202 3.202 0 1 0-4.959-4.053l-8.638 10.559H38.397a3.2 3.2 0 1 0 0 6.4h5.333v6.595a3.2 3.2 0 0 0-2.133 3.003v2.8l-23.72 6.918-3.705-8.652h4.19a4.046 4.046 0 0 0 .446-8.066L5.32 19.227a4.8 4.8 0 1 0-.53 9.57h2.156l4.72 10.566a9.039 9.039 0 0 0-5.265 8.174c0 1.257.267 2.5.781 3.647l-3.606 5.413a4.266 4.266 0 0 0-3.574 4.196v1.067c0 1.178.955 2.133 2.133 2.133h6.4a2.133 2.133 0 0 0 2.133-2.133v-1.067a4.23 4.23 0 0 0-.676-2.283l1.813-2.733c1.13.494 2.35.748 3.584.747h30.93a13.303 13.303 0 0 0 5.545-1.217l2.133 3.2a4.233 4.233 0 0 0-.669 2.286v1.067c0 1.178.955 2.133 2.133 2.133h6.4a2.133 2.133 0 0 0 2.133-2.133v-1.067a4.266 4.266 0 0 0-3.574-4.196ZM37.33 14.933c0-.59.478-1.067 1.067-1.067h11.784c.32 0 .623-.144.826-.391l8.958-10.95a1.07 1.07 0 1 1 1.655 1.36l-9.593 11.72c-.205.249-.51.393-.832.394H38.397a1.067 1.067 0 0 1-1.066-1.066Zm10.666 3.2v6.398h-2.133v-6.399h2.133Zm-3.2 8.531h4.266c.59 0 1.067.478 1.067 1.067v2.525a13.264 13.264 0 0 0-6.4-.276v-2.25c0-.588.478-1.066 1.067-1.066Zm-42.66-2.666c0-.76.322-1.483.888-1.99a2.606 2.606 0 0 1 2.06-.66l13.489 1.504a1.912 1.912 0 0 1-.214 3.812H4.791a2.666 2.666 0 0 1-2.656-2.666Zm9.715 4.8 3.963 9.253-2.12.618-4.413-9.872h2.57ZM2.135 61.86v-1.067c0-1.178.955-2.133 2.133-2.133h2.133c1.178 0 2.133.955 2.133 2.133v1.067H2.135Zm6.268-4.81a4.2 4.2 0 0 0-2.002-.523h-.213l2.224-3.338A9.036 9.036 0 0 0 9.97 54.69l-1.568 2.36Zm6.988-2.656a6.857 6.857 0 0 1-1.92-13.438l29.692-8.66A11.276 11.276 0 0 1 57.594 43.12c-.007 6.223-5.05 11.267-11.274 11.274H15.391Zm40.022-1.45 2.395 3.583h-.214a4.22 4.22 0 0 0-2.002.523l-1.85-2.773c.592-.4 1.15-.845 1.672-1.334Zm6.448 8.916h-6.4v-1.067c0-1.178.956-2.133 2.133-2.133h2.134c1.178 0 2.133.955 2.133 2.133v1.067Z" />
      <path d="M45.863 35.197a8.532 8.532 0 1 0 8.532 8.532 8.542 8.542 0 0 0-8.532-8.532Zm0 14.93a6.4 6.4 0 1 1 6.399-6.398 6.406 6.406 0 0 1-6.4 6.399Z" />
      <path d="m48.473 43.84-1.544-.77v-2.54a1.067 1.067 0 0 0-2.133 0v3.199c-.002.406.227.778.59.96l2.133 1.066a1.067 1.067 0 0 0 .954-1.909v-.005Z" />
    </g>
    <defs>
      <clipPath id="bike_svg__a">
        <path fill={colors.white} d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
);
export { Bike };
