import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Target = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <g clipPath="url(#target_svg__a)">
      <path
        fill={colors.ultramarine900}
        d="M63.687 9.288a1.067 1.067 0 0 0-.754-1.821h-4.891l2.445-2.446a1.067 1.067 0 1 0-1.508-1.508l-2.446 2.445V1.067a1.067 1.067 0 0 0-1.82-.754l-5.042 5.04A31.656 31.656 0 0 0 32 0C14.327 0 0 14.327 0 32c0 17.673 14.327 32 32 32 17.673 0 32-14.327 32-32a31.657 31.657 0 0 0-5.354-17.671l5.041-5.041Zm-3.329.312-5.333 5.333h-4.45L55.908 9.6h4.45Zm-13.425 5.958-2.153 2.154c-7.79-6.946-19.694-6.43-26.853 1.165-7.16 7.595-6.973 19.508.42 26.875 7.393 7.367 19.307 7.51 26.876.325 7.57-7.186 8.044-19.091 1.07-26.857l2.149-2.153h1.624c8.01 9.683 7 23.95-2.295 32.408-9.295 8.458-23.595 8.12-32.48-.765-8.887-8.886-9.224-23.186-.766-32.481 8.457-9.295 22.725-10.305 32.408-2.295v1.624ZM36.267 32A4.267 4.267 0 1 1 32 27.733a4.21 4.21 0 0 1 2.154.605l-2.908 2.908a1.067 1.067 0 1 0 1.508 1.508l2.908-2.908A4.21 4.21 0 0 1 36.267 32Zm-.565-5.21a6.42 6.42 0 1 0 1.509 1.508l3.043-3.044a10.684 10.684 0 1 1-1.508-1.508l-3.044 3.043Zm4.555-4.555c-5.25-4.442-13.07-3.954-17.727 1.108-4.656 5.06-4.493 12.894.37 17.757 4.863 4.863 12.696 5.026 17.757.37 5.062-4.657 5.55-12.476 1.108-17.727l3.016-3.015c6.122 6.936 5.628 17.48-1.114 23.815-6.742 6.335-17.297 6.17-23.839-.371-6.541-6.542-6.706-17.097-.371-23.839 6.335-6.742 16.879-7.235 23.816-1.114l-3.016 3.016ZM54.4 8.092l-5.333 5.333v-4.45L54.4 3.642v4.45ZM61.867 32c0 16.495-13.372 29.867-29.867 29.867S2.133 48.495 2.133 32 15.505 2.133 32 2.133a29.534 29.534 0 0 1 16.131 4.76l-.885.886c-.2.2-.313.472-.313.754v2.685c-10.837-7.79-25.84-5.958-34.482 4.213-8.643 10.171-8.03 25.273 1.407 34.71 9.438 9.439 24.54 10.051 34.71 1.408 10.172-8.642 12.005-23.645 4.214-34.482h2.685c.282 0 .554-.113.754-.313l.885-.885A29.535 29.535 0 0 1 61.866 32Z"
      />
    </g>
    <defs>
      <clipPath id="target_svg__a">
        <path fill={colors.white} d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
);
export { Target };
