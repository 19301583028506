import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Confused = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M25.455 21.09a5.09 5.09 0 1 1-10.182 0 5.09 5.09 0 0 1 10.182 0ZM48.727 21.09a5.09 5.09 0 1 1-10.182.001 5.09 5.09 0 0 1 10.182 0Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M32 2.91C15.934 2.91 2.91 15.932 2.91 32c0 16.066 13.024 29.09 29.09 29.09 16.066 0 29.09-13.024 29.09-29.09C61.09 15.934 48.067 2.91 32 2.91ZM0 32C0 14.327 14.327 0 32 0c17.673 0 32 14.327 32 32 0 17.673-14.327 32-32 32C14.327 64 0 49.673 0 32Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M40.727 40.225c3.55 0 7.167 1.249 9.756 3.837a1.454 1.454 0 1 1-2.057 2.057c-1.96-1.96-4.795-2.985-7.699-2.985s-5.739 1.026-7.698 2.985c-5.346 5.346-13.201 3.403-18.058-1.454a1.455 1.455 0 0 1 2.058-2.057c4.24 4.24 10.191 5.206 13.942 1.454 2.59-2.588 6.207-3.837 9.756-3.837Z"
      clipRule="evenodd"
    />
  </svg>
);
export { Confused };
