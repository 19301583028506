import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Email = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={65} height={64} fill="none" {...props}>
    <g fill={colors.ultramarine900} fillRule="evenodd" clipPath="url(#email_svg__a)" clipRule="evenodd">
      <path d="M2.064 23.467c.589 0 1.066.477 1.066 1.066V60.8c0 .59.478 1.067 1.067 1.067h57.6c.59 0 1.067-.478 1.067-1.067V24.533a1.067 1.067 0 0 1 2.133 0V60.8a3.2 3.2 0 0 1-3.2 3.2h-57.6a3.2 3.2 0 0 1-3.2-3.2V24.533c0-.589.478-1.066 1.067-1.066ZM33.627 2.352a1.017 1.017 0 0 0-1.26 0l-.006.005-4.918 3.819a1.067 1.067 0 1 1-1.308-1.685L31.049.675a3.15 3.15 0 0 1 3.896 0l4.914 3.816a1.067 1.067 0 1 1-1.308 1.685l-4.924-3.824Z" />
      <path d="M52.422 15.59a1.067 1.067 0 0 1 1.496-.187l10.667 8.288a1.067 1.067 0 1 1-1.309 1.685L52.61 17.088a1.067 1.067 0 0 1-.187-1.497ZM13.573 15.59a1.067 1.067 0 0 1-.188 1.498L2.718 25.376A1.067 1.067 0 0 1 1.41 23.69l10.667-8.288a1.067 1.067 0 0 1 1.497.188ZM33.63 40.2a1.028 1.028 0 0 0-1.266 0l-.002.003L2.718 63.22a1.067 1.067 0 0 1-1.308-1.685L31.05 38.52h.003a3.162 3.162 0 0 1 3.889 0h.001l29.642 23.017a1.067 1.067 0 1 1-1.309 1.685L33.632 40.203l-.002-.002Z" />
      <path d="M1.936 23.88a1.067 1.067 0 0 1 1.496-.19l18.486 14.356a1.067 1.067 0 1 1-1.309 1.685L2.124 25.376a1.067 1.067 0 0 1-.188-1.497ZM64.058 23.88a1.067 1.067 0 0 1-.188 1.496L45.354 39.754a1.067 1.067 0 1 1-1.309-1.684l18.517-14.38a1.067 1.067 0 0 1 1.496.19ZM11.664 7.467a3.2 3.2 0 0 1 3.2-3.2H51.13a3.2 3.2 0 0 1 3.2 3.2v19.2a1.067 1.067 0 1 1-2.133 0v-19.2c0-.59-.478-1.067-1.067-1.067H14.864c-.59 0-1.067.478-1.067 1.067v19.2a1.067 1.067 0 0 1-2.133 0v-19.2Z" />
      <path d="M21.264 12.8c0-.59.477-1.067 1.066-1.067h21.334a1.067 1.067 0 0 1 0 2.134H22.33c-.589 0-1.066-.478-1.066-1.067ZM21.264 19.2c0-.59.477-1.067 1.066-1.067h21.334a1.067 1.067 0 0 1 0 2.134H22.33c-.589 0-1.066-.478-1.066-1.067ZM32.997 25.6c0-.59.478-1.067 1.067-1.067h9.6a1.067 1.067 0 0 1 0 2.134h-9.6c-.59 0-1.067-.478-1.067-1.067Z" />
    </g>
    <defs>
      <clipPath id="email_svg__a">
        <path fill={colors.white} d="M.997 0h64v64h-64z" />
      </clipPath>
    </defs>
  </svg>
);
export { Email };
