import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Sad = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M25.455 21.09a5.09 5.09 0 1 1-10.182 0 5.09 5.09 0 0 1 10.182 0ZM48.727 21.09a5.09 5.09 0 1 1-10.182.001 5.09 5.09 0 0 1 10.182 0Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M32 2.91C15.934 2.91 2.91 15.932 2.91 32c0 16.066 13.024 29.09 29.09 29.09 16.066 0 29.09-13.024 29.09-29.09C61.09 15.934 48.067 2.91 32 2.91ZM0 32C0 14.327 14.327 0 32 0c17.673 0 32 14.327 32 32 0 17.673-14.327 32-32 32C14.327 64 0 49.673 0 32Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M32 36.364c-8.033 0-14.546 6.512-14.546 14.545a1.455 1.455 0 1 1-2.909 0c0-9.64 7.815-17.454 17.455-17.454 9.64 0 17.455 7.814 17.455 17.454a1.455 1.455 0 1 1-2.91 0c0-8.033-6.512-14.545-14.545-14.545Z"
      clipRule="evenodd"
    />
  </svg>
);
export { Sad };
