import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Pad = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      d="M2.928 42.807a5.32 5.32 0 0 0 4.284 1.224c.41-.066.815-.152 1.215-.26L7.961 46.3a15.04 15.04 0 0 0 5.302 14.587 14.954 14.954 0 0 0 18.267 0 15.031 15.031 0 0 0 5.3-14.586l-.465-2.528c.401.107.808.194 1.22.259a5.334 5.334 0 0 0 6.148-5.273V25.241a5.333 5.333 0 0 0-6.145-5.272c-.41.066-.817.152-1.219.26l.466-2.528a15.04 15.04 0 0 0-5.302-14.588 14.952 14.952 0 0 0-18.265 0A15.03 15.03 0 0 0 7.966 17.7l.466 2.527a11.927 11.927 0 0 0-1.22-.259 5.333 5.333 0 0 0-6.145 5.273v13.518c0 1.556.68 3.035 1.86 4.048Zm31.81 3.885a12.88 12.88 0 0 1-4.51 12.508 12.82 12.82 0 0 1-15.655 0 12.885 12.885 0 0 1-4.51-12.508l.702-3.815a11.5 11.5 0 0 0 4.169-3.593v9.782a7.467 7.467 0 1 0 14.933 0v-9.782a11.5 11.5 0 0 0 4.168 3.596l.702 3.812Zm-17.67-15.235V14.934a5.333 5.333 0 1 1 10.666 0v34.132a5.333 5.333 0 0 1-10.667 0V31.457Zm20.846-9.377a3.2 3.2 0 0 1 3.686 3.16v13.52a3.2 3.2 0 0 1-3.686 3.16 9.44 9.44 0 0 1-8.047-9.377v-1.086a9.44 9.44 0 0 1 8.047-9.377Zm-27.85-4.772a12.88 12.88 0 0 1 4.508-12.507 12.821 12.821 0 0 1 15.655 0 12.885 12.885 0 0 1 4.511 12.507l-.703 3.812a11.5 11.5 0 0 0-4.168 3.596v-9.782a7.467 7.467 0 0 0-14.933 0v9.782a11.5 11.5 0 0 0-4.169-3.596l-.702-3.812ZM3.2 25.241a3.2 3.2 0 0 1 3.687-3.164 9.44 9.44 0 0 1 8.046 9.38v1.086a9.44 9.44 0 0 1-8.047 9.377 3.2 3.2 0 0 1-3.686-3.16V25.24ZM54.4 30.933a8.27 8.27 0 0 0 8.533-7.958c0-6.186-4.782-13.497-6.964-15.883a2.197 2.197 0 0 0-3.14 0c-2.18 2.386-6.962 9.697-6.962 15.883a8.27 8.27 0 0 0 8.533 7.958Zm0-22.4c1.706 1.87 6.4 8.766 6.4 14.442a6.429 6.429 0 0 1-12.8 0c0-5.677 4.693-12.572 6.4-14.441Z"
    />
    <path
      fill={colors.ultramarine900}
      d="M54.4 26.667a1.067 1.067 0 0 0 0-2.134c-1.156 0-2.133-.713-2.133-1.558a1.067 1.067 0 0 0-2.134 0 4.015 4.015 0 0 0 4.267 3.692ZM54.4 57.6a8.27 8.27 0 0 0 8.533-7.959c0-6.186-4.782-13.497-6.964-15.883a2.197 2.197 0 0 0-3.14 0c-2.18 2.386-6.962 9.697-6.962 15.883A8.27 8.27 0 0 0 54.4 57.6Zm0-22.4c1.706 1.869 6.4 8.765 6.4 14.441a6.429 6.429 0 0 1-12.8 0c0-5.676 4.693-12.572 6.4-14.44Z"
    />
    <path
      fill={colors.ultramarine900}
      d="M54.4 53.333a1.067 1.067 0 0 0 0-2.133c-1.156 0-2.133-.714-2.133-1.559a1.067 1.067 0 0 0-2.134 0 4.015 4.015 0 0 0 4.267 3.692ZM6.4 29.867h4.267a1.067 1.067 0 0 0 0-2.134H6.401a1.067 1.067 0 1 0 0 2.134ZM6.4 36.267h4.267a1.067 1.067 0 1 0 0-2.134H6.401a1.067 1.067 0 0 0 0 2.133ZM34.133 29.867H38.4a1.067 1.067 0 0 0 0-2.134h-4.267a1.067 1.067 0 1 0 0 2.134ZM34.133 36.267H38.4a1.067 1.067 0 1 0 0-2.134h-4.267a1.067 1.067 0 0 0 0 2.133ZM22.4 49.066c.59 0 1.067-.477 1.067-1.066V16a1.067 1.067 0 0 0-2.133 0v32c0 .589.477 1.066 1.066 1.066Z"
    />
  </svg>
);
export { Pad };
