import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Salt = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      d="M24.001 61.864h-12.8a2.133 2.133 0 0 1-2.133-2.133V22.399c0-1.178.955-2.133 2.133-2.133h11.733a1.067 1.067 0 1 0 0-2.133H12.268V13.79h21.333v4.342h-4.267a1.067 1.067 0 1 0 0 2.133h5.333c1.178 0 2.133.955 2.133 2.133v17.066a1.067 1.067 0 1 0 2.134 0V22.399a4.275 4.275 0 0 0-3.2-4.132v-5.05A6.807 6.807 0 0 0 36.8 9.6C36.8 5.17 33.175 0 22.934 0S9.068 5.171 9.068 9.6a6.808 6.808 0 0 0 1.067 3.618v5.049a4.275 4.275 0 0 0-3.2 4.132v37.332a4.266 4.266 0 0 0 4.266 4.266h12.8a1.067 1.067 0 1 0 0-2.133Zm-1.067-59.73c8.66 0 11.733 4.021 11.733 7.466a5.668 5.668 0 0 1-.454 2.058H11.656A5.672 5.672 0 0 1 11.2 9.6c0-3.445 3.073-7.467 11.733-7.467Z"
    />
    <path
      fill={colors.ultramarine900}
      d="M17.601 9.6a1.067 1.067 0 1 0 0-2.134 1.067 1.067 0 0 0 0 2.134ZM22.934 9.6a1.067 1.067 0 1 0 0-2.134 1.067 1.067 0 0 0 0 2.134ZM28.268 9.6a1.067 1.067 0 1 0 0-2.134 1.067 1.067 0 0 0 0 2.134ZM42.133 51.198a1.067 1.067 0 1 0 0-2.133 1.067 1.067 0 0 0 0 2.133ZM40 55.464a1.067 1.067 0 1 0 0-2.133 1.067 1.067 0 0 0 0 2.133ZM44.267 55.464a1.067 1.067 0 1 0 0-2.133 1.067 1.067 0 0 0 0 2.133ZM36.8 58.664a1.067 1.067 0 1 0 0-2.133 1.067 1.067 0 0 0 0 2.133ZM42.133 58.664a1.067 1.067 0 1 0 0-2.133 1.067 1.067 0 0 0 0 2.133ZM47.467 58.664a1.067 1.067 0 1 0 0-2.133 1.067 1.067 0 0 0 0 2.133ZM20.801 6.4a1.067 1.067 0 1 0 0-2.133 1.067 1.067 0 0 0 0 2.133ZM16.534 6.4a1.067 1.067 0 1 0 0-2.133 1.067 1.067 0 0 0 0 2.133ZM25.068 6.4a1.067 1.067 0 1 0 0-2.133 1.067 1.067 0 0 0 0 2.133ZM29.334 6.4a1.067 1.067 0 1 0 0-2.133 1.067 1.067 0 0 0 0 2.133ZM22.934 31.999c2.94 0 5.333 1.913 5.333 4.266a1.067 1.067 0 0 0 2.134 0c0-3.53-3.35-6.4-7.467-6.4-4.116 0-7.466 2.87-7.466 6.4 0 3.53 3.35 6.4 7.466 6.4 2.94 0 5.333 1.913 5.333 4.266 0 2.353-2.392 4.267-5.333 4.267-2.94 0-5.333-1.914-5.333-4.267a1.067 1.067 0 1 0-2.133 0c0 3.53 3.35 6.4 7.466 6.4s7.466-2.87 7.466-6.4c0-3.529-3.35-6.4-7.466-6.4-2.94 0-5.333-1.913-5.333-4.266 0-2.353 2.393-4.266 5.333-4.266ZM17.601 23.466c0-.59-.477-1.067-1.066-1.067h-4.267c-.589 0-1.066.478-1.066 1.067v4.266a1.067 1.067 0 1 0 2.133 0v-3.2h3.2c.589 0 1.066-.477 1.066-1.066Z"
    />
    <path
      fill={colors.ultramarine900}
      d="M54.978 57.222a17.826 17.826 0 0 0-.66-.314 5.032 5.032 0 0 1-1.457-.865c-.39-.389-.439-.853-.494-1.696a11.465 11.465 0 0 0-.1-1.03 3.461 3.461 0 0 0-1.868-2.672c-.265-.136-.541-.263-.82-.39a5.223 5.223 0 0 1-1.7-1.027 6.959 6.959 0 0 1-1.14-2.125 6.204 6.204 0 0 0-2.967-3.798c-1.698-.801-3.59-.292-5.2 1.397a9.813 9.813 0 0 0-2.084 4.318 8.542 8.542 0 0 1-1.43 3.253 10.627 10.627 0 0 1-2.946 2.133c-2.089 1.186-4.457 2.531-5.013 5.905a3.14 3.14 0 0 0 .71 2.546 3.216 3.216 0 0 0 2.454 1.14h23.4a3.26 3.26 0 0 0 3.307-2.51 3.84 3.84 0 0 0-1.992-4.265Zm-.084 3.768a1.116 1.116 0 0 1-1.23.874H30.263c-.32 0-.624-.141-.83-.386a1 1 0 0 1-.233-.818c.392-2.37 2.049-3.307 3.966-4.399a12.361 12.361 0 0 0 3.52-2.602 10.285 10.285 0 0 0 1.852-4.042 8.067 8.067 0 0 1 1.584-3.442 2.92 2.92 0 0 1 2.011-1.104c.253.001.502.059.73.169.97.458 1.352 1.31 1.899 2.66a8.622 8.622 0 0 0 1.548 2.774 6.977 6.977 0 0 0 2.387 1.525c.247.112.49.223.747.352.405.22.675.629.718 1.088.045.287.063.573.082.854a4.327 4.327 0 0 0 1.114 3.063 6.75 6.75 0 0 0 2.075 1.295c.197.09.394.181.586.278.68.35 1.04 1.114.876 1.861Z"
    />
  </svg>
);
export { Salt };
