import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Stopwatch = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={65} height={64} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M32.99 7.467C19.463 7.467 8.457 18.47 8.457 32c0 13.529 11.006 24.533 24.533 24.533 13.528 0 24.534-11.005 24.534-24.533 0-13.529-11.006-24.533-24.534-24.533Zm0 51.2C18.286 58.667 6.324 46.704 6.324 32c0-14.704 11.962-26.667 26.666-26.667S59.657 17.296 59.657 32c0 14.704-11.963 26.667-26.667 26.667Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M32.99 12.8c-10.586 0-19.2 8.613-19.2 19.2s8.614 19.2 19.2 19.2c10.587 0 19.2-8.613 19.2-19.2s-8.613-19.2-19.2-19.2Zm0 40.533c-11.763 0-21.333-9.57-21.333-21.333 0-11.763 9.57-21.333 21.333-21.333 11.764 0 21.334 9.57 21.334 21.333 0 11.763-9.57 21.333-21.334 21.333ZM15.848 8.2c-.273 0-.547.103-.755.311l-4.525 4.525c-.201.202-.312.47-.312.754 0 .286.11.554.312.754l1.25 1.25a27.072 27.072 0 0 1 6.203-5.864l-1.419-1.419a1.062 1.062 0 0 0-.754-.311Zm-3.903 10.296c-.281 0-.553-.11-.754-.312l-2.13-2.131a3.174 3.174 0 0 1-.938-2.263c0-.854.332-1.657.936-2.262l4.526-4.525a3.203 3.203 0 0 1 4.526 0l2.353 2.353a1.068 1.068 0 0 1-.2 1.666 24.799 24.799 0 0 0-7.442 7.017 1.065 1.065 0 0 1-.877.457ZM41.523 8.93a1.066 1.066 0 0 1-1.066-1.068V3.2c0-.589-.48-1.067-1.067-1.067h-12.8c-.588 0-1.067.478-1.067 1.067v4.662a1.066 1.066 0 1 1-2.133 0V3.2c0-1.764 1.436-3.2 3.2-3.2h12.8c1.764 0 3.2 1.436 3.2 3.2v4.662c0 .59-.478 1.067-1.067 1.067ZM47.96 9.93a27.048 27.048 0 0 1 6.202 5.865l1.25-1.25c.203-.2.312-.469.312-.755 0-.283-.11-.552-.312-.754l-4.525-4.524a1.064 1.064 0 0 0-1.508 0L47.96 9.93Zm6.076 8.567a1.066 1.066 0 0 1-.877-.458 24.829 24.829 0 0 0-7.442-7.018 1.063 1.063 0 0 1-.505-.784c-.04-.325.073-.65.304-.88l2.355-2.354a3.201 3.201 0 0 1 4.524 0l4.526 4.525a3.18 3.18 0 0 1 .937 2.262c0 .856-.333 1.66-.937 2.263l-2.131 2.131a1.065 1.065 0 0 1-.754.313Z"
      clipRule="evenodd"
    />
    <path
      fill={colors.ultramarine900}
      fillRule="evenodd"
      d="M21.257 37.333h8.533V22.399h-8.533v14.934Zm9.6 2.133H20.19a1.066 1.066 0 0 1-1.067-1.067V21.333c0-.589.478-1.067 1.067-1.067h10.667c.589 0 1.067.478 1.067 1.067v17.066c0 .59-.478 1.067-1.067 1.067ZM29.797 58.475a3.204 3.204 0 0 0 3.193 3.392 3.204 3.204 0 0 0 3.193-3.392c-2.12.254-4.265.254-6.386 0ZM32.99 64a5.34 5.34 0 0 1-5.333-5.333c0-.565.1-1.13.307-1.73a1.06 1.06 0 0 1 1.175-.707c2.546.402 5.158.402 7.703 0a1.06 1.06 0 0 1 1.175.707c.206.6.306 1.165.306 1.73A5.34 5.34 0 0 1 32.99 64ZM48.686 45.867H17.294a1.066 1.066 0 1 1 0-2.134h31.392a1.066 1.066 0 1 1 0 2.134ZM36.19 28.8h8.534v-6.4H36.19v6.4Zm9.6 10.666H35.124a1.066 1.066 0 1 1 0-2.133h9.6v-6.4h-9.6a1.066 1.066 0 0 1-1.067-1.067v-8.533c0-.589.478-1.067 1.067-1.067H45.79c.59 0 1.067.478 1.067 1.067v17.066c0 .59-.478 1.067-1.067 1.067Z"
      clipRule="evenodd"
    />
  </svg>
);
export { Stopwatch };
