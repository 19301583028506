import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const EggToast = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <g fill={colors.ultramarine900} clipPath="url(#egg-toast_svg__a)">
      <path d="M4.86 19.603c.297.193.473.52.473.877v38.187A5.34 5.34 0 0 0 10.667 64h42.666a5.34 5.34 0 0 0 5.334-5.333V20.48c0-.356.177-.685.472-.877 3.212-2.088 5.024-5.616 4.85-9.438C63.725 4.466 58.904 0 53.011 0H10.988C5.094 0 .273 4.466.012 10.166c-.176 3.822 1.637 7.349 4.849 9.437Zm6.128-17.47h42.024c4.671 0 8.64 3.647 8.845 8.131a8.465 8.465 0 0 1-3.88 7.552 3.166 3.166 0 0 0-1.445 2.665v38.186c0 1.764-1.435 3.2-3.2 3.2H10.667a3.204 3.204 0 0 1-3.2-3.2V20.48c0-1.082-.54-2.078-1.445-2.666-2.57-1.671-4.02-4.493-3.88-7.55.206-4.484 4.175-8.132 8.846-8.132Z" />
      <path d="M7.185 16.026A5.293 5.293 0 0 1 9.6 20.48v38.187c0 .588.478 1.066 1.066 1.066h42.667c.589 0 1.067-.477 1.067-1.066V20.48a5.29 5.29 0 0 1 2.415-4.454 6.352 6.352 0 0 0 2.912-5.665C59.572 7 56.56 4.267 53.013 4.267H10.988c-3.548 0-6.56 2.734-6.714 6.095a6.35 6.35 0 0 0 2.91 5.664ZM10.986 6.4h42.026c2.424 0 4.48 1.822 4.582 4.06a4.237 4.237 0 0 1-1.943 3.778 7.418 7.418 0 0 0-3.385 6.243V57.6H11.733V20.481a7.42 7.42 0 0 0-3.384-6.243 4.235 4.235 0 0 1-1.944-3.778c.103-2.24 2.158-4.06 4.581-4.06Z" />
      <path d="m23.01 43.778.072.401c.965 5.29 2.06 11.288 9.701 11.288 7.032 0 14.15-8.061 14.15-23.467 0-13.296-1.155-23.467-15.69-23.467-.425.001-12.61.196-14.903 13.242-1.218 6.933 1.073 10.668 3.29 14.278 1.44 2.346 2.799 4.56 3.38 7.725Zm-4.57-21.635c1.987-11.301 12.171-11.477 12.78-11.477h.023c11.599 0 13.556 6.58 13.556 21.333 0 13.857-6.19 21.333-12.017 21.333-5.862 0-6.669-4.419-7.602-9.536l-.074-.402c-.652-3.557-2.18-6.048-3.66-8.457-2.192-3.572-4.084-6.656-3.005-12.794Z" />
      <path d="M32 30.933c4.705 0 8.533-3.828 8.533-8.533S36.705 13.867 32 13.867s-8.533 3.828-8.533 8.533 3.828 8.533 8.533 8.533ZM32 16c3.53 0 6.4 2.87 6.4 6.4 0 3.53-2.87 6.4-6.4 6.4-3.53 0-6.4-2.87-6.4-6.4 0-3.53 2.87-6.4 6.4-6.4Z" />
      <path d="M28.8 23.467c.589 0 1.067-.478 1.067-1.067 0-1.176.956-2.133 2.133-2.133a1.067 1.067 0 0 0 0-2.134 4.271 4.271 0 0 0-4.267 4.267c0 .589.478 1.067 1.067 1.067Z" />
    </g>
    <defs>
      <clipPath id="egg-toast_svg__a">
        <path fill={colors.white} d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </svg>
);
export { EggToast };
