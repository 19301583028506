import type { SVGProps } from 'react';

import { colors } from '~/styles/colors.css';
const Treadmill = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} fill="none" {...props}>
    <path
      fill={colors.ultramarine900}
      d="M48.287 19.18c-.579.108-.96.665-.853 1.243l5.332 28.796a1.067 1.067 0 0 0 1.24.855c.579-.108.96-.664.854-1.243l-5.333-28.796a1.066 1.066 0 0 0-1.24-.854ZM7.47 33.027h5.333a1.067 1.067 0 0 0 0-2.133H7.471a1.067 1.067 0 1 0 0 2.133ZM17.07 31.96c0 .59.477 1.067 1.066 1.067h21.33a1.067 1.067 0 0 0 0-2.133h-21.33c-.589 0-1.066.478-1.066 1.067ZM2.138 26.628h5.333a1.067 1.067 0 0 0 0-2.133H2.138a1.067 1.067 0 1 0 0 2.133ZM38.4 24.495H12.803a1.067 1.067 0 0 0 0 2.133H38.4a1.067 1.067 0 0 0 0-2.133Z"
    />
    <path
      fill={colors.ultramarine900}
      d="m59.122 36.616-2.148.22-3.938-22.49 8.592-2.314A3.2 3.2 0 0 0 63.89 8.11l-.276-1.031a3.206 3.206 0 0 0-3.926-2.257l-.17.05-.726-2.55A3.2 3.2 0 0 0 54.106.433L44.72 5.898a3.222 3.222 0 0 0-1.581 2.764v.617l-7.07 1.904a3.2 3.2 0 0 0-2.258 3.921l.277 1.03a3.2 3.2 0 0 0 3.084 2.37c.283.001.564-.035.838-.107l4.47-1.204 3.822 20.756-42.317 4.373a4.43 4.43 0 0 0 .446 8.836h.907v2.133a3.2 3.2 0 0 0 3.2 3.2h6.399a3.2 3.2 0 0 0 3.2-3.2v-2.133h30.608l.5 2.713a3.2 3.2 0 0 0 3.144 2.62h4.217a3.2 3.2 0 0 0 3.151-3.751l-.276-1.582h.09a4.432 4.432 0 0 0 4.426-4.426v-5.716a4.424 4.424 0 0 0-4.875-4.4ZM45.28 8.657c.001-.379.2-.73.526-.923l9.38-5.464a1.067 1.067 0 0 1 1.557.631l.718 2.52-12.18 3.28v-.044Zm-7.816 7.67a1.066 1.066 0 0 1-1.308-.746l-.276-1.031c-.153-.567.18-1.15.747-1.307l22.441-6.045h.012l1.162-.312a1.067 1.067 0 0 1 1.307.746l.278 1.03a1.066 1.066 0 0 1-.752 1.308l-23.611 6.358Zm-21.46 36.964c0 .59-.478 1.067-1.067 1.067h-6.4a1.067 1.067 0 0 1-1.066-1.067v-2.133h8.532v2.133Zm1.066-4.266H4.43A2.296 2.296 0 0 1 4.2 44.44l42.498-4.394 1.653 8.98H17.07Zm40.353 4.952a1.052 1.052 0 0 1-.817.381h-4.217c-.515 0-.956-.368-1.049-.873l-2.708-14.707v-.015L44.556 16.63l6.412-1.727 6.69 38.206c.056.31-.03.63-.234.87Zm4.44-7.245a2.295 2.295 0 0 1-2.292 2.293h-.463l-1.766-10.083 1.997-.204a2.286 2.286 0 0 1 1.77.58c.483.431.758 1.05.755 1.698v5.716Z"
    />
  </svg>
);
export { Treadmill };
